import React, { useState } from 'react';
import { FinancialDetails, OperatingLeaseFinancialDetails, FinancialDetailsEditStatus } from '@cp-es/common';
import { IbanValidationView } from './iban-validation-view';
import { IbanConfirmationView } from './iban-confirmation-view';
import { SepaMandateView } from './sepa-mandate-view';
import { SmeContractsView } from './sme-contracts-view';
import { ValidatedIban } from './iban-validation-view/ValidatedIban';
import { withAutoScroll } from '@cp-shared-9/frontend-ui';

export type EditViewNames = 'SME_CONTRACTS_VIEW' | 'IBAN_VALIDATION_VIEW' | 'IBAN_DETAILS_VIEW' | 'SEPA_MANDATE_VIEW';

export type EditViewProps = {
    /**
     * Only necessary for operative lease contracts
     */
    contractId?: string;
    details: FinancialDetails;
    forOperatingLease?: boolean;
    cancelEditing: () => void;
    finishEditing: (
        newEditStatus: FinancialDetailsEditStatus,
        newIban?: string,
        contractWithSameCostCenterIds?: string[],
    ) => void;
    onEditFail: () => void;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ cancelEditing, finishEditing, contractId, details, forOperatingLease, onEditFail }) => {
        const firstView = forOperatingLease ? 'SME_CONTRACTS_VIEW' : 'IBAN_VALIDATION_VIEW';
        const [currentView, setCurrentView] = useState<EditViewNames>(firstView);
        const [validatedIban, setValidatedIban] = useState<ValidatedIban | null>(null);
        const [lastEditStatus, setLastEditStatus] = useState<FinancialDetailsEditStatus>('NOT_PERFORMED');
        const [newIban, setNewIban] = useState<string>('');
        const [contractWithSameCostCenterIds, setContractWithSameCostCenterIds] = useState<string[]>([]);
        const [shouldSkipSmeContracts, setShouldSkipSmeContracts] = useState(false);

        if (!details._links?.validateIban || !details._links?.changeIban || !details._links?.sepaMandate) {
            return null;
        }

        const toNextView = (): void => {
            switch (currentView) {
                case 'SME_CONTRACTS_VIEW': {
                    setCurrentView('IBAN_VALIDATION_VIEW');
                    break;
                }
                case 'IBAN_VALIDATION_VIEW': {
                    setCurrentView('IBAN_DETAILS_VIEW');
                    break;
                }
                case 'IBAN_DETAILS_VIEW': {
                    setCurrentView('SEPA_MANDATE_VIEW');
                    break;
                }
                case 'SEPA_MANDATE_VIEW': {
                    finishEditing(lastEditStatus, newIban, contractWithSameCostCenterIds);
                    break;
                }
            }
        };

        const toPreviousView = (): void => {
            switch (currentView) {
                case 'SME_CONTRACTS_VIEW': {
                    cancelEditing();
                    break;
                }
                case 'IBAN_VALIDATION_VIEW': {
                    if (forOperatingLease && !shouldSkipSmeContracts) {
                        setCurrentView('SME_CONTRACTS_VIEW');
                    } else {
                        cancelEditing();
                    }
                    break;
                }
                case 'IBAN_DETAILS_VIEW': {
                    setCurrentView('IBAN_VALIDATION_VIEW');
                    break;
                }
                case 'SEPA_MANDATE_VIEW': {
                    setCurrentView('IBAN_DETAILS_VIEW');
                    break;
                }
            }
        };

        const onAcceptSmeContracts = (
            skipSmeContracts = false,
            contractsWithSameCostCenterIds: string[] = [],
        ): void => {
            setContractWithSameCostCenterIds(contractsWithSameCostCenterIds);
            setShouldSkipSmeContracts(skipSmeContracts);
            toNextView();
        };

        const onValidIban = (validatedIban: ValidatedIban): void => {
            setValidatedIban(validatedIban);
            toNextView();
        };

        const onChangedIban = (changeStatus: FinancialDetailsEditStatus, newIban?: string): void => {
            setLastEditStatus(changeStatus);
            setNewIban(newIban ?? '');
            toNextView();
        };

        return (
            <>
                {currentView === 'SME_CONTRACTS_VIEW' && (
                    <SmeContractsView
                        contractId={contractId || ''}
                        costCenter={(details as OperatingLeaseFinancialDetails).costCenter || ''}
                        onAcceptSmeContracts={onAcceptSmeContracts}
                        toPreviousView={toPreviousView}
                    />
                )}
                {currentView === 'IBAN_VALIDATION_VIEW' && (
                    <IbanValidationView
                        validateIbanEndpoint={details._links.validateIban}
                        forOperatingLease={forOperatingLease}
                        previousIban={details.iban}
                        onValidIban={onValidIban}
                        toPreviousView={toPreviousView}
                        isFirstView={!forOperatingLease}
                        onEditFail={onEditFail}
                    />
                )}
                {validatedIban && currentView === 'IBAN_DETAILS_VIEW' && (
                    <IbanConfirmationView
                        changeIbanEndpoint={details._links.changeIban}
                        validatedIban={validatedIban}
                        onChangedIban={onChangedIban}
                        toPreviousView={toPreviousView}
                        forOperatingLease={forOperatingLease}
                    />
                )}
                {currentView === 'SEPA_MANDATE_VIEW' && (
                    <SepaMandateView toNextView={toNextView} sepaMandateDownloadEndpoint={details._links.sepaMandate} />
                )}
            </>
        );
    },
    'IbanSectionEditView',
);
