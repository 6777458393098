import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { Notification, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ContractHeader } from 'components/contracts/ContractHeader';
import { Contract, OperatingLeaseFinancialDetails } from '@cp-es/common';
import { boundry } from '../contract-recalculation-form/ContractRecalculationFormValues';

import { formatCpDate } from '@cp-shared-9/common-utilities';

type ContractRecalculationUiProps = {
    contract: Contract;
    financialDetails: OperatingLeaseFinancialDetails;
};
export const ContractRecalculationHeader: React.FC<ContractRecalculationUiProps> = ({ contract, financialDetails }) => {
    const { t } = useTranslation('contract-recalculation');
    const totalDurationPermittedInMonths = boundry.duration.maximum;
    const maximumMileagePermitted = boundry.distance.maximum;
    const currentDuration = Math.round(
        formatCpDate(financialDetails.endDate).diff(financialDetails.startDate, 'M', true),
    );

    const contractDetails: DefinitionListItem[] = [
        {
            label: t('details.total-duration'),
            value: <b>{totalDurationPermittedInMonths + ' ' + t('duration-unit')}</b>,
        },
        {
            label: t('details.current-duration'),
            value: <b>{currentDuration + ' ' + t('duration-unit')}</b>,
        },
        {
            label: t('details.contractual-mileage'),
            value: <b>{maximumMileagePermitted + ' ' + t('distance-unit')}</b>,
        },
        {
            label: t('details.current-mileage'),
            value: <b>{financialDetails?.mileageLastRecalculation + ' ' + t('distance-unit')}</b>,
        },
    ];

    return (
        <>
            <Card element="article">
                <ContractHeader contract={contract} />
            </Card>

            <br />
            <Notification>
                <React.Fragment key=".0">{t('info.text')}</React.Fragment>
            </Notification>
            <br />
            <DefinitionListHorizontal list={contractDetails} />
        </>
    );
};
