import React from 'react';
import { Layout, TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import { ContractType, DigitalRenewalOptions, OperatingLeaseFinancialDetails } from '@cp-es/common';
import { useTranslation } from 'react-i18next';
import { formatCpDate } from '@cp-shared-9/common-utilities';
import moment from 'moment';

type OptionsProps = {
    contractType: string;
    monthsLeft: number | undefined;
    contractTypeContent: string;
    isUnpaidPresent: boolean;
    productModality?: string;
    ipoScenario?: boolean;
    ipoDate: Date;
    contractEndDate: Date;
    financialDetails?: OperatingLeaseFinancialDetails;
    setOption: (option: DigitalRenewalOptions | null) => void;
    brand: string;
};

type RenderTileConfig = {
    testId: string;
    titleKey: string;
    icon: string;
    value: string;
    option: DigitalRenewalOptions;
    disabled?: boolean;
    slotData?: Record<string, string>;
};

const Options: React.FC<OptionsProps> = ({
    contractType,
    monthsLeft,
    contractTypeContent,
    productModality,
    isUnpaidPresent,
    ipoScenario,
    financialDetails,
    setOption,
    brand,
    ipoDate,
    contractEndDate,
}): JSX.Element => {
    const { t } = useTranslation('digital-renewal');

    const momentNow = moment().toDate();
    const nextProductModality = productModality === 'C5' && contractType === ContractType.FINANCING;
    const cpcProductModality = productModality === 'CP' && contractType === ContractType.FINANCING;

    const isIpoToTwoMonths = ipoDate <= momentNow && monthsLeft && monthsLeft > 2;

    const isTwoMonthsToEndDate = momentNow < contractEndDate && (monthsLeft || 0) <= 2;
    const renderTile = ({
        testId,
        titleKey,
        icon,
        value,
        option,
        disabled,
        slotData,
    }: RenderTileConfig): JSX.Element => (
        <TileSelect
            testId={testId}
            title={t(`${titleKey}.heading`)}
            inputType="radio"
            icon={icon}
            value={value}
            radioGroupName="digitalRenewalOptions"
            onClick={(): void => setOption(option)}
            disabled={disabled}
        >
            {t(`${titleKey}.text`, slotData ?? {})}
        </TileSelect>
    );
    const currentDuration = Math.round(
        formatCpDate(financialDetails?.endDate).diff(financialDetails?.startDate, 'M', true),
    );

    const mileage = financialDetails?.mileageLastRecalculation;
    const renderFirstTile = (): JSX.Element | undefined => {
        if (
            contractType === ContractType.OPERATING_LEASE &&
            monthsLeft &&
            monthsLeft <= 1 &&
            mileage &&
            mileage < 200000 &&
            currentDuration < 60
        ) {
            return renderTile({
                testId: 'optionExtend',
                titleKey: `${contractTypeContent}.extend`,
                icon: 'semantic-plus',
                value: 'extend',
                option: DigitalRenewalOptions.EXTEND,
            });
        }
        if (
            (contractType === ContractType.FINANCING && ((monthsLeft && monthsLeft <= 2) || ipoScenario)) ||
            (contractType === ContractType.OPERATING_LEASE && monthsLeft && monthsLeft <= 5 && monthsLeft > 1)
        ) {
            return renderTile({
                testId: 'optionRenewal',
                titleKey: `${contractTypeContent}.renewal`,
                icon: 'semantic-car',
                value: 'renewal',
                option: DigitalRenewalOptions.RENEWAL,
            });
        }
        return;
    };

    const renderSecondTile = (): JSX.Element => {
        if (
            (contractType === ContractType.OPERATING_LEASE && monthsLeft && monthsLeft <= 5 && monthsLeft > 2) ||
            (isIpoToTwoMonths && nextProductModality)
        ) {
            return renderTile({
                testId: 'optionMoreInfo',
                titleKey: `${contractTypeContent}.more_info`,
                icon: 'semantic-info',
                value: 'more-info',
                option: DigitalRenewalOptions.MORE_INFO,
            });
        }

        if (
            (!ipoScenario && contractType === ContractType.FINANCING && monthsLeft && monthsLeft <= 2) ||
            (isTwoMonthsToEndDate && nextProductModality)
        ) {
            return renderTile({
                testId: 'optionRefinanceOfPayment',
                titleKey: 'finance.refinance_or_payment',
                icon: 'semantic-calculator',
                value: 'refinance-or-payment',
                option: DigitalRenewalOptions.REFINANCE,
            });
        }

        if (
            contractType === ContractType.OPERATING_LEASE &&
            monthsLeft &&
            monthsLeft <= 2 &&
            monthsLeft > 1 &&
            mileage &&
            mileage < 200000 &&
            currentDuration < 60
        ) {
            return renderTile({
                testId: 'optionExtend',
                titleKey: `${contractTypeContent}.extend`,
                icon: 'semantic-plus',
                value: 'extend',
                option: DigitalRenewalOptions.EXTEND,
            });
        }

        if (contractType === ContractType.OPERATING_LEASE && monthsLeft && monthsLeft <= 1) {
            return renderTile({
                testId: 'optionJointContract',
                titleKey: `${contractTypeContent}.joint_contract`,
                icon: 'two-users',
                value: 'joint_contract',
                option: DigitalRenewalOptions.JOINT_CONTRACT,
                slotData: { brand },
            });
        }

        return renderTile({
            testId: 'optionRefinanceOfPayment',
            titleKey: 'finance.refinance_or_payment',
            icon: 'semantic-calculator',
            value: 'refinance-or-payment',
            option: DigitalRenewalOptions.REFINANCE,
        });
    };

    const renderThirdTile = (): JSX.Element | undefined => {
        if (contractType === ContractType.OPERATING_LEASE && monthsLeft && monthsLeft >= 1 && monthsLeft <= 2) {
            return renderTile({
                testId: 'optionMoreInfo',
                titleKey: `${contractTypeContent}.more_info`,
                icon: 'semantic-info',
                value: 'more_info',
                option: DigitalRenewalOptions.MORE_INFO,
            });
        }
        if (
            (isTwoMonthsToEndDate && (nextProductModality || cpcProductModality)) ||
            (contractType === ContractType.OPERATING_LEASE && monthsLeft && monthsLeft <= 1)
        ) {
            return renderTile({
                testId: 'optionReturnOfTheCar',
                titleKey: `${contractTypeContent}.returncar`,
                icon: 'semantic-reload',
                value: 'return_of_the_car',
                option: DigitalRenewalOptions.RETURN_OF_THE_CAR,
                disabled: isUnpaidPresent,
            });
        }
        return;
    };

    return (
        <Layout.Item className="u-text-center" default="1/1" s="1/1">
            <TileSelectGroup layoutItemClassName={'u-1/3 u-1/3@l  u-1/1@s'}>
                {renderFirstTile()}
                {renderSecondTile()}
                {renderThirdTile()}
            </TileSelectGroup>
        </Layout.Item>
    );
};

export default Options;
