import { VehicleDetails } from '@cp-es/common';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    VehicleDetails[],
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'vehicleDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchVehicleDetails = fetchData;
