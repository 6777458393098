import { CustomerType } from '@cp-es/common';
import {
    AbstractDataState,
    useGetSimpleApiData,
    useGetSimpleApiDataWithTransformer,
} from '@cp-shared-9/frontend-integration';
import { fetchCustomerType } from './CustomerTypeSlice';
import { CustomerTypeSelector, setDefaultCustomerType } from './CustomerTypeSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

export function useCustomerType(): AbstractDataState<CustomerType, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiData(fetchCustomerType, CustomerTypeSelector);
}

export function useCustomerTypeWithDefaultValue(): AbstractDataState<CustomerType, DefaultBusinessMarketApiErrorCode> {
    return useGetSimpleApiDataWithTransformer(fetchCustomerType, CustomerTypeSelector, setDefaultCustomerType);
}
