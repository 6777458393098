import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
    Button,
    ButtonContainer,
    CustomRangeSlider,
    Fieldset,
    Form,
    Input,
    Layout,
    SectionHeading,
} from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    ValidatedDate,
} from '@cp-shared-9/frontend-ui';
import { CPDate, formatCpDate, trimAllValues } from '@cp-shared-9/common-utilities';
import {
    ContractLinks,
    DATE_FORMAT,
    EarlySettlementError,
    formatAsCurrency,
    PartialEarlySettlementRequest,
} from '@cp-es/common';
import * as Yup from 'yup';
import { CpDataApi } from '../../../../../cp-xhr';
import { parseErrorResponse } from '@cp-shared-9/frontend-integration';
import { FormValues, getInitialValues } from './initialValues';

export type OptionCalculatorFormProps = {
    back: () => void;
    pendingCapital: number;
    contractEndDate?: CPDate;
    links?: ContractLinks;
    setCalculatedOptions: Function;
    setRequestDate: Function;
    setLastSubmissionError: Function;
};

export const OptionCalculatorForm: React.FC<OptionCalculatorFormProps> = ({
    back,
    pendingCapital,
    contractEndDate,
    links,
    setRequestDate,
    setCalculatedOptions,
    setLastSubmissionError,
}) => {
    const roundedOutstandingCapital = Math.round(pendingCapital);
    const defaultPartialSettlementAmount = Math.round(roundedOutstandingCapital / 2);

    const { t, i18n } = useTranslation('partial-early-settlement');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [inputAmount, setInputAmount] = useState<number>(defaultPartialSettlementAmount);
    const [sliderAmount, setSliderAmount] = useState<number>(defaultPartialSettlementAmount);

    useAnalyticsPageViewTracker('earlySettlementSimulation');
    const { onAction: onCalculate } = useAnalyticsActionTracker('onEarlySettlementCalculate');

    const language = i18n.languages[0];

    const maxDate = formatCpDate(contractEndDate)
        .subtract(2, 'days')
        .format(DATE_FORMAT);

    const validationSchema = Yup.object().shape({
        settlementDate: Yup.string()
            .required(t('calculate.date.validation.required'))
            .test('format', t('calculate.date.validation.format'), date => formatCpDate(date, DATE_FORMAT).isValid())
            .test('no future date', t('calculate.date.validation.not-future-date'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .startOf('day')
                    .isSameOrAfter(
                        formatCpDate()
                            .toMoment()
                            .startOf('day'),
                    ),
            )
            .test('not before expire date', t('calculate.date.validation.not-valid-date'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .isSameOrBefore(
                        formatCpDate(contractEndDate)
                            .subtract(2, 'days')
                            .toMoment(),
                    ),
            ),
    });

    const handleChangeForInputAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const intValue = parseInt(event.target.value);
        if (!intValue) {
            setInputAmount(defaultPartialSettlementAmount);
            setSliderAmount(defaultPartialSettlementAmount);
        } else {
            setInputAmount(intValue);
            setSliderAmount(intValue);
        }
    };

    const handleChangeForSliderValue = (value: string | number | (string | number)[]): void => {
        const intValue = parseInt(String(value));
        setInputAmount(intValue);
        setSliderAmount(intValue);
    };

    const onSubmit = ({ settlementDate }: FormValues): void => {
        if (!links?.partialEarlySettlement) {
            return;
        }

        onCalculate();

        setIsSubmitting(true);

        const requestDate = formatCpDate(settlementDate, DATE_FORMAT).toCpDate();

        setRequestDate(requestDate);

        const requestBody: PartialEarlySettlementRequest = trimAllValues({
            date: requestDate,
            amount: inputAmount,
        });

        CpDataApi.post(links?.partialEarlySettlement, requestBody)
            .then(response => {
                const responseData = response.data;
                setCalculatedOptions(responseData);
                setLastSubmissionError(undefined);
            })
            .catch(error => {
                const errorCode = parseErrorResponse<EarlySettlementError>(error).code;
                setCalculatedOptions(undefined);
                setLastSubmissionError(errorCode);
            })
            .finally(() => setIsSubmitting(false));
    };
    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Layout.Item className="u-mt" default="1/1" s="1/1">
                <SectionHeading level="3" subtitle={t('calculate.text')}>
                    {t('calculate.headline')}
                </SectionHeading>
            </Layout.Item>

            <Layout.Item className="u-text-center" default="1/1" s="1/1">
                <SectionHeading center={true} level="5">
                    {t('calculate.sub-heading')}
                </SectionHeading>
            </Layout.Item>
            <Layout.Item className="u-text-center" default="1/1" s="1/1">
                <Formik initialValues={getInitialValues()} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {(formik): JSX.Element => (
                        <Form onSubmit={e => preventSubmit(e)} data-testid="optionCalculatorForm">
                            <Fieldset className="u-text-center">
                                <Fieldset.Row>
                                    <Layout.Item className="u-text-center" default="1/4" s={'1/1'}>
                                        <Input
                                            testId="inputPartialSettlementAmount"
                                            type="text"
                                            addonText="€"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                handleChangeForInputAmount(event);
                                            }}
                                            value={inputAmount}
                                        />
                                    </Layout.Item>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout.Item default="1/1" s="1/1">
                                        <CustomRangeSlider
                                            testId="sliderPartialSettlementAmount"
                                            value={sliderAmount}
                                            range={{ min: 1, max: roundedOutstandingCapital }}
                                            pips={{
                                                mode: 'steps',
                                                density: roundedOutstandingCapital,
                                                format: {
                                                    to: (value: number): string => {
                                                        const intValue = parseInt(String(value));
                                                        if (intValue > 1) {
                                                            return `max. ${formatAsCurrency(parseInt(String(value)))}`;
                                                        }
                                                        return `min. ${formatAsCurrency(parseInt(String(value)))}`;
                                                    },
                                                },
                                            }}
                                            tooltips={{
                                                to: (value: number): string =>
                                                    formatAsCurrency(parseInt(String(value))),
                                            }}
                                            onSet={(value: string | number | (string | number)[]): void =>
                                                handleChangeForSliderValue(value)
                                            }
                                        />
                                    </Layout.Item>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout.Item className="u-text-center" default="1/2" s="1/1">
                                        <ValidatedDate
                                            testId="settlementDate"
                                            name="settlementDate"
                                            label={t('calculate.date.label', {
                                                date: maxDate,
                                            })}
                                            language={language}
                                            outputDateFormat="d/m/Y"
                                            maxDate={maxDate}
                                            minDate={formatCpDate().format(DATE_FORMAT)}
                                            type="text"
                                            value={formatCpDate().format(DATE_FORMAT)}
                                        />
                                    </Layout.Item>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Layout.Item default="1/1" s="1/1">
                                        <ButtonContainer center>
                                            <Button secondary onClick={back} testId="calculationBackButton">
                                                {t('translation:editableSectionNav.back')}
                                            </Button>
                                            <Button
                                                onClick={formik.submitForm}
                                                testId="calculateButton"
                                                type="button"
                                                disabled={isSubmitting}
                                            >
                                                {t('calculate.button')}
                                            </Button>
                                        </ButtonContainer>
                                    </Layout.Item>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
