import { RootState } from 'store/rootReducer';
import { getSimpleDataSelector } from '@cp-shared-9/frontend-integration';
import { CustomerType } from '@cp-es/common';

export const CustomerTypeDataSelector = (state: RootState) => state.customerType;

export const CustomerTypeSelector = getSimpleDataSelector(CustomerTypeDataSelector);

export function setDefaultCustomerType(customerType?: CustomerType): CustomerType {
    return customerType ?? 'PRIVATE';
}
