import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Contract, ContractType, DATE_FORMAT, EncryptedContractNumber } from '@cp-es/common';
import { useHistory } from 'react-router-dom';
import { digitalRenewalPagePath } from 'components/navigation/paths';
import { calculateMonthsLeft, checkTheMonthsLeft } from 'utils/digitalRenewalEligibleContracts';
import DigitalRenewalEntryNotificationContent from './DigitalRenewalEntryNotificationContent';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { formatCpDate } from '@cp-shared-9/common-utilities';
import moment from 'moment';
import { useFinancialDetails } from '../../../contracts/financial-details/useFinancialDetails';
import { operatingLeaseEmployeeContractNumberPrefixes } from 'components/contracts/operating-lease-contract';

export type DigitalRenewalEntryNotificationProps = {
    testId?: string;
    contract: (Contract & EncryptedContractNumber) | null;
    handleButtonClick?: (contractId: string) => void;
};

export const EntryNotification: React.FC<DigitalRenewalEntryNotificationProps> = ({
    testId,
    contract,
    handleButtonClick,
}) => {
    const { t } = useTranslation('digital-renewal');
    const history = useHistory();
    const { data: financialDetails, isLoading } = useFinancialDetails(contract?.contractNumber || '');

    if (!contract || isLoading) {
        return null;
    }

    if (
        contract &&
        !(contract.contractType === ContractType.FINANCING || contract.contractType === ContractType.OPERATING_LEASE)
    ) {
        return <NoConnectionNotification />;
    }
    const twoMonthsLeft = checkTheMonthsLeft(contract, 2);
    const oneMonthLeft = checkTheMonthsLeft(contract, 1);
    const fiveMonthsLeft = checkTheMonthsLeft(contract, 5);
    const monthsLeft = calculateMonthsLeft(contract);
    const isEmployeeRenewal = operatingLeaseEmployeeContractNumberPrefixes.some(prefix =>
        contract.contractNumber.includes(prefix),
    );

    const contractId = contract._encryptedContractNumber;
    const {
        productModality,
        contractNumber,
        carInformation: modelName,
        contractEndDate: endDate,
        contractType,
    } = contract;
    const contractEndDate = formatCpDate(endDate).format(DATE_FORMAT);
    const currentIPOdate = moment(contract.beginDate)
        .add(financialDetails?.ipoShiftMonths || 0, 'months')
        .toDate();
    const next2IpoDate = moment(contract.contractEndDate).toDate();
    const ipoScenario = currentIPOdate < moment().toDate() && moment().toDate() < next2IpoDate;
    let headline = '';
    let content = '';

    if (contractType === ContractType.FINANCING && ipoScenario && productModality === 'C5') {
        headline = t('finance.notification.entry-notification-next.headline');
        content = t('finance.notification.entry-notification-next.content', {
            modelName,
            contractNumber,
            contractEndDate,
        });
    } else if (contractType === ContractType.FINANCING) {
        headline = t(`finance.notification.entry-notification.headline`);
        content = t(`finance.notification.entry-notification.content`, { modelName, contractNumber, contractEndDate });
    } else if (contractType === ContractType.OPERATING_LEASE) {
        if (oneMonthLeft) {
            headline = t(`leasing.notification.entry-notification-1-month-contract.headline`);
            content = t(`leasing.notification.entry-notification-1-month-contract.content`, {
                modelName,
                contractNumber,
                contractEndDate,
            });
        } else if (!oneMonthLeft && twoMonthsLeft) {
            headline = t(`leasing.notification.entry-notification-2-month-contract.headline`);
            content = t(`leasing.notification.entry-notification-2-month-contract.content`, {
                modelName,
                contractNumber,
                contractEndDate,
            });
        } else if (!oneMonthLeft && !twoMonthsLeft && fiveMonthsLeft) {
            headline = t(`leasing.notification.entry-notification.headline`);
            content = t(`leasing.notification.entry-notification.content`, {
                modelName,
                contractNumber,
                contractEndDate,
            });
        }
    }

    const handleClick = (): void => {
        history.push(digitalRenewalPagePath(contractId));
    };

    if (
        (contractType === ContractType.FINANCING &&
            ((monthsLeft && monthsLeft <= 2) || (ipoScenario && productModality === 'C5'))) ||
        (contractType === ContractType.OPERATING_LEASE && !isEmployeeRenewal)
    ) {
        return (
            <Notification
                status={NotificationStatus.info}
                headline={headline}
                testId={testId}
                className={'c-notification--context u-mb'}
                showCloseButton={true}
                onCloseClick={(): void => handleButtonClick && handleButtonClick(contractId)}
            >
                <DigitalRenewalEntryNotificationContent content={content} handleClick={handleClick} />
            </Notification>
        );
    } else {
        return null;
    }
};
