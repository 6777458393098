import { CustomerType, formatAsIban, IbanChange, IbanError, FinancialDetailsEditStatus } from '@cp-es/common';
import {
    DataOverview,
    DefinitionListHorizontal,
    preventSubmit,
    UiBlockingSpinner,
    ValidatedCheckbox,
    withAutoScroll,
} from '@cp-shared-9/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useCustomerTypeWithDefaultValue } from 'components/customer-type/useCustomerType';
import { withLoadingHandler } from 'components/integration-wrapper';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { IbanValidationError } from '../iban-validation-view/IbanValidationError';
import { NotificationForLastValidation } from '../iban-validation-view/NotificationForLastValidation';
import { ValidatedIban } from '../iban-validation-view/ValidatedIban';
import { parseErrorResponse } from '@cp-shared-9/frontend-integration';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-9/common-utilities';

export type IbanConfirmationViewProps = {
    validatedIban: ValidatedIban;
    changeIbanEndpoint: string;
    onChangedIban: (changedIban: FinancialDetailsEditStatus, newIban?: string) => void;
    toPreviousView: () => void;
    forOperatingLease?: boolean;
};

type FormType = {
    isAccountHolder: boolean;
};

const IbanConfirmationViewUi: React.FC<IbanConfirmationViewProps & { customerType: CustomerType }> = ({
    validatedIban,
    changeIbanEndpoint,
    toPreviousView,
    onChangedIban,
    customerType,
    forOperatingLease,
}) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultBusinessMarketApiError<IbanValidationError>
    >('NO_ERROR');
    const [lastIsAccountHolder, setLastIsAccountHolder] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const labelText =
        customerType === 'PRIVATE'
            ? t('ibanSection.editView.ibanConfirmationView.accountHolder.private.label')
            : t('ibanSection.editView.ibanConfirmationView.accountHolder.sme.label');

    const validationText =
        customerType === 'PRIVATE'
            ? t('ibanSection.editView.ibanConfirmationView.accountHolder.private.validations.required')
            : t('ibanSection.editView.ibanConfirmationView.accountHolder.sme.validations.required');

    const initialValues: FormType = {
        isAccountHolder: lastIsAccountHolder,
    };

    const validationSchema = Yup.object().shape({
        isAccountHolder: Yup.bool().oneOf([true], validationText),
    });

    const onSubmit = (values: FormType): void => {
        setLastIsAccountHolder(values.isAccountHolder);
        setIsSubmitting(true);
        const requestBody: IbanChange = {
            iban: validatedIban.iban,
        };
        CpDataApi.put(changeIbanEndpoint, requestBody)
            .then(response => {
                setIsSubmitting(false);
                setLastSubmissionError('NO_ERROR');
                if (response.status === 202) onChangedIban('SUCCESS_PENDING');
                else onChangedIban('SUCCESS', validatedIban.iban);
            })
            .catch(error => {
                setIsSubmitting(false);
                const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanError>>(error).code;
                switch (errorCode) {
                    case 'MARKET_API_DEFAULT_BUSINESS_ERROR':
                    case 'NO_LOAN_PAYMENT_HOLDER':
                    case 'SAME_BANK_ACCOUNT':
                    case 'WRONG_BANK_CODE':
                        setLastSubmissionError(errorCode);
                        break;
                    default:
                        setLastSubmissionError('MARKET_API_DEFAULT_BUSINESS_ERROR');
                        break;
                }
            });
    };

    return (
        <UiBlockingSpinner isBlocking={isSubmitting}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {formik => (
                    <Form onSubmit={e => preventSubmit(e)}>
                        <Fieldset>
                            <Fieldset.Row>
                                <DefinitionListHorizontal
                                    list={[
                                        {
                                            label: t('ibanSection.editView.ibanConfirmationView.iban'),
                                            value: formatAsIban(validatedIban.iban),
                                        },
                                        {
                                            label: t('ibanSection.editView.ibanConfirmationView.bankName'),
                                            value: validatedIban.bankName,
                                        },
                                    ]}
                                />
                                <Layout>
                                    <Layout.Item default="1/1">
                                        <ValidatedCheckbox
                                            name={'isAccountHolder'}
                                            label={labelText}
                                            testId="isAccountHolder"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        {lastSubmissionError !== 'NO_ERROR' && (
                            <Fieldset>
                                <NotificationForLastValidation
                                    lastValidationError={lastSubmissionError}
                                    forOperatingLease={forOperatingLease}
                                />
                            </Fieldset>
                        )}
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button secondary onClick={toPreviousView} testId="cancelButton">
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                        testId="submitButton"
                                        type="submit"
                                    >
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </UiBlockingSpinner>
    );
};

const IbanConfirmationViewWithHandlers = withLoadingHandler(IbanConfirmationViewUi);

export const IbanConfirmationView: React.FC<IbanConfirmationViewProps> = withAutoScroll(
    ({ validatedIban, changeIbanEndpoint, toPreviousView, onChangedIban, forOperatingLease }) => {
        const { t } = useTranslationWithFormatting('financial-details');

        const { data: customerType, isLoading } = useCustomerTypeWithDefaultValue();

        return (
            <DataOverview title={t('ibanSection.editView.title')}>
                <IbanConfirmationViewWithHandlers
                    customerType={customerType}
                    validatedIban={validatedIban}
                    changeIbanEndpoint={changeIbanEndpoint}
                    toPreviousView={toPreviousView}
                    onChangedIban={onChangedIban}
                    isLoading={isLoading}
                    forOperatingLease={forOperatingLease}
                />
                {isLoading && (
                    <ButtonContainer className="u-mt" nav>
                        <Button secondary onClick={toPreviousView} testId="cancelButton">
                            {t('translation:editableSectionNav.back')}
                        </Button>
                    </ButtonContainer>
                )}
            </DataOverview>
        );
    },
    'IbanConfirmationView',
);
