import React, { useState } from 'react';
import { Contract, EarlySettlementError, EarlySettlementHeader, PartialEarlySettlement } from '@cp-es/common';
import { useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { OptionCalculatorForm } from './form/OptionCalculatorForm';
import { SettlementOptions } from './options/SettlementOptions';
import { ContractTermsHeader } from '../../contract-terms-header';

export type CalculationProps = {
    contract: Contract;
    earlySettlementHeader: EarlySettlementHeader;
    setRequestDate: Function;
    setSelectedOption: Function;
    backToJointEntryPoint: () => void;
    continueToQuoteDetails: () => void;
};

export const Calculation: React.FC<CalculationProps> = ({
    contract,
    earlySettlementHeader,
    setRequestDate,
    setSelectedOption,
    backToJointEntryPoint,
    continueToQuoteDetails,
}) => {
    const { t } = useTranslationWithFormatting('partial-early-settlement');
    const [calculationOptions, setCalculatedOptions] = useState<PartialEarlySettlement[]>();
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultCpIntegrationErrors<EarlySettlementError>
    >();
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

    if (!contract?._links?.partialEarlySettlement || !contract.totalAmount || !contract.contractEndDate) {
        return null;
    }

    const isThereAnyData = lastSubmissionError || calculationOptions;
    const isCalculationProvidedForAnyOption = lastSubmissionError === 'ERROR_IN_AMOUNT_WITHOUT_CONTENT';
    const isMarketErrorForBothOptions = lastSubmissionError === 'AVAILABLE_AMOUNT_LESS_THAN_PENALTY_AMOUNT';
    const isLastSubmissionError =
        !isCalculationProvidedForAnyOption && !isMarketErrorForBothOptions && lastSubmissionError;
    const isThereAnyError = isCalculationProvidedForAnyOption || isMarketErrorForBothOptions || isLastSubmissionError;

    const showDisabledContinueButton = !isThereAnyError && !isOptionSelected;
    const showEnabledContinueAndButton = !isThereAnyError && isOptionSelected;
    return (
        <>
            <Layout.Item default="1/1" s="1/1">
                <ContractTermsHeader contractDetails={contract} earlySettlementHeader={earlySettlementHeader} />
            </Layout.Item>
            <OptionCalculatorForm
                back={backToJointEntryPoint}
                links={contract?._links}
                pendingCapital={contract.totalAmount}
                contractEndDate={contract.contractEndDate}
                setCalculatedOptions={setCalculatedOptions}
                setRequestDate={setRequestDate}
                setLastSubmissionError={setLastSubmissionError}
            />
            <Layout.Item className="u-mt" default="1/1" s="1/1">
                {isCalculationProvidedForAnyOption && (
                    <Notification
                        className="u-text-left"
                        status={NotificationStatus.error}
                        headline={t('calculate.notification.mandatory-field-missing.headline')}
                        text={t('calculate.notification.mandatory-field-missing.text')}
                    />
                )}
                {isMarketErrorForBothOptions && (
                    <Notification
                        className="u-text-left"
                        status={NotificationStatus.error}
                        headline={t('calculate.notification.es-error-response.headline')}
                        text={t('calculate.notification.es-error-response.text')}
                    />
                )}
                {isLastSubmissionError && (
                    <Notification
                        className="u-text-left"
                        status={NotificationStatus.warning}
                        headline={t('calculate.notification.backend-error.headline')}
                        text={t('calculate.notification.backend-error.text')}
                    />
                )}
            </Layout.Item>

            <SettlementOptions
                setSelectedOption={setSelectedOption}
                setIsOptionSelected={setIsOptionSelected}
                partialEarlySettlementDetails={calculationOptions}
            />
            {isThereAnyData && (
                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    <ButtonContainer center>
                        {calculationOptions && (
                            <Button secondary onClick={backToJointEntryPoint} testId="settlementOptionsBackButton">
                                {t('translation:editableSectionNav.back')}
                            </Button>
                        )}
                        {showDisabledContinueButton && (
                            <Button disabled testId="disabledSettlementOptionsContinueButton">
                                {t('translation:editableSectionNav.continue')}
                            </Button>
                        )}
                        {showEnabledContinueAndButton && (
                            <Button testId="enabledSettlementOptionsContinueButton" onClick={continueToQuoteDetails}>
                                {t('translation:editableSectionNav.continue')}
                            </Button>
                        )}
                    </ButtonContainer>
                </Layout.Item>
            )}
        </>
    );
};
