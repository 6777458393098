import {
    AbstractContractBasedDataState,
    useGetContractBasedUpdateData,
    useGetContractBasedUpdateState,
} from '@cp-shared-9/frontend-integration';
import { updateFinancialDetailsPerContract, updateFinancialDetailsState } from './FinancialDetailsSlice';
import { FinancialDetailsDataSelector } from './FinancialDetailsDataSelector';
import { FinancialDetailsStateSelector } from './FinancialDetailsStateSelector';
import { FetchContractError, FinancialDetails } from '@cp-es/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

type UpdateFinancialDetailsType = {
    updateFinancialDetails: (data: FinancialDetails) => void;
    financialDetailsData: FinancialDetails;
};

export function useUpdateFinancialDetailsPerContract(contactId: string): UpdateFinancialDetailsType {
    const {
        updateData,
        dataState: { data },
    } = useGetContractBasedUpdateData(contactId, updateFinancialDetailsPerContract, FinancialDetailsDataSelector);
    return {
        updateFinancialDetails: updateData,
        financialDetailsData: data as FinancialDetails,
    };
}

type UpdateFinancialDetailsStateType = {
    updateFinancialDetailsState: (
        data: AbstractContractBasedDataState<FinancialDetails, DefaultBusinessMarketApiErrorCode | FetchContractError>,
    ) => void;
    financialDetailsState: AbstractContractBasedDataState<
        FinancialDetails,
        DefaultBusinessMarketApiErrorCode | FetchContractError
    >;
};

export function useUpdateFinancialDetailsState(): UpdateFinancialDetailsStateType {
    const { updateState, state } = useGetContractBasedUpdateState(
        updateFinancialDetailsState,
        FinancialDetailsStateSelector,
    );
    return {
        updateFinancialDetailsState: updateState as (
            data: AbstractContractBasedDataState<
                FinancialDetails,
                DefaultBusinessMarketApiErrorCode | FetchContractError
            >,
        ) => void,
        financialDetailsState: state as AbstractContractBasedDataState<
            FinancialDetails,
            DefaultBusinessMarketApiErrorCode | FetchContractError
        >,
    };
}
