import React, { useMemo } from 'react';
import { BrandHeader, Contract, ContractType } from '@cp-es/common';
import { useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { OfflinePaymentDataSelector } from 'components/early-settlement/total-early-settlement/total-early-settlment-offline-payment/OfflinePaymentDataSelector';
import { fetchOfflinePayment } from 'components/early-settlement/total-early-settlement/total-early-settlment-offline-payment/OfflinePaymentSlice';
import { TotalEarlySettlementDataSelector } from 'components/early-settlement/total-early-settlement/TotalEarlySettlementDataSelector';
import { fetchTotalEarlySettlement } from 'components/early-settlement/total-early-settlement/TotalEarlySettlementSlice';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { usePersonalDetails } from 'components/my-profile/usePersonalDetails';
import { DashboardMarketingCardUi } from './ui';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { getMostRecentSeatContract } from './utils';
import { useAdditionalProducts } from './additional-products/useAdditionalProducts';
import { useVehicalDetails } from './vehical-details/useVehicalDetails';
import { flatMap } from 'lodash';

const DashboardMarketingCardUiWithHandlers = withLoadingAndNoConnectionHandler(DashboardMarketingCardUi);

type DashboardMarketingCardProps = {
    contracts?: Contract[];
    setHasMarketingCard: (shouldRender: boolean) => void;
};

export const DashboardMarketingCard: React.FC<DashboardMarketingCardProps> = ({ contracts, setHasMarketingCard }) => {
    const additionalProducts = useAdditionalProducts(contracts);
    const vehicalDetails = useVehicalDetails(contracts);

    const isUserAlreadyBought = useMemo(
        () =>
            flatMap(additionalProducts, entry => entry.data).some(
                additionalProduct =>
                    additionalProduct?.type === 'LONGDRIVE SERVICE' || additionalProduct?.type === 'MANTENIMIENTO',
            ),
        [additionalProducts],
    );

    const isBrandSupportAdditionalServices = useMemo(
        () =>
            flatMap(vehicalDetails, entry => entry.data).some(
                vehicleDetail =>
                    vehicleDetail?.brandHeader === BrandHeader.AUDI ||
                    vehicleDetail?.brandHeader === BrandHeader.SKODA ||
                    vehicleDetail?.brandHeader === BrandHeader.VW,
            ),
        [vehicalDetails],
    );

    const isFinancingCard = useMemo(
        () => contracts?.some(contract => contract.contractType === ContractType.FINANCING),
        [contracts],
    );

    const {
        cmsContent: dashboardMarketingCard,
        isLoading: isLoadingContent,
        loadingError: loadingContentError,
    } = useDashboardMarketingCard();

    const mostRecentSeatContract = getMostRecentSeatContract(contracts);

    const {
        data: personalDetails,
        isLoading: isLoadingProfile,
        loadingError: loadingErrorProfile,
    } = usePersonalDetails();

    const {
        data: totalEarlySettelment,
        isLoading: isLoadingTotalEarlySettelment,
        loadingError: loadingTotalEarlySettelment,
    } = useGetContractBasedApiData(
        mostRecentSeatContract?.contractNumber || '',
        fetchTotalEarlySettlement,
        TotalEarlySettlementDataSelector,
        mostRecentSeatContract?._links?.totalEarlySettlement,
    );

    const {
        data: offlinePayment,
        isLoading: isLoadingOfflinePayment,
        loadingError: loadingOfflinePayment,
    } = useGetContractBasedApiData(
        mostRecentSeatContract?.contractNumber || '',
        fetchOfflinePayment,
        OfflinePaymentDataSelector,
        totalEarlySettelment?._links?.offlinePayment,
    );

    return (
        <DashboardMarketingCardUiWithHandlers
            isLoading={isLoadingContent || isLoadingProfile || isLoadingTotalEarlySettelment || isLoadingOfflinePayment}
            hasError={
                !!loadingErrorProfile ||
                !!loadingContentError ||
                !!loadingTotalEarlySettelment ||
                !!loadingOfflinePayment
            }
            dashboardMarketingCard={dashboardMarketingCard}
            personalDetails={personalDetails}
            mostRecentSeatContract={mostRecentSeatContract}
            offlinePayment={offlinePayment}
            showLongDriveMarketingCard={isFinancingCard && !isUserAlreadyBought && isBrandSupportAdditionalServices}
            setHasMarketingCard={setHasMarketingCard}
        />
    );
};
