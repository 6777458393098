import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer, Card, Heading, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from '../../contracts/ContractHeader';
import { dashboardPagePath, digitalRenewalPagePath } from 'components/navigation/paths';
import { FinanceContract, OperatingLeaseContract, EncryptedContractNumber, DigitalRenewalOptions } from '@cp-es/common';
import DigitalRenewalContentTransformComponent from '../DigitalRenewalContentTransformComponent';
import { makeClientDecision } from '../makeClientDecision';
import { useDispatch } from 'react-redux';
import { setSuccessNotification } from 'components/notifications/digital-renewal/DigitalRenewalNotificationSlice';
import { Spinner } from '@cp-shared-9/frontend-ui';
import { SuccessModal } from '../success-modal/SuccessModal';

type FinanceContractWithVin = FinanceContract & { vinCode?: string } & EncryptedContractNumber;
type OperatingLeaseContractWithVin = OperatingLeaseContract & { vinCode?: string } & EncryptedContractNumber;

type DigitalRenewalJointContractProps = {
    contract?: FinanceContractWithVin | OperatingLeaseContractWithVin;
};

export const DigitalRenewalJointContract: React.FC<DigitalRenewalJointContractProps> = ({ contract }) => {
    const { t } = useTranslation('digital-renewal');
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    if (!contract) {
        return null;
    }

    const { vinCode, carInformation, contractNumber } = contract;
    const heading = t('leasing.joint-contract-page.heading', { brand: carInformation });
    const paymentParagraph = t('leasing.joint-contract-page.paragraph', {
        contractNumber,
        VIN: vinCode,
    });

    const backToDashboard = (): void => history.push(dashboardPagePath());
    const onModalClose = (): void => {
        setIsModalOpen(false);
        backToDashboard();
    };

    const handleSetSuccessMessage = (
        contract: (FinanceContract | OperatingLeaseContract) & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        dispatch(setSuccessNotification({ contract, selectedOption: option }));
    };

    const handleMakeClientDecision = (
        contract: (FinanceContract | OperatingLeaseContract) & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        setIsLoading(true);
        const contractNumber = contract._encryptedContractNumber;
        makeClientDecision(contractNumber, option)
            .then(() => {
                setIsSuccess(true);
                handleSetSuccessMessage(contract, option);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(true);
            });
    };

    const successModalContent = isSuccess
        ? {
              heading: t('leasing.joint_contract.modal-success.heading'),
              description: t('leasing.joint_contract.modal-success.description'),
              buttonContent: t('leasing.joint_contract.modal-success.buttonContent'),
          }
        : isError
        ? {
              heading: t('finance.modal-error.heading'),
              description: t('finance.modal-error.description'),
              buttonContent: t('finance.modal-error.buttonContent'),
          }
        : null;

    return (
        <>
            <Heading level={1}>{heading}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader contract={contract} />
                    </Card>
                </Layout.Item>
                <Layout.Item default="1/1" s="1/1">
                    <Paragraph>
                        <DigitalRenewalContentTransformComponent content={paymentParagraph} />
                    </Paragraph>
                </Layout.Item>
                {isLoading && (
                    <Layout.Item>
                        <Spinner center />
                    </Layout.Item>
                )}
                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    <ButtonContainer center>
                        <Button
                            secondary
                            onClick={(): void =>
                                history.push(digitalRenewalPagePath(contract._encryptedContractNumber))
                            }
                            testId="backButton"
                        >
                            {t('finance.button.back')}
                        </Button>
                        <Button
                            onClick={(): void =>
                                handleMakeClientDecision(contract, DigitalRenewalOptions.JOINT_CONTRACT)
                            }
                            testId="submitButton"
                        >
                            {t('finance.button.submit')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <SuccessModal
                isOpen={isModalOpen}
                onClose={onModalClose}
                onSubmit={onModalClose}
                content={successModalContent}
            />
        </>
    );
};
