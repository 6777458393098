import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditStatus } from '../../EditStatus';
import { WithDefaultMarketApiErrors } from '@cp-shared-9/common-utilities';

export const NotificationForLastEditStatus: React.FC<{
    lastEditStatus: WithDefaultMarketApiErrors<EditStatus>;
    upcomingDueDate?: string;
    hasUnpaidBalance?: boolean;
}> = ({ lastEditStatus, upcomingDueDate, hasUnpaidBalance }) => {
    const { t } = useTranslation('financial-details');
    switch (lastEditStatus) {
        case 'SUCCESS': {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('monthlyDueDateSection.notification.success.upcomingDueDate.headline')}
                    text={t('monthlyDueDateSection.notification.success.upcomingDueDate.text', {
                        upcomingDueDate,
                    })}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'SAME_DATE_SELECTED': {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('monthlyDueDateSection.notification.success.sameDateSelected.headline')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'SUCCESS_PENDING': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('monthlyDueDateSection.notification.success.pendingChanges.headline')}
                    text={t('monthlyDueDateSection.notification.success.pendingChanges.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'CANT_CHANGE_MORE_THAN_TWO_TIMES': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('monthlyDueDateSection.notification.error.cantChangeMoreThanTwoTimes.headline')}
                    testId={'lastEditStatus'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('monthlyDueDateSection.notification.error.cantChangeMoreThanTwoTimes.text'),
                        }}
                    />
                </Notification>
            );
        }
        case 'NO_INSTALMENTS_REMAINING': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('monthlyDueDateSection.notification.error.noInstalmentsRemaining.headline')}
                    testId={'lastEditStatus'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('monthlyDueDateSection.notification.error.noInstalmentsRemaining.text'),
                        }}
                    />
                </Notification>
            );
        }
        case 'MARKET_API_DEFAULT_BUSINESS_ERROR': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('monthlyDueDateSection.notification.error.unexpectedError.headline')}
                    testId={'lastEditStatus'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('monthlyDueDateSection.notification.error.unexpectedError.text'),
                        }}
                    />
                </Notification>
            );
        }
        case 'NOT_PERFORMED': {
            if (hasUnpaidBalance) {
                return (
                    <Notification
                        status={NotificationStatus.info}
                        text={t('monthlyDueDateSection.notification.unpaidBalance.text')}
                        testId={'lastEditStatus'}
                    />
                );
            }
            return null;
        }
        default: {
            return null;
        }
    }
};
