import { useAnalyticsActionTracker, useAuthentication } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { dashboardPagePath } from 'components/navigation/paths';
import { idpHint } from 'config';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getAbsoluteUrlWithParams } from '../utils/urlSearchParams';

export function useLogin(pathFn: () => string = dashboardPagePath, loginHint?: string): () => Promise<void> {
    const { login } = useAuthentication();
    const { i18n } = useTranslation();
    const { pathname } = useLocation();
    const { onAction } = useAnalyticsActionTracker('login');

    const locale = i18n.languages[0];
    const redirectUri = getAbsoluteUrlWithParams(pathFn());

    return useCallback((): Promise<void> => {
        onAction(pathname);
        return login({
            redirectUri,
            locale,
            loginHint,
            idpHint,
        });
    }, [pathname, redirectUri, locale, loginHint, login, onAction]);
}
