import { Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckmarkList } from '@cp-shared-9/frontend-ui';

export const Accessories: React.FC<{ accessories?: string[] }> = ({ accessories }) => {
    const { t } = useTranslation('contracts');

    if (!Array.isArray(accessories) || !accessories.length) {
        return null;
    }

    return (
        <>
            <Heading className={'u-mb'} level={'6'}>
                {t('vehicleDetails.accessories')}
            </Heading>
            <CheckmarkList items={accessories} />
        </>
    );
};
