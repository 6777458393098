import { DashboardMarketingCard } from '@cp-es/common';
import { openInNewWindow, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { Button, Card } from '@vwfs-bronson/bronson-react';
import React, { MouseEvent } from 'react';

type MarketingCardComponentProps = {
    content: DashboardMarketingCard;
    testId?: string;
    className?: string;
    buttonUrl?: string;
};

export const MarketingCardComponent: React.FC<MarketingCardComponentProps> = ({
    content,
    testId,
    className,
    buttonUrl,
}) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');
    const { title, subtitle, imageUrl, text, buttonLabel } = content;

    const onButtonClick = (e: MouseEvent<Element>): void => {
        openInNewWindow(buttonUrl)(e);
        onAction(title);
    };

    if (!buttonUrl) return null;

    return (
        <Card
            element="article"
            title={title}
            subtitle={subtitle}
            imageSrc={imageUrl}
            footer
            testId={testId}
            className={className}
            buttons={
                <Button
                    link
                    icon="semantic-forward"
                    iconReversed
                    small
                    testId={`${testId}-button`}
                    onClick={onButtonClick}
                >
                    {buttonLabel}
                </Button>
            }
        >
            {text}
        </Card>
    );
};
