import React, { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { FormSectionGroup } from '@vwfs-bronson/bronson-react';
import {
    Contract,
    FinanceContract as FinanceContractType,
    OperatingLeaseContract as OperatingLeaseContractType,
    EncryptedContractNumber,
} from '@cp-es/common';
import { ContractsLoadingPlaceholder, useAnalyticsDashboardPageTrackerWithoutUnpaid } from '@cp-shared-9/frontend-ui';
import { isAfterRegistrationLocalStorageKey } from 'config';
import { registrationPagePath, loginPath } from '../navigation/paths';
import { FinanceContract } from './finance-contract';
import { OperatingLeaseContract } from './operating-lease-contract';
import { withDifferentBrandHandler } from '../integration-wrapper/withDifferentBrandHandler';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { useTranslation } from 'react-i18next';

const Contracts: React.FC<{ contractList?: Contract[] }> = ({ contractList }) => {
    const { t } = useTranslation('contracts');
    const lastLocation = useLastLocation();
    const isAfterRegistration = !!window.localStorage.getItem(isAfterRegistrationLocalStorageKey);
    const lastPathname =
        lastLocation === null ? loginPath() : isAfterRegistration ? registrationPagePath() : lastLocation?.pathname;

    useAnalyticsDashboardPageTrackerWithoutUnpaid(true, lastPathname || '');

    useEffect(() => {
        if (isAfterRegistration) {
            window.localStorage.removeItem(isAfterRegistrationLocalStorageKey);
        }
    }, [isAfterRegistration]);

    const getViewChange = (currentViewName: string): string | undefined => {
        switch (currentViewName) {
            case t('financialDetails.headline'):
                return 'Financial details';
            case t('vehicleDetails.headline'):
                return 'Vehicle details';
            case t('contractParties.headline'):
                return 'Contract parties';
            case t('additional-products.headline'):
                return 'Products / Services included';
        }
    };

    return (
        <FormSectionGroup>
            {contractList?.map((contract, contractIndex) => {
                if (contract.isOperatingLeaseContract) {
                    return (
                        <OperatingLeaseContract
                            getViewChange={getViewChange}
                            key={contract.contractNumber}
                            contract={contract as OperatingLeaseContractType & EncryptedContractNumber}
                            links={contract._links}
                            defaultExpanded={contract.isActiveContract && contractIndex === 0}
                        />
                    );
                } else {
                    return (
                        <FinanceContract
                            getViewChange={getViewChange}
                            key={contract.contractNumber}
                            contract={contract as FinanceContractType & EncryptedContractNumber}
                            links={contract._links}
                            defaultExpanded={contract.isActiveContract && contractIndex === 0}
                        />
                    );
                }
            })}
        </FormSectionGroup>
    );
};

const ContractsWithHandlers = withDifferentBrandHandler(withLoadingAndNoConnectionHandler(Contracts));

type ContractsOverviewProps = {
    contracts?: Contract[];
    isLoadingContractsOverview: boolean;
    contractsOverviewLoadingError: boolean;
};

export const ContractsOverview: React.FC<ContractsOverviewProps> = ({
    contracts = [],
    isLoadingContractsOverview,
    contractsOverviewLoadingError,
}) => {
    return (
        <ContractsWithHandlers
            isLoading={isLoadingContractsOverview}
            hasError={!!contractsOverviewLoadingError}
            contractList={contracts}
            loadingPlaceholder={<ContractsLoadingPlaceholder numberOfContracts={1} />}
        />
    );
};
