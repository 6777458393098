import React, { FormEvent, useState } from 'react';
import { Layout, Paragraph, Fieldset, CustomRangeSlider, Textarea, Form, Input } from '@vwfs-bronson/bronson-react';
import { formatAsCurrency, formatAsNumber, MAX_REQUEST } from '@cp-es/common';
import { useTranslation } from 'react-i18next';
import { useFinancialDetails } from 'components/contracts/financial-details/useFinancialDetails';
import { preventSubmit, RequestLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import moment from 'moment';
import { RefinancingFormValues } from '../refinancingFormikValues';

export type RefinanceSliderProps = {
    show: boolean;
    contractId: string;
    formValues: RefinancingFormValues;
    setFormValues: (values: Partial<RefinancingFormValues>) => void;
};

export const RefinanceSlider: React.FC<RefinanceSliderProps> = ({ show, contractId, formValues, setFormValues }) => {
    const { t } = useTranslation('digital-renewal');
    const sliderAmountCurrency = formValues.sliderAmountCurrency;
    const [inputCurrencyValue, setInputCurrencyValue] = useState<number>(sliderAmountCurrency);

    const { data: financialDetails, isLoading } = useFinancialDetails(contractId);

    if (!show) {
        return null;
    }

    if (isLoading) {
        return <RequestLoadingPlaceholder />;
    }

    if (!financialDetails || !('pendingCapital' in financialDetails)) {
        return null;
    }

    const { pendingCapital, startDate, endDate } = financialDetails;

    const currentContractMonths = moment(endDate).diff(startDate, 'months');

    const handleChangeForSliderValueMonths = (value: string): void => {
        const intValue = parseInt(value);

        setFormValues({ sliderAmount: intValue });
    };

    const handleChangeForSliderValueCurrency = (value: string): void => {
        const intValue = parseInt(value);

        setInputCurrencyValue(intValue);
        setFormValues({ sliderAmountCurrency: intValue });
    };

    const handleChangeRequest = (event: FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormValues({ request: event.currentTarget.value });
    };

    const handleChangeForInputCurrencyValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const intValue = parseInt(event.target.value);
        if (!intValue) {
            setInputCurrencyValue(0);
            setFormValues({ sliderAmountCurrency: 0 });
        } else {
            setInputCurrencyValue(intValue);
            setFormValues({ sliderAmountCurrency: intValue });
        }
    };

    return (
        <Form onSubmit={e => preventSubmit(e)}>
            <Paragraph>
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('finance.refinance-or-payment-page.radio-button.refinancing-text'),
                    }}
                />
            </Paragraph>
            <Fieldset className="u-text-center">
                <Fieldset.Row>
                    <Layout.Item default="1/1" s="1/1">
                        <CustomRangeSlider
                            value={formValues.sliderAmount}
                            range={{ min: 0, max: 96 - currentContractMonths }}
                            step={12}
                            tooltips={{
                                to: (value: number): string =>
                                    `${formatAsNumber(+value)} ${t('finance.refinance-or-payment-page.slider.months')}`,
                            }}
                            onSet={(value: string | number | (string | number)[]): void =>
                                handleChangeForSliderValueMonths(value as string)
                            }
                        />
                    </Layout.Item>
                </Fieldset.Row>
                <Fieldset.Row>
                    <Layout.Item className="u-text-center" default="1/4" s={'1/1'}>
                        <Input
                            testId="inputCurrencyValue"
                            type="text"
                            addonText={'€'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                handleChangeForInputCurrencyValue(event);
                            }}
                            value={inputCurrencyValue}
                        />
                    </Layout.Item>
                    <Layout.Item default="1/1" s="1/1">
                        {pendingCapital && (
                            <CustomRangeSlider
                                value={formValues.sliderAmountCurrency}
                                range={{ min: 0, max: pendingCapital }}
                                tooltips={{
                                    to: (value: number): string => formatAsCurrency(+value),
                                }}
                                onSet={(value: string | number | (string | number)[]): void =>
                                    handleChangeForSliderValueCurrency(value as string)
                                }
                            />
                        )}
                    </Layout.Item>
                </Fieldset.Row>

                <Fieldset.Row>
                    <Layout.Item default="1/1" s="1/1">
                        <p style={{ textAlign: 'left' }}>{t('finance.refinance-or-payment-page.text-area')}</p>
                        <Textarea
                            testId="request"
                            inputMode="text"
                            maxCounter={MAX_REQUEST}
                            charCounter
                            value={formValues.request}
                            onInput={e => handleChangeRequest((e as unknown) as FormEvent<HTMLInputElement>)}
                        />
                    </Layout.Item>
                </Fieldset.Row>
            </Fieldset>
        </Form>
    );
};
