import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { VehicleDetails as VehicleDetailsType } from '@cp-es/common';
import { AdditionalEquipments } from './additional-equipments';
import { Accessories } from './accessories';
import { VehicleSummary } from './vehicle-summary';
import { DataOverview } from '@cp-shared-9/frontend-ui';
import { LicensePlate } from '../../../license-plate';

export const SingleVehicleDetails: React.FC<{ details: VehicleDetailsType; numberOfCars: number }> = ({
    details,
    numberOfCars,
}) => {
    const hasAdditionalEquipments =
        Array.isArray(details?.additionalEquipments) && details?.additionalEquipments.length;
    const hasAccessories = Array.isArray(details?.accessories) && details?.accessories.length;

    return (
        <DataOverview title={details.vehicleDescription}>
            <Layout>
                <Layout.Item default="1/1">
                    <LicensePlate size="small" licensePlateNumber={details.licensePlate} numberOfCars={numberOfCars} />
                </Layout.Item>

                <Layout.Item default="1/1">
                    <VehicleSummary details={details} />
                </Layout.Item>

                {!!(hasAdditionalEquipments || hasAccessories) && (
                    <Layout.Item default="1/1">
                        <hr className="u-mb-small" />
                    </Layout.Item>
                )}

                {!!hasAdditionalEquipments && (
                    <Layout.Item default="1/2" s="1/1">
                        <AdditionalEquipments additionalEquipments={details?.additionalEquipments} />
                    </Layout.Item>
                )}
                {!!hasAccessories && (
                    <Layout.Item default="1/2" s="1/1" className={hasAdditionalEquipments ? 'u-pl-xsmall u-pl@s' : ''}>
                        <Accessories accessories={details?.accessories} />
                    </Layout.Item>
                )}
            </Layout>
        </DataOverview>
    );
};
