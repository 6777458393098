import React, { ReactNode } from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';

interface SuccessModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    content: ContentTye | null;
}

export interface ContentTye {
    heading: string;
    description: string | ReactNode;
    buttonContent: string;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, content }) => {
    if (!content) {
        return null;
    }
    const { heading, description, buttonContent } = content;
    return (
        <>
            <Modal
                shown={isOpen}
                buttonConfirmText={buttonContent}
                onConfirm={onClose}
                onClose={onClose}
                title={heading}
            >
                <div>{description}</div>
            </Modal>
        </>
    );
};
