import { Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckmarkList } from '@cp-shared-9/frontend-ui';

export const AdditionalEquipments: React.FC<{ additionalEquipments?: string[] }> = ({ additionalEquipments }) => {
    const { t } = useTranslation('contracts');

    if (!Array.isArray(additionalEquipments) || !additionalEquipments.length) {
        return null;
    }

    return (
        <>
            <Heading className={'u-mb'} level={'6'}>
                {t('vehicleDetails.additionalEquipment')}
            </Heading>
            <CheckmarkList items={additionalEquipments} />
        </>
    );
};
