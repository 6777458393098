import React from 'react';
import { PageHeader } from '../components/page-header/PageHeader';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { RequestCertificate } from '../components/request-certificate';
import { useParams } from 'react-router-dom';

interface RouteParams {
    contractId: string;
}

export const RequestCertificatePage: React.FC = () => {
    const { t } = useTranslation('request-certificate');
    const { contractId: encryptedContractId } = useParams<RouteParams>();

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <RequestCertificate encryptedContractId={encryptedContractId || 'no-contract'} />
        </ContentSection>
    );
};
