import React from 'react';
import { Button, ButtonContainer, Card, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Contract, PartialEarlySettlement } from '@cp-es/common';
import { ComparisonSection } from '../comparison-section';
import { ContractHeader } from '../../../contracts/ContractHeader';
import { CalculationDetails } from '../../calculation-details';
import { CPDate } from '@cp-shared-9/common-utilities';

type QuoteDetailsProps = {
    back: () => void;
    proceedToPayment: () => void;
    contractDetails: Contract;
    partialEarlySettlementDetails?: PartialEarlySettlement;
    paymentDate: CPDate;
};

export const QuoteDetails: React.FC<QuoteDetailsProps> = ({
    back,
    proceedToPayment,
    contractDetails,
    partialEarlySettlementDetails,
    paymentDate,
}) => {
    const { t } = useTranslation('partial-early-settlement');

    if (
        !partialEarlySettlementDetails ||
        !partialEarlySettlementDetails.subsidyDetails ||
        !partialEarlySettlementDetails.settlementDetails
    )
        return null;

    return (
        <>
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader contract={contractDetails} />
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <SectionHeading level="3">{t('details.headline')}</SectionHeading>
                <ComparisonSection
                    contractDetails={contractDetails}
                    partialEarlySettlementDetails={partialEarlySettlementDetails}
                />
            </Layout.Item>

            <Layout.Item default="1/1">
                <SectionHeading level="4" subtitle={t('details.calculated-quote-details.information-text')}>
                    {t('details.calculated-quote-details.headline')}
                </SectionHeading>
                <CalculationDetails
                    subsidyDetails={partialEarlySettlementDetails.subsidyDetails}
                    settlementDetails={partialEarlySettlementDetails.settlementDetails}
                    partialSettlementPaymentDate={paymentDate}
                    partialSettlementTotalAmount={partialEarlySettlementDetails.totalAmount}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                <ButtonContainer center>
                    <Button secondary onClick={back} testId="quoteDetailsBackButton">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button onClick={proceedToPayment} testId="quoteDetailsProceedButton">
                        {t('details.buttons.continue')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </>
    );
};
