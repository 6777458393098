import React, { useState } from 'react';

import { InvoiceConsent, PersonalDetailsEditStatus } from '@cp-es/common';
import { ConsultView } from './consult-view/ConsultView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeInvoiceConsentPath } from '../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

export const ConsentSection: React.FC<{ invoiceConsent?: InvoiceConsent }> = ({ invoiceConsent }) => {
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();
    const history = useHistory();
    const [isSignUp, setIsSignUpStatus] = useState<boolean>(!!invoiceConsent?.signUp);

    const trackingSection = 'Consent settings';
    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileConsentSettings');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const setLastEditStatus = (
        newEditStatus: WithDefaultCpIntegrationErrors<PersonalDetailsEditStatus>,
        updatedSignUp?: boolean,
    ) => {
        updateMyProfile({
            ...myProfileData,
            invoiceConsent: { signUp: updatedSignUp ?? invoiceConsent?.signUp },
            lastEditStatus: { ...myProfileData?.lastEditStatus, consentSettings: newEditStatus },
        });
    };

    const startEditing = (): void => {
        setLastEditStatus('NOT_PERFORMED');
        history.push(changeInvoiceConsentPath());
        onEdit();
    };

    const cancelEditing = (): void => {
        setLastEditStatus('NOT_PERFORMED');
        history.push(path);
        onCancel(trackingSection);
    };
    const finishEditing = (
        newEditStatus: WithDefaultCpIntegrationErrors<PersonalDetailsEditStatus>,
        isNewSignUp: boolean,
    ): void => {
        setLastEditStatus(newEditStatus, isNewSignUp);
        setIsSignUpStatus(isNewSignUp);
        history.push(path);
    };

    if (!invoiceConsent) {
        return null;
    }

    const isChangeLinkPresent = !!invoiceConsent;

    return (
        <Switch>
            {isChangeLinkPresent && (
                <Route exact path={changeInvoiceConsentPath()}>
                    <EditView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        invoiceConsent={invoiceConsent}
                        lastEditStatus={myProfileData?.lastEditStatus?.consentSettings ?? 'NOT_PERFORMED'}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    invoiceConsent={invoiceConsent}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.consentSettings ?? 'NOT_PERFORMED'}
                    isSignUp={isSignUp}
                />
            </Route>
        </Switch>
    );
};
