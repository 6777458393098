import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Card, Heading, Layout, Modal, Paragraph } from '@vwfs-bronson/bronson-react';
import {
    contractRecalculation,
    dashboardPagePath,
    digitalRenewalJointContractPagePath,
    digitalRenewalRefinanceOrPaymentPagePath,
    digitalRenewalReturnOfTheCarPath,
} from 'components/navigation/paths';
import { ContractHeader } from '../../contracts/ContractHeader';
import {
    Contract,
    ContractType,
    DigitalRenewalOptions,
    EncryptedContractNumber,
    FinancialDetails,
} from '@cp-es/common';
import { calculateMonthsLeft } from 'utils/digitalRenewalEligibleContracts';
import { makeClientDecision } from '../makeClientDecision';
import { WarningNotification } from 'components/notifications/digital-renewal/warning-notification';
import { SuccessModal } from '../success-modal/SuccessModal';
import { useDispatch } from 'react-redux';
import { setSuccessNotification } from 'components/notifications/digital-renewal/DigitalRenewalNotificationSlice';
import { Spinner } from '@cp-shared-9/frontend-ui';
import Options from './Options';
import { useBrandName } from './useBrandName';
import moment from 'moment/moment';

export const DigitalRenewalOptionsOverview: React.FC<{
    contract?: Contract & EncryptedContractNumber;
    financialDetails?: FinancialDetails;
}> = ({ contract, financialDetails }) => {
    const { t } = useTranslation('digital-renewal');
    const history = useHistory();
    const brand = useBrandName(contract);
    const [option, setOption] = useState<DigitalRenewalOptions | null>(null);
    const [showEntryNotification, setShowEntryNotification] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const dispatch = useDispatch();

    if (!contract || !brand) {
        return null;
    }

    const { unpaidAmount, unpaidInstalments, carInformation, productModality, contractType } = contract;
    const contractTypeContent = contractType === ContractType.FINANCING ? 'finance' : 'leasing';
    const content = t(`${contractTypeContent}.heading`, { carInformation: carInformation });
    const nextPoint = financialDetails?.nextEngagement?.totalPoints;
    const paragraphNext = t('finance.paragraph-next.text', {
        nextPoint,
        number: nextPoint,
        brand: brand,
    });
    const paragraph = t(`${contractTypeContent}.paragraph.text`, { brand: brand });
    const monthsLeft = calculateMonthsLeft(contract);
    const isUnpaidPresent = !!unpaidAmount && unpaidAmount > 0.0 && !!unpaidInstalments && unpaidInstalments > 0;
    const currentIPOdate = moment(contract.beginDate)
        .add(financialDetails?.ipoShiftMonths || 0, 'months')
        .toDate();
    const next2IpoDate = moment(contract.contractEndDate).toDate();

    const ipoScenario = currentIPOdate < moment().toDate() && moment().toDate() < next2IpoDate;
    const translateOption = option?.toLowerCase();
    const productModalityTranslation = productModality === 'C5' ? 'next' : 'cpc';
    const monthsLeftTranslation = monthsLeft && monthsLeft <= 2 ? '2-month' : 'other-month';
    const successModalContent = isSuccess
        ? monthsLeft && monthsLeft <= 5 && contractType === ContractType.OPERATING_LEASE
            ? {
                  heading: t(
                      `${contractTypeContent}.${translateOption}.${monthsLeftTranslation}.modal-success.heading`,
                  ),
                  description: t(
                      `${contractTypeContent}.${translateOption}.${monthsLeftTranslation}.modal-success.description`,
                  ),
                  buttonContent: t(
                      `${contractTypeContent}.${translateOption}.${monthsLeftTranslation}.modal-success.buttonContent`,
                  ),
              }
            : {
                  heading: t(`finance.${translateOption}.modal-success.heading`),
                  description: t(`finance.${translateOption}.modal-success.description`),
                  buttonContent: t(`finance.${translateOption}.modal-success.buttonContent`),
              }
        : isError
        ? {
              heading: t('finance.modal-error.heading'),
              description: t('finance.modal-error.description'),
              buttonContent: t('finance.modal-error.buttonContent'),
          }
        : null;

    const successModalContentRenewal =
        isSuccess && !isError
            ? {
                  heading: t(
                      `${contractTypeContent}.${translateOption}.${productModalityTranslation}.modal-success.heading`,
                  ),
                  description: t(
                      `${contractTypeContent}.${translateOption}.${productModalityTranslation}.modal-success.description`,
                  ),
                  buttonContent: t(
                      `${contractTypeContent}.${translateOption}.${productModalityTranslation}.modal-success.buttonContent`,
                  ),
              }
            : {
                  heading: t('finance.modal-error.heading'),
                  description: t('finance.modal-error.description'),
                  buttonContent: t('finance.modal-error.buttonContent'),
              };
    const handleButtonClick = (): void => setShowEntryNotification(false);
    const backToDashboard = (): void => history.push(dashboardPagePath());
    const onModalClose = (): void => {
        setIsModalOpen(false);
        backToDashboard();
    };

    const handleSetSuccessMessage = (
        contract: Contract & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        dispatch(setSuccessNotification({ contract, selectedOption: option }));
    };

    const handleMakeClientDecision = (
        contract: Contract & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        setIsLoading(true);
        const contractNumber = contract._encryptedContractNumber;
        makeClientDecision(contractNumber, option)
            .then(() => {
                setIsSuccess(true);
                handleSetSuccessMessage(contract, option);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(true);
            });
    };

    const handleSubmit = (option: DigitalRenewalOptions | null): void => {
        if (!option) {
            return;
        }

        const optionHandlers: Record<DigitalRenewalOptions, () => void> = {
            [DigitalRenewalOptions.RENEWAL]: () => {
                setShowConfirmationModal(true);
            },
            [DigitalRenewalOptions.MORE_INFO]: () => {
                setShowConfirmationModal(true);
            },
            [DigitalRenewalOptions.EXTEND]: () => {
                if (contract.contractType === ContractType.OPERATING_LEASE) {
                    history.push(contractRecalculation(contract._encryptedContractNumber, contract?.vinCode));
                } else {
                    handleMakeClientDecision(contract, DigitalRenewalOptions.EXTEND);
                }
            },
            [DigitalRenewalOptions.REFINANCE]: () => {
                history.push(digitalRenewalRefinanceOrPaymentPagePath(contract._encryptedContractNumber));
            },
            [DigitalRenewalOptions.PAYMENT]: () => {
                history.push(digitalRenewalRefinanceOrPaymentPagePath(contract._encryptedContractNumber));
            },
            [DigitalRenewalOptions.RETURN_OF_THE_CAR]: () => {
                history.push(digitalRenewalReturnOfTheCarPath(contract._encryptedContractNumber));
            },
            [DigitalRenewalOptions.JOINT_CONTRACT]: () => {
                history.push(digitalRenewalJointContractPagePath(contract._encryptedContractNumber));
            },
        };
        optionHandlers[option]();
    };

    return (
        <>
            <Heading level={1}>{content}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader contract={contract} />
                    </Card>
                </Layout.Item>
                <Layout.Item default="1/1" s="1/1">
                    <Heading level={3}>
                        {productModality === 'C5'
                            ? t('finance.paragraph-next.heading')
                            : t(`${contractTypeContent}.paragraph.heading`)}
                    </Heading>
                    <Paragraph>{productModality === 'C5' ? paragraphNext : paragraph}</Paragraph>
                </Layout.Item>
                {isUnpaidPresent && showEntryNotification && (
                    <Layout.Item>
                        <WarningNotification contract={contract} handleButtonClick={handleButtonClick} />
                    </Layout.Item>
                )}
                <Options
                    ipoDate={currentIPOdate}
                    contractEndDate={moment(contract.contractEndDate).toDate()}
                    monthsLeft={monthsLeft}
                    contractType={contractType}
                    contractTypeContent={contractTypeContent}
                    productModality={productModality}
                    isUnpaidPresent={isUnpaidPresent}
                    ipoScenario={ipoScenario}
                    setOption={setOption}
                    financialDetails={financialDetails}
                    brand={brand}
                />
                {isLoading && (
                    <Layout.Item>
                        <Spinner center />
                    </Layout.Item>
                )}

                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    <ButtonContainer center>
                        <Button secondary onClick={backToDashboard} testId="backButton">
                            {t('finance.button.back')}
                        </Button>
                        <Button
                            disabled={isUnpaidPresent ? true : option === null}
                            onClick={(): void => handleSubmit(option)}
                            testId="submitButton"
                        >
                            {t('finance.button.submit')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <SuccessModal
                isOpen={isModalOpen}
                onClose={onModalClose}
                onSubmit={onModalClose}
                content={
                    contractTypeContent === 'finance' && option === DigitalRenewalOptions.RENEWAL
                        ? successModalContentRenewal
                        : successModalContent
                }
            />
            <Modal
                shown={showConfirmationModal}
                buttonConfirmText={t(`${contractTypeContent}.${translateOption}.confirm-modal.submit`)}
                onConfirm={(): void => {
                    setShowConfirmationModal(false);
                    if (option === DigitalRenewalOptions.RENEWAL) {
                        handleMakeClientDecision(contract, option);
                    } else {
                        handleMakeClientDecision(contract, DigitalRenewalOptions.MORE_INFO);
                    }
                }}
                onCancel={(): void => {
                    setShowConfirmationModal(false);
                }}
                testId="confirmModal"
                hideCloseButton
                buttonConfirmType={'submit'}
                buttonCancelText={t(`${contractTypeContent}.${translateOption}.confirm-modal.cancel`)}
            >
                {t(`${contractTypeContent}.${translateOption}.confirm-modal.description`)}
            </Modal>
        </>
    );
};
