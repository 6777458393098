import { CustomRangeSlider, FormField } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { boundry } from '../ContractRecalculationFormValues';

type RangeSliderProps = {
    name: string;
    type: 'distance' | 'duration';
    currentValue: number;
};
export const FormRangeSlider: React.FC<RangeSliderProps> = ({ name, type, currentValue }) => {
    const { t } = useTranslation('contract-recalculation');
    const minimum = boundry[type].minimum;
    const maximum = boundry[type].maximum;

    const unitSuffix = ' ' + t(type + '-unit');
    const [sliderAmount, setSliderAmount] = useState<number>(currentValue);

    const [, , helpers] = useField({ name, type: 'input' });
    const handleChangeForSliderValue = (value: string): void => {
        const intValue = parseInt(value);
        setSliderAmount(intValue);
        helpers.setValue(intValue);
    };
    return (
        <FormField type="input">
            <CustomRangeSlider
                testId={'slider' + type[0].toUpperCase() + type.slice(1) + 'Amount'}
                value={sliderAmount}
                range={{ min: minimum, max: maximum }}
                pips={{
                    mode: 'steps',
                    density: 2 * maximum,
                    format: {
                        to: (value: number): string => {
                            return `${value}` + unitSuffix;
                        },
                    },
                }}
                tooltips={{
                    to: (value: number): string => {
                        return `${value}` + unitSuffix;
                    },
                }}
                onSet={(value: string | number | (string | number)[]): void =>
                    handleChangeForSliderValue(value as string)
                }
            />
        </FormField>
    );
};
