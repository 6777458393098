import React from 'react';

import { Combobox } from '@vwfs-bronson/bronson-react';
import { PostboxDocument } from '@cp-es/common';
import { useTranslation } from 'react-i18next';

import { AllTimeframes, getListOfAvailableTimeframes, Timeframe } from '../../helper/Timeframe';
import { ComboboxOptions, ComboboxState } from '../../../../types/bronson-react';

export type TimeframeFilterProps = {
    postboxDocuments?: PostboxDocument[];
    onChange: (value: Timeframe | AllTimeframes) => void;
};

export const TimeframeFilter: React.FC<TimeframeFilterProps> = ({ postboxDocuments, onChange }) => {
    const { t } = useTranslation('postbox');

    if (!postboxDocuments) {
        return null;
    }

    const selectValueChange = (selected: ComboboxOptions): void => {
        onChange(selected[0].key as Timeframe | AllTimeframes);
    };

    const availableOptionsWithTranslations = getListOfAvailableTimeframes(postboxDocuments).map(timeframe => ({
        ...timeframe,
        translation: t(`filters.timeframe.select-items.${timeframe.translation}`),
    }));

    const availableOptionElements = availableOptionsWithTranslations.map(timeframe => {
        return (
            <Combobox.Item
                key={timeframe.key}
                testId={`TimeframeFilterItem-${timeframe.key}`}
                optionKey={timeframe.key}
                optionValue={timeframe.translation}
            >
                <div>{timeframe.translation}</div>
            </Combobox.Item>
        );
    });

    const defaultItem: ComboboxState = {
        value: [
            {
                key: availableOptionsWithTranslations[0].key,
                value: availableOptionsWithTranslations[0].translation,
            },
        ],
    };

    return (
        <>
            <label>{t('filters.timeframe.label')}</label>
            <Combobox testId={'TimeframeFilter'} onChange={selectValueChange} defaultState={defaultItem}>
                {availableOptionElements}
            </Combobox>
        </>
    );
};
