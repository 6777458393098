import { OpenRequestRentingCategoriesSalesforce, SelectItemLabelValueCategories } from '@cp-es/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-9/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const operativeLeasingCategories: SelectItemLabelValueCategories[] = [
    ['invoices', OpenRequestRentingCategoriesSalesforce.INVOICES],
    ['insurance', OpenRequestRentingCategoriesSalesforce.INSURANCE],
    ['change-contact-details', OpenRequestRentingCategoriesSalesforce.CHANGE_CONTACT_DETAILS],
    ['contract-information', OpenRequestRentingCategoriesSalesforce.CONTRACT_INFORMATION],
    ['unpaid-balance', OpenRequestRentingCategoriesSalesforce.UNPAID_BALANCE],
    ['replacement-vehicle', OpenRequestRentingCategoriesSalesforce.REPLACEMENT_VEHICLE],
    ['traffic-fines', OpenRequestRentingCategoriesSalesforce.TRAFFIC_FINES],
    ['parking-card', OpenRequestRentingCategoriesSalesforce.PARKING_CARD],
    ['foreign-country-noc', OpenRequestRentingCategoriesSalesforce.FOREIGN_COUNTRY_NOC],
    ['low-emission', OpenRequestRentingCategoriesSalesforce.LOW_EMISSION],
    ['sepa-mandate', OpenRequestRentingCategoriesSalesforce.SEPA_MANDATE],
    ['other-issues-operative-leasing', OpenRequestRentingCategoriesSalesforce.OTHER_ISSUES_OPERATIVE_LEASING],
];

export const OperativeLeasingDropdown: React.FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation('my-request');

    const validatedSelectItems: ValidatedSelectItem[] = operativeLeasingCategories.map((category, index) => {
        const categoryEntryText = t(`form.operative-leasing-categories.${category[0]}`);
        return {
            label: categoryEntryText,
            value: category[1],
            key: index,
        };
    });

    return (
        <>
            <ValidatedSelect
                label={t(`form.category.headline`)}
                name={name}
                selectItems={validatedSelectItems}
                testId={`operativeLeasing-${name}`}
                placeholder={t('form.category.placeholder')}
                disablePlaceholder={true}
            />
        </>
    );
};
