import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NoConnectionNotification } from '../components/notifications/no-connection/NoConnectionNotification';
import { PageHeader } from '../components/page-header/PageHeader';
import { PartialEarlySettlement } from '../components/early-settlement/partial-early-settlement';

interface RouteParams {
    contractId: string;
}

export const PartialEarlySettlementPage: React.FC = () => {
    const { t } = useTranslation('partial-early-settlement');
    const { contractId: encryptedContractId } = useParams<RouteParams>();
    if (!encryptedContractId) return <NoConnectionNotification />;
    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <PartialEarlySettlement encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
