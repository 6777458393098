import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { TotalEarlySettlementDetails, EarlySettlementSubsidyDetails } from '@cp-es/common';
import { Button, ButtonContainer, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import { TotalEarlySettlementViewType } from '../TotalEarlySettlementViewType';
import { CalculationDetails } from '../../calculation-details';
import { useTranslation } from 'react-i18next';

type QuoteDetailsProps = {
    cancel: () => void;
    proceedToOfflinePayment: () => void;
    settlementDetails: TotalEarlySettlementDetails;
    subsidyDetails: EarlySettlementSubsidyDetails;
    currentView: TotalEarlySettlementViewType;
};

export const QuoteDetails: React.FC<QuoteDetailsProps> = ({
    cancel,
    proceedToOfflinePayment,
    settlementDetails,
    subsidyDetails,
    currentView,
}) => {
    const { t } = useTranslation('total-early-settlement');
    return (
        <Layout>
            {currentView === 'TES_TODAY_QUOTE' && (
                <Layout.Item default="1/1">
                    <SectionHeading level="3" subtitle={t('settlement-details.information-text')}>
                        {t('settlement-details.headline')}
                    </SectionHeading>
                </Layout.Item>
            )}

            <Layout.Item default="1/1">
                <CalculationDetails subsidyDetails={subsidyDetails} settlementDetails={settlementDetails} />
            </Layout.Item>
            {currentView === 'TES_FUTURE_QUOTE_SIMULATION' && (
                <Layout.Item>
                    <Notification
                        status={NotificationStatus.info}
                        headline={t('future-quote-simulation.quote-details.headline')}
                        text={t('future-quote-simulation.quote-details.text')}
                    />
                </Layout.Item>
            )}
            <Layout.Item default="1/1">
                <ButtonContainer center>
                    <Button secondary onClick={cancel} testId="quoteDetailsCancelButton">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button onClick={proceedToOfflinePayment} testId="quoteDetailsProceedButton">
                        {t('buttons.proceed-to-payment')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
