import React from 'react';
import { SectionHeading, Layout, Card } from '@vwfs-bronson/bronson-react';
import { useTranslationWithFormatting, TranslationFormat } from 'localization/useTranslationWithFormatting';
import { TotalEarlySettlementOfflinePayment } from '@cp-es/common';
import {
    DefinitionListHorizontal,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-9/frontend-ui';
import { CPDate } from '@cp-shared-9/common-utilities';

type OfflinePaymentContentProps = {
    offlinePaymentDetails?: TotalEarlySettlementOfflinePayment;
    requestedPaymentDate?: CPDate;
};

export const OfflinePaymentContent: React.FC<OfflinePaymentContentProps> = ({
    offlinePaymentDetails,
    requestedPaymentDate,
}) => {
    const { t, f } = useTranslationWithFormatting('total-early-settlement');
    if (!offlinePaymentDetails) {
        return null;
    }
    const { bankName, iban, codeTransfer, totalAmount, paymentDate } = offlinePaymentDetails;
    const calculatedPaymentDate = requestedPaymentDate ? requestedPaymentDate : paymentDate;
    const contractInformationList: DefinitionListItem[] = [
        {
            label: t('offline-payment.bank-detail-box.bank'),
            value: bankName,
        },
        {
            label: t('offline-payment.bank-detail-box.iban'),
            value: iban,
        },
        {
            label: t('offline-payment.bank-detail-box.concept-to-include.text'),
            value: codeTransfer,
            tooltip: t('offline-payment.bank-detail-box.concept-to-include.tooltip'),
        },
    ];
    return (
        <Layout>
            <Layout.Item default="1/1">
                <SectionHeading level="3" subtitle={t('offline-payment.text')}>
                    {t('offline-payment.headline')}
                </SectionHeading>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Card element="article">
                    <SectionHeading level="3">{t('offline-payment.bank-detail-box.headline')}</SectionHeading>
                    <Layout equalHeight divider>
                        <Layout.Item default="1/2" s="1/1">
                            <DefinitionListHorizontal list={contractInformationList} />
                        </Layout.Item>
                        <Layout.Item default="1/2" s="1/1">
                            <Layout>
                                <Layout.Item default="1/2" s="1/1">
                                    <h4>{t('offline-payment.bank-detail-box.total-amount')}</h4>
                                    <h6>{t('offline-payment.bank-detail-box.payment-date')}</h6>
                                </Layout.Item>
                                <Layout.Item default="1/2" s="1/1">
                                    <h4>{f(totalAmount, TranslationFormat.CURRENCY)}</h4>
                                    <h6>{f(calculatedPaymentDate, TranslationFormat.DATE)}</h6>
                                </Layout.Item>
                            </Layout>
                        </Layout.Item>
                    </Layout>
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Notification
                    className="u-mt"
                    status={NotificationStatus.warning}
                    headline={t('offline-payment.info-box.title', { date: f(paymentDate, TranslationFormat.DATE) })}
                    text={t('offline-payment.info-box.text')}
                />
            </Layout.Item>
        </Layout>
    );
};
