import React from 'react';
import { NextEngagement } from '@cp-es/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';

export const NextEngagementSection: React.FC<{ nextEngagement: NextEngagement }> = ({ nextEngagement }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    const { engagedMonths, totalPoints, thisMonthPoints } = nextEngagement;

    const financeNewEngagementSectionItemList: DefinitionListItem[] = [
        {
            label: t('nextEngagementSection.engagedMonths.label'),
            value: f(engagedMonths, TranslationFormat.NUMBER),
            tooltip: t('nextEngagementSection.engagedMonths.tooltip'),
        },
        {
            label: t('nextEngagementSection.totalPoints.label'),
            value: f(totalPoints, TranslationFormat.NUMBER_ROUNDED_TWO),
            tooltip: t('nextEngagementSection.totalPoints.tooltip'),
        },
        {
            label: t('nextEngagementSection.monthPoints.label'),
            value: f(thisMonthPoints, TranslationFormat.NUMBER_ROUNDED_TWO),
            tooltip: t('nextEngagementSection.monthPoints.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview title={t('nextEngagementSection.title')}>
            <DefinitionListHorizontal list={financeNewEngagementSectionItemList} />
        </DataOverview>
    );
};
