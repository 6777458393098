import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Card, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import {
    dashboardPagePath,
    partialEarlySettlementPagePath,
    totalEarlySettlementPagePath,
} from 'components/navigation/paths';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

export const JointEntryPoint: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { t } = useTranslation('joint-early-settlement');
    const { onAction: trackOnEarlySettlementActionEntryPointClick } = useAnalyticsActionTracker(
        'onEarlySettlementActionEntryPointClick',
    );
    const history = useHistory();
    const backToDashboard = (): void => {
        history.push(dashboardPagePath());
    };
    const partialEarlySettlementPage = (): void => {
        trackOnEarlySettlementActionEntryPointClick('Partial Early Settlement');
        history.push(partialEarlySettlementPagePath(encryptedContractId));
    };
    const totalEarlySettlementPage = (): void => {
        trackOnEarlySettlementActionEntryPointClick('Total Early Settlement');
        history.push(totalEarlySettlementPagePath(encryptedContractId));
    };

    if (!encryptedContractId) {
        return null;
    }
    const cards = [
        {
            testId: 'partialEarlySettlement',
            icon: 'semantic-calculator',
            title: t('joint-entry-point.partial.title'),
            body: t('joint-entry-point.partial.body'),
            pagePath: () => partialEarlySettlementPage(),
        },
        {
            testId: 'totalEarlySettlement',
            icon: 'semantic-checkmark',
            title: t('joint-entry-point.total.title'),
            body: t('joint-entry-point.total.body'),
            pagePath: () => totalEarlySettlementPage(),
        },
    ];

    return (
        <>
            <SectionHeading level="3" subtitle={t('joint-entry-point.sub-headline')}>
                {t('joint-entry-point.headline')}
            </SectionHeading>
            <Layout>
                <Layout.Item default="1/1">
                    <Layout className={'o-layout--equal-height'}>
                        {cards.map((card, index) => (
                            <Layout.Item key={index} default="1/2" m="1/1">
                                <Card
                                    testId={`${card.testId}Card`}
                                    className="u-mt"
                                    element="article"
                                    icon={card.icon}
                                    title={card.title}
                                    footer={true}
                                    buttons={[
                                        <Button testId={`${card.testId}Button`} key={index} onClick={card.pagePath}>
                                            {t('translation:editableSectionNav.continue')}
                                        </Button>,
                                    ]}
                                >
                                    {card.body}
                                </Card>
                            </Layout.Item>
                        ))}
                    </Layout>
                </Layout.Item>
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button secondary onClick={backToDashboard} testId="backButton">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
