import React from 'react';

import {
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-9/frontend-ui';

import { landingPagePath, registrationPagePath } from 'components/navigation/paths';
import { getUserRegistryStatusEndpoint, UserRegistryStatus } from '@cp-es/common';
import { CpDataApi } from 'cp-xhr';
import { RedirectWithParams } from '../utils/redirectWithParams';

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <RedirectWithParams to={landingPagePath()} />,
});

const authDataProvider = async (): Promise<{ isAuthorized: boolean }> => {
    try {
        const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
        return { isAuthorized: registrationData.isRegistered };
    } catch (e) {
        return { isAuthorized: false };
    }
};

const { Authorization: UserRegistrationAuthorization } = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: authDataProvider,
    onMissingAuthorization: <RedirectWithParams to={registrationPagePath()} />,
    onLoading: <Spinner center />,
    onError: <RedirectWithParams to={landingPagePath()} />,
});

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistrationAuthorization);
