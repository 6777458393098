import { CPDate } from '@cp-shared-9/common-utilities';
import { ValidationError } from './validation-types';

export interface EarlySettlementHeader {
    /**
     * Accumulated points (impuntos), as displayed in Financial Details
     */
    totalPoints?: number;
    /**
     * Months of engagement (qmesvali), as displayed in Financial Details
     */
    monthsOfEngagement?: number;
    /**
     * Pending Installments as displayed in Amortization Table Installments Tile
     */
    pendingInstallments?: number;
}

export interface TotalEarlySettlement {
    settlementDetails: TotalEarlySettlementDetails;
    subsidyDetails: EarlySettlementSubsidyDetails;
    _links?: TotalEarlySettlementLinks;
}

export interface PartialEarlySettlement {
    newInstallmentAmount: number;
    formerEndDate: CPDate;
    endDate: CPDate;
    formerRemainingInstallments: number;
    remainingInstallments: number;
    formerPendingAmount: number;
    pendingAmount: number;
    codeTransfer: string;
    optionType: EarlySettlementOptionType;
    totalAmount: number;
    settlementDetails: PartialEarlySettlementDetails;
    subsidyDetails: EarlySettlementSubsidyDetails;
    _links?: PartialEarlySettlementLinks;
}

export interface PartialEarlySettlementDetails extends CommonEarlySettlementDetails {
    amortizedCapital: number;
    pendingArrears?: number;
}

export interface TotalEarlySettlementDetails extends CommonEarlySettlementDetails {
    /**
     * Pending security amount (pending_collateral).
     */
    pendingCollateral?: number;
    /**
     * Outstanding capital on the contract (pendingcapital_amount).
     */
    outstandingCapital: number;
    /**
     * Calculated settlement amount using this formula:
     * settlement amount = (pendingcapital_amount + accruedinterest_amount + settlementfee_amount
     * - pending_settlements - pending_collateral)
     */
    settlementAmount: number;
    /**
     * Payment date (payment_date)
     */
    paymentDate: CPDate;
    /**
     * Total early settlement amount (total_earlysettlement).
     */
    totalAmount: number;
}

export interface CommonEarlySettlementDetails {
    /**
     * Accrued interest from the last installment until the end of your contract (accruedinterest_amount).
     */
    accruedInterestAmount?: number;
    /**
     * Cancellation fee. This amount is the result of applying the settlement fee % to your outstanding capital (settlementfee_amount).
     */
    settlementFeeAmount?: number;
    /**
     * % applied as the cancellation fee. You can consult this information in your contract (settlementfee_rate).
     */
    settlementFeeRate?: number;
    /**
     * Upfront paid installments (pending_settlements).
     */
    pendingSettlements?: number;
    /**
     * Unpaid amount including overdue installments and interest on arrears (unpaid_balance).
     */
    unpaidBalance?: number;
    /**
     * First installment included in the total early settlement (next_duedate).
     */
    nextDueDate?: CPDate;
    /**
     * Pending installments to be cancelled until the end of the contract (TES: settled_period) (PES: cancelled_installments).
     */
    installmentsToSettle?: number;

    openingComAmount?: number;
}

export interface EarlySettlementSubsidyDetails {
    freeInstallment?: number;
    autoInsurance?: number;
    breakdownInsurance?: number;
    maintenance?: number;
    discountPlanE?: number;
    primaryDiscountVWFS?: number;
    secondaryDiscountVWFS?: number;
    subsidyAmount?: number;
}

export interface TotalEarlySettlementOfflinePayment {
    bankName: string;
    iban: string;
    codeTransfer: string;
    totalAmount: number;
    paymentDate: CPDate;
}

export interface TotalEarlySettlementLinks {
    offlinePayment: string;
    offlinePaymentEmailSending: string;
}

export interface PartialEarlySettlementLinks {
    offlinePaymentEmailSending: string;
}

export interface TotalEarlySettlementSimulationRequest {
    requestDate: string;
}

export interface PartialEarlySettlementRequest {
    date: CPDate;
    amount: number;
}

export interface EarlySettlementEmailRequest {
    optionType: EarlySettlementOptionType;
}

export type EarlySettlementError =
    | 'CONTRACTUAL_SITUATION_ERROR'
    | 'AVAILABLE_AMOUNT_LESS_THAN_PENALTY_AMOUNT'
    | 'ERROR_IN_AMOUNT_WITHOUT_CONTENT'
    | ValidationError;
export type TotalEarlySettlementError = 'CONTRACTUAL_SITUATION_ERROR' | ValidationError;
export type TotalEarlySettlementOfflinePaymentError = ValidationError;
export type EarlySettlementOptionType = 'SPP' | 'SPI' | 'STO';
export const EARLY_SETTLEMENT_OPTIONS: EarlySettlementOptionType[] = ['SPP', 'SPI', 'STO'];
