import { formatAsCurrency, formatAsDate, undefinedReturnValueHyphen } from '@cp-es/common';
import { CPDate } from '@cp-shared-9/common-utilities';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-9/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type FinanceContractHeaderSummaryProps = {
    nextInstalmentAmount?: number;
    nextInstalmentDate?: CPDate;
    totalAmount?: number;
};

export const FinanceContractHeaderSummary: React.FC<FinanceContractHeaderSummaryProps> = ({
    nextInstalmentAmount,
    nextInstalmentDate,
    totalAmount,
}) => {
    const { t } = useTranslation('contracts');
    const items: Array<ContractSummaryItemProps> = [
        {
            label: t('nextInstalmentDate'),
            value: nextInstalmentDate ? formatAsDate(nextInstalmentDate) : '-',
            testClass: 'next-instalment-date',
        },
        {
            label: t('nextInstalmentAmount.label'),
            value: formatAsCurrency(nextInstalmentAmount, undefinedReturnValueHyphen),
            testClass: 'next-instalment-amount',
        },
        {
            label: t('totalAmount'),
            value: formatAsCurrency(totalAmount, undefinedReturnValueHyphen),
            testClass: 'total-amount',
        },
    ];

    return <ContractSummary items={items} />;
};
