import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerType, FinancialDetailsEditStatus } from '@cp-es/common';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { withLoadingHandler } from 'components/integration-wrapper';
import { useCustomerTypeWithDefaultValue } from 'components/customer-type/useCustomerType';

type NotificationForLastEditStatusProps = { lastEditStatus: FinancialDetailsEditStatus; forOperatingLease?: boolean };

const NotificationForLastEditStatusUi: React.FC<NotificationForLastEditStatusProps & {
    customerType: CustomerType;
}> = ({ lastEditStatus, forOperatingLease = false }) => {
    const { t } = useTranslation('financial-details');
    const translationLabelPrefix = `ibanSection.consultView.editStatus.${
        forOperatingLease ? 'operatingLease' : 'finance'
    }`;

    switch (lastEditStatus) {
        case 'SUCCESS': {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t(`${translationLabelPrefix}.success.title`)}
                    text={t(`${translationLabelPrefix}.success.text`)}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'SUCCESS_PENDING': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t(`${translationLabelPrefix}.successPending.title`)}
                    text={t(`${translationLabelPrefix}.successPending.text`)}
                    testId={'lastEditStatus'}
                />
            );
        }
        default: {
            return null;
        }
    }
};

const NotificationForLastEditStatusWithHandlers = withLoadingHandler(NotificationForLastEditStatusUi);

export const NotificationForLastEditStatus: React.FC<NotificationForLastEditStatusProps> = ({ lastEditStatus }) => {
    const { data: customerType, isLoading } = useCustomerTypeWithDefaultValue();

    return lastEditStatus === 'NOT_PERFORMED' ? null : (
        <NotificationForLastEditStatusWithHandlers
            lastEditStatus={lastEditStatus}
            customerType={customerType}
            isLoading={isLoading}
        />
    );
};
