import { ContentSection } from '@vwfs-bronson/bronson-react';
import { RequestSubrogation } from 'components/request-subrogation/RequestSubrogation';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NoConnectionNotification } from '../components/notifications/no-connection/NoConnectionNotification';

interface RouteParams {
    contractId: string;
}

export const RequestSubrogationPage: React.FC = () => {
    const { contractId: encryptedContractId } = useParams<RouteParams>();
    if (!encryptedContractId) return <NoConnectionNotification />;

    return (
        <ContentSection pageWrapSize="medium">
            <RequestSubrogation encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
