import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatAsEmission, VehicleDetails } from '@cp-es/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';

export type VehicleSummaryProps = {
    details: VehicleDetails;
};

export const VehicleSummary: React.FC<VehicleSummaryProps> = ({ details }) => {
    const { t } = useTranslation('contracts');

    const summaryItems: DefinitionListItem[] = [
        {
            label: t('vehicleDetails.vinCode'),
            value: details.vinCode,
        },
    ];
    if (typeof details.emissionCO2 === 'number') {
        summaryItems.push({
            label: t('vehicleDetails.emissionCO2'),
            value: formatAsEmission(details.emissionCO2),
        });
    }

    return <DefinitionListHorizontal list={summaryItems} />;
};
