import * as Yup from 'yup';
import { RegistrationData } from '../../apis';
import { IbanLast5DigitsValidatorError, RegistrationDataValidatorError } from '../types';
import { defaultIbanLast5DigitsValidatorError, defaultRegistrationDataValidatorError } from '../messages';
import { isValidCif, isValidNif } from './spanish-id';
import { formatCpDate } from '@cp-shared-9/common-utilities';

const ibanLast5DigitsValidator = (
    errors: IbanLast5DigitsValidatorError = defaultIbanLast5DigitsValidatorError,
): Yup.StringSchema =>
    Yup.string()
        .required(errors.required)
        .length(5, errors.length)
        .matches(new RegExp('^\\d{5}$'), errors.matches);

const passportRegExp = new RegExp(`^[A-PR-WY][A-Z0-9]{1,42}[1-9]$`);

export const registrationDataValidator = (
    errors: RegistrationDataValidatorError = defaultRegistrationDataValidatorError,
): Yup.SchemaOf<RegistrationData> =>
    Yup.object()
        .shape({
            ibanDigits: ibanLast5DigitsValidator(errors.ibanDigits),
            personalId: Yup.string()
                .trim()
                .required(errors.personalId.required)
                .test(
                    'format',
                    errors.personalId.format,
                    (id: string): boolean => isValidNif(id) || isValidCif(id) || passportRegExp.test(id),
                ),
            identificationDate: Yup.date()
                .required(errors.identificationDate.required)
                .typeError(errors.identificationDate.wrongFormat)
                .max(formatCpDate().toCpDate(), errors.identificationDate.max)
                .min(
                    formatCpDate()
                        .subtract(300, 'years')
                        .toCpDate(),
                    errors.identificationDate.min,
                ),
        })
        .defined();
