import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { FetchContractError } from '@cp-es/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';

export const NotificationForSmeContractsFetch: React.FC<{
    fetchContractError: WithDefaultCpIntegrationErrors<FetchContractError>;
}> = ({ fetchContractError }) => {
    const { t } = useTranslation('financial-details');

    switch (fetchContractError) {
        case 'CUSTOMER_NOT_FOUND': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('ibanSection.editView.smeContractsView.notification.customerNotFound.headline')}
                    text={t('ibanSection.editView.smeContractsView.notification.customerNotFound.text')}
                    testId={'editStatus'}
                />
            );
        }
        default: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('ibanSection.editView.smeContractsView.notification.apiNotReachable.headline')}
                    text={t('ibanSection.editView.smeContractsView.notification.apiNotReachable.text')}
                    testId={'editStatus'}
                />
            );
        }
    }
};
