import { Contract, enrichContracts, PersonalDetails } from '@cp-es/common';
import { transformOnlyDefinedValues, useGetSimpleApiDataWithTransformer } from '@cp-shared-9/frontend-integration';
import { Notification, NotificationStatus, MyProfileLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContractsDataSelector } from '../contracts/ContractsDataSelector';
import { fetchContracts } from '../contracts/ContractsSlice';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { AddressSection } from './address-section/AddressSection';
import { ConsentSection } from './consent-section/ConsentSection';
import { ContactSection } from './contact-section/ContactSection';
import { IdentificationSection } from './identification-section/IdentificationSection';
import { usePersonalDetails } from './usePersonalDetails';
import { useUpdateMyProfile } from './useUpdateMyProfile';

export type MyProfileProps = {
    details?: PersonalDetails;
    contracts?: Contract[];
};

export const MyProfile: React.FC<MyProfileProps> = ({ details, contracts }) => {
    const { t } = useTranslation('my-profile');

    if (!details) {
        return null;
    }

    const hasOperativeLeaseContracts = contracts
        ? contracts.some(contract => contract.isOperatingLeaseContract)
        : false;

    return (
        <>
            <Notification status={NotificationStatus.info} text={t('headlineInfo')} />
            <hr />
            <IdentificationSection identification={details.identification} />
            <AddressSection address={details.address} />
            <ContactSection contact={details.contact} hasOperativeLeaseContracts={hasOperativeLeaseContracts} />
            <ConsentSection invoiceConsent={details.invoiceConsent} />
        </>
    );
};

const MyProfileWithWrappers = withLoadingAndNoConnectionHandler(MyProfile);

export const MyProfileOverview: React.FC = () => {
    const {
        data: myDetailsList,
        isLoading: isLoadingProfile,
        loadingError: loadingErrorProfile,
    } = usePersonalDetails();

    const {
        data: contracts,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContracts,
    } = useGetSimpleApiDataWithTransformer(
        fetchContracts,
        ContractsDataSelector,
        transformOnlyDefinedValues(enrichContracts),
    );
    const isLoading = isLoadingProfile || isLoadingContracts;
    const hasError = !!loadingErrorProfile && !!loadingErrorContracts;
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const resetLastEditStatus = () => {
        updateMyProfile({
            ...myProfileData,
            lastEditStatus: {
                contact: 'NOT_PERFORMED',
                address: 'NOT_PERFORMED',
                consentSettings: 'NOT_PERFORMED',
            },
        });
    };

    useEffect(() => {
        resetLastEditStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MyProfileWithWrappers
            isLoading={isLoading}
            hasError={hasError}
            details={myDetailsList}
            contracts={contracts}
            loadingPlaceholder={<MyProfileLoadingPlaceholder />}
        />
    );
};
