import React, { useEffect } from 'react';
import { ErrorPage, ErrorPageProps } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { IdentityBrokerErrorType } from './types';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../navigation/paths';
import { useLogin } from '../../../auth/useLogin';
import { getGenericErrorTranslationPrefix } from './utils';

export const IdentityBrokerError: React.FC<{ type: IdentityBrokerErrorType }> = ({ type }) => {
    const { t } = useTranslation('identity-broker-error-pages');
    const history = useHistory();

    useEffect(() => {
        if (type === 'ALREADY_LOGIN_ERR') {
            history.push(dashboardPagePath());
        }
    }, [type, history]);

    const login = useLogin();
    const onButtonClick = () => {
        login();
    };

    const translationPrefix = getGenericErrorTranslationPrefix(type);

    const errorPageProps: ErrorPageProps = {
        headline: t(`${translationPrefix}.headline`),
        testId: type,
        notificationText: t(`${translationPrefix}.notification`),
        buttonLabel: t(`${translationPrefix}.button`),
        onButtonClick,
        pageWrapSize: 'xsmall',
    };
    return <ErrorPage {...errorPageProps} />;
};
