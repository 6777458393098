import React from 'react';
import { HeroImage, StaticPageLoadingPlaceholder, useAnalyticsErrorPageTracker } from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useLogin } from '../../../auth/useLogin';
import { useUnauthorizedErrorPage } from './useUnauthorizedErrorPage';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const UnauthorizedErrorPage: React.FC = () => {
    const { cmsContent: unauthorizedErrorContent, isLoading, loadingError } = useUnauthorizedErrorPage();

    useAnalyticsErrorPageTracker('unauthorizedError', !!unauthorizedErrorContent);
    const onLoginCallback = useLogin();

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={unauthorizedErrorContent?.pretitle}
            title={unauthorizedErrorContent?.title || ''}
            subTitle={unauthorizedErrorContent?.subTitle}
            buttonText={unauthorizedErrorContent?.buttonText}
            clickHandler={onLoginCallback}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
        />
    );
};
