import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { LegalNoticeDto as LegalNoticeType } from '@cp-es/common';
import { useCmsContent } from '@cp-shared-9/frontend-integration';
import { HeroImage, StaticPageLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';

import { fetchLegalNotice } from './LegalNoticeSlice';

export type LegalNoticeViewProps = {
    legalNotice: LegalNoticeType;
};

const LegalNoticeView: React.FC<LegalNoticeViewProps> = ({ legalNotice }) => {
    const { teaser, legalNoticeContent } = legalNotice;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    const legalNoticeContentComponent = legalNoticeContent ? (
        <ContentSection pageWrapSize="medium">
            <div dangerouslySetInnerHTML={{ __html: legalNoticeContent }} />
        </ContentSection>
    ) : null;

    return (
        <>
            {teaserComponent}
            {legalNoticeContentComponent}
        </>
    );
};

const LegalNoticeWithHandlers = withLoadingAndNoConnectionHandler(LegalNoticeView);

export const LegalNotice: React.FC = () => {
    const { cmsContent: legalNotice, isLoading, loadingError } = useCmsContent(fetchLegalNotice, (state: RootState) => {
        return state.content.legalNotice;
    });

    if (!legalNotice) {
        return null;
    }

    return (
        <LegalNoticeWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            legalNotice={legalNotice}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
