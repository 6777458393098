import React, { useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
    ValidatedSelect,
    withAutoScroll,
} from '@cp-shared-9/frontend-ui';
import {
    Address,
    ChangeAddressData,
    ComplementaryInformation,
    PersonalDetailsEditStatus,
    PersonalDetailsUpdateError,
    Street,
} from '@cp-es/common';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../cp-xhr';
import {
    Button,
    ButtonContainer,
    ContentSection,
    DataOverview,
    Fieldset,
    Form,
    FormField,
    Input,
    Layout,
    Modal,
} from '@vwfs-bronson/bronson-react';
import { getRoadType, roadTypeItems } from './road-type-mapping';
import { useAddressValidationSchema } from './useValidation';
import { trimAllValues } from '@cp-shared-9/common-utilities';

export type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: PersonalDetailsEditStatus, updatedAddress?: Address) => void;
    address: Address;
    lastEditStatus: PersonalDetailsEditStatus;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ cancelEditing, finishEditing, address, lastEditStatus }) => {
        const { t } = useTranslation('my-profile-address-details');
        const validationSchema = useAddressValidationSchema();

        const [showConfirmationModal, setShowConfirmationModal] = useState(false);
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [lastEnteredValues, setLastEnteredValues] = useState<ChangeAddressData>();

        const trackingSection = 'Address';
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', lastEditStatus === 'NOT_PERFORMED', trackingSection);
        const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileAddressValidationError');
        const { onTyping, onError, onSuccess } = useAnalyticsFormTracker({
            startTyping: 'onEditProfileAddressTypedIn',
            confirmError: 'onEditProfileAddressSubmitFailed',
            confirmSuccess: 'onEditProfileAddressSuccess',
        });
        const { onAction: onShowConfirmationView } = useAnalyticsActionTracker('onEditProfileConfirmation');
        const { onAction: onCancelConfirmation } = useAnalyticsActionTracker('onEditProfileConfirmationCancel');

        const getErrors = (errors: FormikErrors<ChangeAddressData>): string => {
            const streetErrors = errors.street && Object.keys(errors.street).join(`, `);
            const cityErrors = errors.city && Object.keys(errors.city).join(`, `);
            return [streetErrors, cityErrors].filter(element => !!element).join(', ');
        };

        const openConfirmationModal = (): void => setShowConfirmationModal(true);
        const closeConfirmationModal = (cancel = true): void => {
            if (cancel) {
                onCancelConfirmation(trackingSection);
            }
            setShowConfirmationModal(false);
        };

        const streetInitialValues: Street = {
            roadType: lastEnteredValues?.street.roadType ?? getRoadType(address?.roadType),
            name: lastEnteredValues?.street.name ?? address.name ?? '',
            number: lastEnteredValues?.street.number ?? address.number ?? '',
        };
        const complimentaryInformationInitialValues: ComplementaryInformation = {
            block: lastEnteredValues?.complementaryInformation?.block ?? address.block ?? '',
            stairs: lastEnteredValues?.complementaryInformation?.stairs ?? address.stairs ?? '',
            door: lastEnteredValues?.complementaryInformation?.door ?? address.door ?? '',
            floor: lastEnteredValues?.complementaryInformation?.floor ?? address.floor ?? '',
            details: lastEnteredValues?.complementaryInformation?.details ?? address.details ?? '',
        };
        const cityInitialValue = {
            zip: lastEnteredValues?.city.zip ?? address.zip ?? '',
            locality: lastEnteredValues?.city.locality ?? address.locality ?? '',
        };
        const initialValues: ChangeAddressData = {
            street: streetInitialValues,
            complementaryInformation: complimentaryInformationInitialValues,
            city: cityInitialValue,
        };

        const onSubmit = (values: ChangeAddressData): void => {
            if (!address._links?.changeLink) {
                return;
            }
            const addressChangeValues = trimAllValues<ChangeAddressData>(values);
            setIsSubmitting(true);
            setLastEnteredValues(addressChangeValues);
            const updatedAddress = {
                ...address,
                ...addressChangeValues.city,
                ...addressChangeValues.complementaryInformation,
                ...addressChangeValues.street,
            };
            CpDataApi.put(address._links?.changeLink, addressChangeValues)
                .then(response => {
                    onSuccess();
                    setIsSubmitting(false);
                    response.status === 202
                        ? finishEditing('SUCCESS_PENDING', updatedAddress)
                        : finishEditing('SUCCESS', updatedAddress);
                })
                .catch(error => {
                    onError();
                    setIsSubmitting(false);
                    switch (error.response?.data?.code as PersonalDetailsUpdateError) {
                        case 'CHANGE_ERROR':
                        case 'PARTIAL_CHANGE_ERROR':
                            finishEditing(error.response.data?.code);
                            break;
                        default:
                            finishEditing('UNEXPECTED_ERROR');
                            break;
                    }
                });
        };

        return (
            <DataOverview title={t('edit-view.title')}>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Notification status={NotificationStatus.warning} text={t('edit-view.warning-info')} />
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {(formik): JSX.Element => (
                            <>
                                <Form
                                    onSubmit={e => preventSubmit(e)}
                                    data-testid="editForm"
                                    onChange={(): void => onTyping(formik.errors, formik.touched)}
                                >
                                    <ContentSection className={'address-edit-form'}>
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default="1/3" s="1/1">
                                                        <ValidatedSelect
                                                            label={t('edit-view.street.road-type')}
                                                            name="street.roadType"
                                                            testId="roadType"
                                                            selectItems={roadTypeItems}
                                                            disablePlaceholder={true}
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/3" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.street.name.label')}
                                                            tooltip={t('edit-view.street.name.tooltip')}
                                                            name="street.name"
                                                            testId="name"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/3" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.street.number.label')}
                                                            tooltip={t('edit-view.street.number.tooltip')}
                                                            name="street.number"
                                                            testId="number"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.complementary-information.block')}
                                                            name="complementaryInformation.block"
                                                            testId="block"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.complementary-information.stairs')}
                                                            name="complementaryInformation.stairs"
                                                            testId="stairs"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.complementary-information.floor')}
                                                            name="complementaryInformation.floor"
                                                            testId="floor"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.complementary-information.door')}
                                                            name="complementaryInformation.door"
                                                            testId="door"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default="1/1" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.complementary-information.details')}
                                                            name="complementaryInformation.details"
                                                            testId="details"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.city.zip.label')}
                                                            tooltip={t('edit-view.city.zip.tooltip')}
                                                            name="city.zip"
                                                            testId="zip"
                                                            type="text"
                                                            inputMode="numeric"
                                                            autocomplete="postal-code"
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <ValidatedInput
                                                            label={t('edit-view.city.locality.label')}
                                                            tooltip={t('edit-view.city.locality.tooltip')}
                                                            name="city.locality"
                                                            testId="locality"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <FormField
                                                            type="input"
                                                            id="province"
                                                            testId="province"
                                                            labelText={t('edit-view.city.province')}
                                                        >
                                                            <Input
                                                                type="text"
                                                                id="province-text"
                                                                testId="province-text"
                                                                placeholder={address.province}
                                                                disabled
                                                            />
                                                        </FormField>
                                                    </Layout.Item>
                                                    <Layout.Item default="1/4" s="1/1">
                                                        <FormField
                                                            type="input"
                                                            id="country"
                                                            testId="country"
                                                            labelText={t('edit-view.city.country')}
                                                        >
                                                            <Input
                                                                type="text"
                                                                id="country-text"
                                                                testId="country-text"
                                                                placeholder={address.country}
                                                                disabled
                                                            />
                                                        </FormField>
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                        </Fieldset>
                                    </ContentSection>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ButtonContainer nav>
                                                <Button
                                                    secondary
                                                    onClick={cancelEditing}
                                                    testId="cancelButton"
                                                    type="button"
                                                >
                                                    {t('translation:editableSectionNav.cancel')}
                                                </Button>
                                                <Button
                                                    onClick={(): Promise<void> =>
                                                        formik.validateForm().then(validationErrors => {
                                                            if (Object.keys(validationErrors).length === 0) {
                                                                openConfirmationModal();
                                                                onShowConfirmationView(trackingSection);
                                                            } else {
                                                                onValidationError(getErrors(validationErrors));
                                                            }
                                                        })
                                                    }
                                                    testId="submitButton"
                                                    type="button"
                                                >
                                                    {t('edit-view.confirm-changes.label')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                                <Modal
                                    shown={showConfirmationModal}
                                    buttonConfirmText={t('translation:editableSectionNav.confirm')}
                                    buttonCancelText={t('translation:editableSectionNav.cancel')}
                                    onConfirm={(): void => {
                                        formik.handleSubmit();
                                        closeConfirmationModal(false);
                                    }}
                                    onCancel={() => closeConfirmationModal()}
                                    onClickOutside={() => closeConfirmationModal()}
                                    onClose={closeConfirmationModal}
                                    testId="confirmModal"
                                >
                                    <div dangerouslySetInnerHTML={{ __html: t('edit-view.confirmation-modal.text') }} />
                                </Modal>
                            </>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </DataOverview>
        );
    },
);
