import React from 'react';
import { DataOverview, withAutoScroll } from '@cp-shared-9/frontend-ui';
import { SepaMandateForm } from './sepa-mandate-form';
import { useTranslation } from 'react-i18next';

export type SepaMandateViewProps = {
    toNextView: () => void;
    sepaMandateDownloadEndpoint: string;
};

export const SepaMandateView: React.FC<SepaMandateViewProps> = withAutoScroll(
    ({ toNextView, sepaMandateDownloadEndpoint }) => {
        const { t } = useTranslation('financial-details');

        return (
            <DataOverview title={t('ibanSection.editView.title')}>
                <SepaMandateForm onSubmit={toNextView} sepaMandateDownloadEndpoint={sepaMandateDownloadEndpoint} />
            </DataOverview>
        );
    },
    'SepaMandateView',
);
