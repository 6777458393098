import React from 'react';
import { useContract } from 'components/contracts/useContract';
import { useParams } from 'react-router-dom';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { EarlySettlementLeasingUi } from './ui/EarlySettlementLeasingUi';
import { usePersonalDetails } from 'components/my-profile/usePersonalDetails';
import { fetchVehicleDetails, VehicleDetailsDataSelector } from 'components/contracts/vehicle-details';
import { useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { useFinancialDetails } from '../contracts/financial-details/useFinancialDetails';
import { EarlySettlementLoadingPlaceholder } from '@cp-shared-9/frontend-ui';

const EarlySettlementLeasingUiWithHandlers = withLoadingAndNoConnectionHandler(EarlySettlementLeasingUi);
interface RouteParams {
    contractId: string;
}

export const EarlySettlementLeasing: React.FC = () => {
    const { contractId: encryptedContractId } = useParams<RouteParams>();

    const {
        data: personalDetails,
        isLoading: isLoadingProfile,
        loadingError: loadingErrorPersonalDetails,
    } = usePersonalDetails();

    const { data: contract, isLoading: isLoadingContracts, loadingError: loadingErrorContract } = useContract(
        encryptedContractId,
        {
            encryptedContractId: true,
        },
    );

    const {
        data: financialDetails,
        isLoading: isLoadingFinancialDetails,
        loadingError: loadingErrorFinancialDetails,
    } = useFinancialDetails(contract?.contractNumber ?? '');

    const {
        data: vehicleDetails,
        isLoading: isLoadingVehicleDetails,
        loadingError: loadingErrorVehicleDetails,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchVehicleDetails,
        VehicleDetailsDataSelector,
        contract?._links?.vehicleDetails,
    );

    return (
        <EarlySettlementLeasingUiWithHandlers
            vehicleDetails={vehicleDetails}
            contract={contract}
            email={personalDetails?.contact?.email}
            financialDetails={financialDetails}
            isLoading={isLoadingContracts || isLoadingProfile || isLoadingVehicleDetails || isLoadingFinancialDetails}
            hasError={
                !encryptedContractId ||
                !!loadingErrorPersonalDetails ||
                !!loadingErrorContract ||
                !!loadingErrorVehicleDetails ||
                !!loadingErrorFinancialDetails
            }
            loadingPlaceholder={<EarlySettlementLoadingPlaceholder />}
        />
    );
};
