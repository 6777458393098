import { Installment } from '@cp-es/common';
import {
    createDynamicTable,
    DynamicTableFormatOptions,
    DynamicTableGroupByOptions,
    DynamicTableSortOptions,
    TableHeaderEntry,
} from '@cp-shared-9/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { formatCpDate } from '@cp-shared-9/common-utilities';

export type AmortizationTableInstallmentOverviewProps = {
    amortizationDetails: Installment[];
    forFinanceLeaseContract?: boolean;
};

const DynamicTable = createDynamicTable<Installment>();

export const AmortizationTableInstallmentOverview: React.FC<AmortizationTableInstallmentOverviewProps> = ({
    amortizationDetails,
    forFinanceLeaseContract,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-table');
    const columnHeadings: TableHeaderEntry[] = [
        t('table.columns.payment-id.label'),
        {
            heading: t('table.columns.due-date.label'),
            tooltip: t('table.columns.due-date.tooltip'),
        },
        {
            heading: t('table.columns.total-monthly-amount.label'),
            tooltip: t('table.columns.total-monthly-amount.tooltip'),
        },
        { heading: t('table.columns.amortized-capital.label'), tooltip: t('table.columns.amortized-capital.tooltip') },
        t('table.columns.interest.label'),
        {
            heading: t('table.columns.outstanding-capital.label'),
            tooltip: t('table.columns.outstanding-capital.tooltip'),
        },
    ];
    if (forFinanceLeaseContract) {
        columnHeadings.push(t('table.columns.expenses.label'), t('table.columns.tax.label'));
    }

    const groupByOptions: DynamicTableGroupByOptions<Installment> = {
        groupBy: (row: Installment) =>
            formatCpDate(row.dueDate)
                .toMoment()
                .year()
                .toString(),
    };

    const sortOptions: DynamicTableSortOptions<Installment> = {
        compare: (row1: Installment, row2: Installment) =>
            row1.id && row2.id ? parseInt(row1.id) - parseInt(row2.id) : 0,
        reversed: true,
    };

    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: false,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    return (
        <DynamicTable
            rows={[...amortizationDetails]}
            columnHeadings={columnHeadings}
            toColumnValues={(row: Installment): string[] => {
                const fallbackValue = '-';
                const rowValues = [
                    row.id || fallbackValue,
                    f(row.dueDate, TranslationFormat.DATE) || fallbackValue,
                    f(row.totalAmount, TranslationFormat.CURRENCY) || fallbackValue,
                    f(row.amortizedCapital, TranslationFormat.CURRENCY) || fallbackValue,
                    f(row.interest, TranslationFormat.CURRENCY) || fallbackValue,
                    f(row.pendingCapital, TranslationFormat.CURRENCY) || fallbackValue,
                ];
                if (forFinanceLeaseContract) {
                    rowValues.push(
                        f(row.expenses, TranslationFormat.CURRENCY) || fallbackValue,
                        f(row.tax, TranslationFormat.CURRENCY) || fallbackValue,
                    );
                }
                return rowValues;
            }}
            groupByOptions={groupByOptions}
            sortOptions={sortOptions}
            formatOptions={formatOptions}
        />
    );
};
