import React from 'react';
import { Address, PersonalDetailsEditStatus } from '@cp-es/common';
import { useTranslation } from 'react-i18next';
import { ValueOrDefaultError } from '../../../value-or-default-error';
import { joinDefinedValues } from '@cp-shared-9/common-utilities';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { NotificationForLastEditStatus } from '../../contact-section/consult-view/NotificationForLastEditStatus';

export type ConsultViewProps = {
    address: Address;
    startEditing: () => void;
    lastEditStatus: PersonalDetailsEditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ address, startEditing, lastEditStatus }) => {
    const { t } = useTranslation('my-profile');
    const isChangeAllowed = !!address._links?.changeLink;

    const street = (): React.ReactNode => {
        if (!address.name || !address.roadType) {
            return <ValueOrDefaultError />;
        } else {
            const addressBlock = (): string | undefined => (address.block ? `/${address.block}` : undefined);

            const street1 = joinDefinedValues(' ', address.roadType, address.name, address.number);

            const street2 = joinDefinedValues('/', addressBlock(), address.stairs);
            const street3 = joinDefinedValues(' ', address.floor, address.door);

            const street = joinDefinedValues(' ', street1, street2, street3);

            return <ValueOrDefaultError value={street} />;
        }
    };

    const locality = (): React.ReactNode => {
        if (!address.zip || !address.country) {
            return <ValueOrDefaultError />;
        }

        const zipAndLocality = joinDefinedValues(' ', address.zip, address.locality);
        const zipLocalityAndCountry = joinDefinedValues(', ', zipAndLocality, address.country);

        return <ValueOrDefaultError value={zipLocalityAndCountry} />;
    };

    const identificationList: DefinitionListItem[] = [
        {
            label: t('address.items.label.street'),
            value: street(),
        },
        {
            label: '',
            value: address.details,
        },
        {
            label: t('address.items.label.locality'),
            value: locality(),
        },
    ].filter(item => !!item.value);

    const handleStartEditing = () => {
        startEditing();
    };

    return (
        <DataOverview
            title={t('address.title')}
            buttonLabel={isChangeAllowed ? t('translation:editableSectionNav.start') : undefined}
            buttonProps={isChangeAllowed ? { onClick: handleStartEditing, testId: 'editButton' } : undefined}
        >
            <DefinitionListHorizontal list={identificationList} />
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
        </DataOverview>
    );
};
