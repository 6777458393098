import { ContractType } from './contract-types';

export interface MyRequest {
    email: string;
    category: string;
    contractInfo?: ContractInfo;
    shortDescription: string;
    request: string;
}

export interface ContractInfo {
    licensePlate?: string;
    contractId?: string;
    contractType: ContractType;
}

export enum OpenRequestCategoriesEN {
    INVOICES = 'INVOICES',
    INSURANCE = 'INSURANCE',
    CHANGE_CONTACT_DETAILS = 'CHANGE OF CONTACT DETAILS',
    CONTRACT_INFORMATION = 'CONTRACT INFORMATION',
    UNPAID_BALANCE = 'UNPAID BALANCE',
    REPLACEMENT_VEHICLE = 'REPLACEMENT VEHICLE',
    TRAFFIC_FINES = 'TRAFFIC FINES MANAGEMENT',
    PARKING_CARD = 'REGULATED PARKING CARD',
    FOREIGN_COUNTRY_NOC = 'DOCUMENTATION TO CIRCULATE IN A FOREIGN COUNTRY',
    LOW_EMISSION = 'LOW-EMISSION DISCS',
    SEPA_MANDATE = 'SEPA MANDATE',
    OTHER_ISSUES_OPERATIVE_LEASING = 'OTHER ISSUES RENTING',
    RECALCULATION = 'RECALCULATION',
    EARLY_SETTLEMENT_LEASING = 'EARLY SETTLEMENT LEASING',
}

export enum OpenRequestRentingCategoriesSalesforce {
    INVOICES = 'VWR-CONSULTA SOBRE FACTURAS',
    INSURANCE = 'VWR-SEGUROS',
    CHANGE_CONTACT_DETAILS = 'VWR-MODIF. DOMICILIO/AC10',
    CONTRACT_INFORMATION = 'VWR-INFO CONDICIONES CONTRATO',
    UNPAID_BALANCE = 'VWR-IMPAGADOS',
    REPLACEMENT_VEHICLE = 'VWR-VEH. SUST. ITV',
    TRAFFIC_FINES = 'VWR-GESTION DE MULTAS',
    PARKING_CARD = 'PARKING-VWR-SOLICITUD CERTIFICADOS',
    FOREIGN_COUNTRY_NOC = 'FOREIGN-COUNTRY-VWR-SOLICITUD CERTIFICADOS',
    LOW_EMISSION = 'LOW-EMMISION-VWR-SOLICITUD CERTIFICADOS',
    SEPA_MANDATE = 'VWR-MODIF. DATOS BANCARIOS/AC09',
    OTHER_ISSUES_OPERATIVE_LEASING = 'FINANCE-OTROS',
    RECALCULATION = 'VWGC-Recalculo',
    EARLY_SETTLEMENT_LEASING = 'Cancelación anticipada',
}

export enum OpenRequestFinancingCategoriesSalesforce {
    TITLE_RETENTION = 'FINANCE-SOLICITUD-LEVANT RD',
    EARLY_SETTLEMENT = 'FINANCE-SOLICITUD CANC. ANTICIPADA (LPA, LPV, LPP, LPI, LPO)',
    CHANGE_CONTACT_DETAILS = 'LDS-MODIFICACION DATOS PERSONALES',
    UNPAID_BALANCE = 'FINANCE-IMPAGADOS/AC07',
    CONTRACT_INFORMATION = 'FINANCE- INFO CONTRATO',
    SEPA_MANDATE = 'FINANCE-MODIF. DATOS BANCARIOS/AC09',
    OTHER_ISSUES_FINANCING = 'FINANCE-OTROS',
}

export enum OpenRequestGeneralCategoriesSalesforce {
    OTHER_ISSUES_OPERATIVE_LEASING = 'LEASING-FINANCE-OTROS',
    OTHER_ISSUES_FINANCING = 'FINANCING-FINANCE-OTROS',
}

export type OpenRequest =
    | OpenRequestRentingCategoriesSalesforce
    | OpenRequestGeneralCategoriesSalesforce
    | OpenRequestFinancingCategoriesSalesforce;
export type RequestName = string;
export type SelectItemLabelValueCategories = (RequestName | OpenRequest)[];
