import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatAsCurrency, formatAsDate, undefinedReturnValueHyphen } from '@cp-es/common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-9/frontend-ui';
import { CPDate } from '@cp-shared-9/common-utilities';

export type OperatingLeaseContractHeaderSummaryProps = {
    vinCode?: string;
    nextInstalmentAmount?: number;
    contractEndDate?: CPDate;
};

export const OperatingLeaseContractHeaderSummary: React.FC<OperatingLeaseContractHeaderSummaryProps> = ({
    vinCode,
    nextInstalmentAmount,
    contractEndDate,
}) => {
    const { t } = useTranslation('contracts');
    const items: Array<ContractSummaryItemProps> = [
        {
            label: t('vinCode'),
            value: vinCode ? vinCode : '-',
            testClass: 'vin-code',
        },
        {
            label: t('nextInstalmentAmount.label'),
            value: formatAsCurrency(nextInstalmentAmount, undefinedReturnValueHyphen),
            testClass: 'next-instalment-amount',
            tooltip: t('nextInstalmentAmount.tooltip'),
        },
        {
            label: t('contractEndDate'),
            value: contractEndDate ? formatAsDate(contractEndDate) : '-',
            testClass: 'contract-end-date',
        },
    ];

    return <ContractSummary items={items} />;
};
