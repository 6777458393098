import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Hr } from '@vwfs-bronson/bronson-react';
import { OperatingLeaseFinancialDetails as OperatingLeaseFinancialDetailsBo } from '@cp-es/common';
import { useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { AccordionItem } from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FinancialDetailsProps } from '../FinancialDetailsProps';
import { BaseSection } from './base-section/BaseSection';
import { IbanSection } from './iban-section/IbanSection';
import { FinancialDetailsDataSelector } from '../FinancialDetailsDataSelector';
import { fetchFinancialDetails } from '../FinancialDetailsSlice';
import { useUpdateFinancialDetailsPerContract as useUpdateFinancialDetails } from '../useUpdateFinancialDetails';

export const operatingLeaseEmployeeContractNumberPrefixes = ['01RN60', '01RN66', '01RN92', '01RN93'];

const OperatingLeaseFinancialDetailsUi: React.FC<{
    contractId: string;
    encryptedContractId: string;
    details?: OperatingLeaseFinancialDetailsBo;
}> = ({ contractId, details, encryptedContractId }) => {
    if (!details) {
        return null;
    }

    return (
        <>
            <BaseSection details={details} />
            <Hr className="u-mb-none" />
            <IbanSection encryptedContractId={encryptedContractId} contractId={contractId} details={details} />
        </>
    );
};
const OperatingLeaseFinancialDetailsUiWithWrappers = withLoadingAndNoConnectionHandler(
    OperatingLeaseFinancialDetailsUi,
);

export const OperatingLeaseFinancialDetails: React.FC<FinancialDetailsProps> = ({
    contractId,
    link,
    encryptedContractId,
}) => {
    const { t } = useTranslation('contracts');

    const { data: details, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        link,
    );

    const { updateFinancialDetails, financialDetailsData } = useUpdateFinancialDetails(contractId);

    const resetLastEditStatus = (): void => {
        if (financialDetailsData)
            updateFinancialDetails({
                ...financialDetailsData,
                lastEditStatus: {
                    ...financialDetailsData.lastEditStatus,
                    iban: 'NOT_PERFORMED',
                },
            });
    };

    useEffect(() => {
        resetLastEditStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AccordionItem title={t('financialDetails.headline')}>
            <div>
                <OperatingLeaseFinancialDetailsUiWithWrappers
                    isLoading={isLoading}
                    hasError={!!loadingError}
                    contractId={contractId}
                    encryptedContractId={encryptedContractId}
                    details={details}
                />
            </div>
        </AccordionItem>
    );
};
