import React from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';

import { useTranslation } from 'react-i18next';
import { WithDefaultCpIntegrationErrors, WithDefaultMarketApiErrors } from '@cp-shared-9/common-utilities';
import { RequestCertificateError } from '@cp-es/common';
import { myProfilePagePath } from '../../../navigation/paths';

export type OfflinePaymentConfirmationModalProps = {
    shown: boolean;
    receivedEmail?: string;
    errorStatus?: WithDefaultMarketApiErrors<WithDefaultCpIntegrationErrors<RequestCertificateError>>;
    closeConfirmationModal: Function;
};

export const OfflinePaymentConfirmationModal: React.FC<OfflinePaymentConfirmationModalProps> = ({
    shown,
    receivedEmail,
    errorStatus,
    closeConfirmationModal,
}) => {
    const { t } = useTranslation('total-early-settlement');
    let title, text, buttonConfirmText: string;

    if (receivedEmail) {
        title = t('offline-payment.confirmation-modal.success.headline');
        text = t('offline-payment.confirmation-modal.success.text', {
            email: receivedEmail,
        });
        buttonConfirmText = t('offline-payment.confirmation-modal.success.confirm');
    } else {
        switch (errorStatus) {
            case 'EMAIL_IS_EMPTY': {
                title = t('offline-payment.confirmation-modal.error.invalid-email.headline');
                text = t('offline-payment.confirmation-modal.error.invalid-email.text', {
                    profilePath: myProfilePagePath(),
                });
                break;
            }
            case 'VALIDATION_ERROR': {
                title = t('offline-payment.confirmation-modal.error.unexpected-error.headline');
                text = t('offline-payment.confirmation-modal.error.unexpected-error.text');
                break;
            }
            default: {
                title = t('offline-payment.confirmation-modal.error.backend-not-available.headline');
                text = t('offline-payment.confirmation-modal.error.backend-not-available.text');
                break;
            }
        }
        buttonConfirmText = t('offline-payment.confirmation-modal.error.confirm');
    }

    return (
        <Modal
            shown={shown}
            buttonConfirmText={buttonConfirmText}
            onConfirm={() => closeConfirmationModal()}
            onClose={() => closeConfirmationModal()}
            testId="confirmModal"
            title={title}
        >
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </Modal>
    );
};
