import React from 'react';

import { NotificationStatus, Notification } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ContractType, EncryptedContractNumber, FinanceContract, OperatingLeaseContract } from '@cp-es/common';
import DigitalRenewalContentTransformComponent from 'components/digital-renewal/DigitalRenewalContentTransformComponent';
import { unpaidDetailsPath } from '../../../navigation/paths';

export type DigitalRenewalWarningNotificationProps = {
    testId?: string;
    contract: (FinanceContract & EncryptedContractNumber) | (OperatingLeaseContract & EncryptedContractNumber);
    handleButtonClick?: () => void;
};

export const WarningNotification: React.FC<DigitalRenewalWarningNotificationProps> = ({
    testId,
    contract,
    handleButtonClick,
}) => {
    const { t } = useTranslation('digital-renewal');
    const headline = t('finance.notification.warning-notification.headline');

    const { _encryptedContractNumber, contractType } = contract;
    const contractTypeContent = contractType === ContractType.FINANCING ? 'finance' : 'leasing';
    const content = t(`${contractTypeContent}.notification.warning-notification.content`, {
        linkToUnpaidDetails: unpaidDetailsPath(_encryptedContractNumber),
    });

    return (
        <Notification
            status={NotificationStatus.warning}
            headline={headline}
            testId={testId}
            className={'c-notification--context'}
            showCloseButton={true}
            onCloseClick={handleButtonClick}
        >
            <DigitalRenewalContentTransformComponent content={content} />
        </Notification>
    );
};
