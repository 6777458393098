import { ContentSection } from '@vwfs-bronson/bronson-react';
import { AmortizationTable } from 'components/amortization-table/AmortizationTable';
import { PageHeader } from 'components/page-header/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface RouteParams {
    contractId: string;
}

export const AmortizationTablePage: React.FC = () => {
    const { t } = useTranslation('amortization-table');
    const { contractId: encryptedContractId } = useParams<RouteParams>();

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <AmortizationTable encryptedContractId={encryptedContractId || 'no-contract'} />
        </ContentSection>
    );
};
