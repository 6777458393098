import { CPDate } from '@cp-shared-9/common-utilities';

export type RequestCertificateType = 'CONTRACT_STATUS' | 'OUTSTANDING_DEBT_CONTRACT';
export const REQUEST_CERTIFICATE_TYPES: RequestCertificateType[] = ['CONTRACT_STATUS', 'OUTSTANDING_DEBT_CONTRACT'];

export interface RequestCertificateGeneralData {
    certificateType: RequestCertificateType;
}

export interface ContractStatusData extends RequestCertificateGeneralData {
    certificateType: 'CONTRACT_STATUS';
}

export interface OutstandingDebtContractData extends RequestCertificateGeneralData {
    certificateType: 'OUTSTANDING_DEBT_CONTRACT';
    requestDate: CPDate;
}

export type RequestCertificateData = ContractStatusData | OutstandingDebtContractData;

export interface RequestCertificateResponse {
    email: string;
}

export type RequestCertificateError = 'EMAIL_IS_EMPTY' | 'VALIDATION_ERROR';
