import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { LandingPageMarketingCardDto } from '@cp-es/common';
import { useCmsContent } from '@cp-shared-9/frontend-integration';
import {
    MediaTeaser as MediaTeaserShared,
    openInNewWindow,
    useAnalyticsActionTracker,
    MarketingCardLoadingPlaceholder,
} from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';
import { fetchLandingPageMarketingCards } from './LandingPageMarketingCardsSlice';

const MarketingCards: React.FC<{ cards: Array<LandingPageMarketingCardDto> | undefined }> = ({ cards }) => {
    const { onAction } = useAnalyticsActionTracker('onClickMarketingCard');
    if (!cards) {
        return null;
    }

    return (
        <ContentSection>
            {cards.map((card, index) => {
                const cardTitle = card?.title || '';
                return (
                    <PageWrap size={'medium'} key={index}>
                        <MediaTeaserShared
                            title={cardTitle}
                            imageUrl={card?.imageUrl || ''}
                            pretitle={card?.pretitle}
                            text={card?.text}
                            buttonLabel={card?.buttonLabel}
                            clickHandler={e => {
                                onAction(cardTitle);
                                openInNewWindow(card?.buttonUrl)(e);
                            }}
                            reversed={index % 2 === 1}
                        />
                    </PageWrap>
                );
            })}
        </ContentSection>
    );
};

const MarketingCardsWithWrappers = withLoadingAndNoConnectionHandler(MarketingCards);

export const LandingPageMarketingCards: React.FC = () => {
    const { cmsContent: landingPageMarketingCards, isLoading, loadingError } = useCmsContent(
        fetchLandingPageMarketingCards,
        (state: RootState) => {
            return state.content.landingPageMarketingCards;
        },
    );

    return (
        <MarketingCardsWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            cards={landingPageMarketingCards}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
