import { FinanceContract as FinanceContractType, FinanceFinancialDetails, Installment } from '@cp-es/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import { Card, DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from 'components/contracts/ContractHeader';
import { ValueOrDefaultError } from 'components/value-or-default-error';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { formatCpDate } from '@cp-shared-9/common-utilities';

export type AmortizationTableHeaderProps = {
    contract: FinanceContractType;
    amortizationDetails: Installment[];
    financialDetails: FinanceFinancialDetails;
};

export const AmortizationTableHeader: React.FC<AmortizationTableHeaderProps> = ({
    contract,
    amortizationDetails,
    financialDetails,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-table');

    const now = formatCpDate().toMoment();
    const totalInstallments = amortizationDetails.length;
    const pendingInstallments = amortizationDetails.filter(
        installment =>
            formatCpDate(installment.dueDate)
                .toMoment()
                .diff(now) > 0,
    ).length;

    const nominalAmount = financialDetails.nominalAmount;
    const pendingCapital = financialDetails.pendingCapital;
    const startDate = financialDetails.startDate;
    const endDate = financialDetails.endDate;

    const tiles = [
        {
            title: t('tiles.installment.title'),
            icon: 'semantic-list',
            data: [
                {
                    label: t('tiles.installment.totalInstallments'),
                    // TODO: remove? in no case totalInstallments is false
                    value: <ValueOrDefaultError value={f(totalInstallments, TranslationFormat.NUMBER)} />,
                },
                {
                    label: t('tiles.installment.pendingInstallments'),
                    // TODO: remove? in no case pendingInstallments is false
                    value: <ValueOrDefaultError value={f(pendingInstallments, TranslationFormat.NUMBER)} />,
                },
            ],
        },
        {
            title: t('tiles.finance.title'),
            icon: 'semantic-accessoires',
            data: [
                {
                    label: t('tiles.finance.nominalAmount'),
                    value: f(nominalAmount, TranslationFormat.CURRENCY),
                },
                {
                    label: t('tiles.finance.pendingCapital'),
                    value: f(pendingCapital, TranslationFormat.CURRENCY),
                },
            ],
        },
        {
            title: t('tiles.timeline.title'),
            icon: 'semantic-calendar',
            data: [
                {
                    label: t('tiles.timeline.beginDate'),
                    value: f(startDate, TranslationFormat.DATE),
                },
                {
                    label: t('tiles.timeline.endDate'),
                    value: f(endDate, TranslationFormat.DATE),
                },
            ],
        },
    ];

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader contract={contract} />
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Layout className={'o-layout--equal-height'}>
                    {tiles.map((tile, index) => (
                        <Layout.Item key={index} default="1/3" m="1/1">
                            <DataOverview title={tile.title} icon={tile.icon}>
                                <Layout.Item default="1/1">
                                    <DefinitionListHorizontal list={tile.data as DefinitionListItem[]} />
                                </Layout.Item>
                            </DataOverview>
                        </Layout.Item>
                    ))}
                </Layout>
            </Layout.Item>
        </Layout>
    );
};
