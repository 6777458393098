import { OpenRequestFinancingCategoriesSalesforce, SelectItemLabelValueCategories } from '@cp-es/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-9/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const financingCategories: SelectItemLabelValueCategories[] = [
    ['title-retention', OpenRequestFinancingCategoriesSalesforce.TITLE_RETENTION],
    ['early-settlement', OpenRequestFinancingCategoriesSalesforce.EARLY_SETTLEMENT],
    ['change-contact-details', OpenRequestFinancingCategoriesSalesforce.CHANGE_CONTACT_DETAILS],
    ['unpaid-balance', OpenRequestFinancingCategoriesSalesforce.UNPAID_BALANCE],
    ['contract-information', OpenRequestFinancingCategoriesSalesforce.CONTRACT_INFORMATION],
    ['sepa-mandate', OpenRequestFinancingCategoriesSalesforce.SEPA_MANDATE],
    ['other-issues-financing', OpenRequestFinancingCategoriesSalesforce.OTHER_ISSUES_FINANCING],
];

export const FinancingDropdown: React.FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation('my-request');

    const validatedSelectItems: ValidatedSelectItem[] = financingCategories.map(category => {
        const categoryEntryText = t(`form.financing-categories.${category[0]}`);
        return {
            label: categoryEntryText,
            value: category[1],
        };
    });

    return (
        <>
            <ValidatedSelect
                label={t('form.category.headline')}
                name={name}
                selectItems={validatedSelectItems}
                testId={`finance-${name}`}
                placeholder={t('form.category.placeholder')}
                disablePlaceholder={true}
            />
        </>
    );
};
