import { Contract } from '@cp-es/common';
import { Button, ButtonContainer, Heading, TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import {
    corporateNameChangingSubragationPagePath,
    dashboardPagePath,
    legalPersonSubragationPagePath,
    privatePersonSubragationPagePath,
} from 'components/navigation/paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

type ChooseSubrogationTypeProps = {
    contract?: Contract;
};

export enum Options {
    NATURAL_PERSON = 'NATURAL_PERSON',
    LEGAL_PERSON = 'LEGAL_PERSON',
    CORPORATE = 'CORPORATE',
}

export const ChooseSubrogationType: React.FC<ChooseSubrogationTypeProps> = ({ contract }) => {
    const [chosenOption, setChosenOption] = useState<Options>();
    const { t } = useTranslation('request-subrogation');
    const history = useHistory();
    const { onAction: trackOnRequestSubrogationActionEntryPointClick } = useAnalyticsActionTracker(
        'onRequestSubrogationActionEntryPointClick',
    );

    if (!contract) return null;

    const onSubmit = (): void => {
        switch (chosenOption) {
            case Options.NATURAL_PERSON:
                trackOnRequestSubrogationActionEntryPointClick('Subrogation Natural person');
                history.push(privatePersonSubragationPagePath(contract._encryptedContractNumber));
                break;
            case Options.LEGAL_PERSON:
                trackOnRequestSubrogationActionEntryPointClick('Subrogation Legal person');
                history.push(legalPersonSubragationPagePath(contract._encryptedContractNumber));
                break;
            case Options.CORPORATE:
                trackOnRequestSubrogationActionEntryPointClick('Subrogation Corporate');
                history.push(corporateNameChangingSubragationPagePath(contract._encryptedContractNumber));
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Heading level={'4'}>{t('subheading')}</Heading>
            <TileSelectGroup layoutItemClassName={'u-1/3 l-1/3 m-1/1 s-1/1'}>
                <TileSelect
                    icon={'semantic-user'}
                    title={t('tile-select.natural-person')}
                    inputType="radio"
                    radioGroupName="typeOfSubrogationRadio"
                    onChange={(): void => setChosenOption(Options.NATURAL_PERSON)}
                    value=""
                    testId={'privatePersonTileTestId'}
                />
                <TileSelect
                    icon={'semantic-user'}
                    title={t('tile-select.legal-person')}
                    inputType="radio"
                    radioGroupName="typeOfSubrogationRadio"
                    onChange={(): void => setChosenOption(Options.LEGAL_PERSON)}
                    value=""
                    testId={'legalPersonTileTestId'}
                />
                <TileSelect
                    icon={'semantic-user'}
                    title={t('tile-select.corporate-name')}
                    inputType="radio"
                    radioGroupName="typeOfSubrogationRadio"
                    onChange={(): void => setChosenOption(Options.CORPORATE)}
                    value=""
                    testId={'corporateNameTileTestId'}
                />
            </TileSelectGroup>
            <ButtonContainer center className={'u-mt-large'}>
                <Button secondary onClick={() => history.push(dashboardPagePath())} testId="backButton">
                    {t('buttons.back-button')}
                </Button>
                <Button onClick={() => onSubmit()} testId="submitButton">
                    {t('buttons.submit-button')}
                </Button>
            </ButtonContainer>
        </>
    );
};
