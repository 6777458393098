import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { PrivacyPolicy as PrivacyPolicyPageType } from '@cp-es/common';
import { useCmsContent } from '@cp-shared-9/frontend-integration';
import { HeroImage, StaticPageLoadingPlaceholder } from '@cp-shared-9/frontend-ui';
import { RootState } from '../../store/rootReducer';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';

import { fetchPrivacyPolicyPage } from './PrivacyPolicySlice';
import { PrivacyPolicyTable } from './PrivacyPolicyTable';

export type PrivacyPolicyViewProps = {
    privacyPolicy: PrivacyPolicyPageType;
};

const PrivacyPolicyView: React.FC<PrivacyPolicyViewProps> = ({ privacyPolicy }) => {
    const { teaser, content } = privacyPolicy;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    const PrivacyPolicyContentComponent = content ? (
        <ContentSection pageWrapSize="medium">
            <h3 className="u-text-center">{privacyPolicy.title}</h3>
            <PrivacyPolicyTable privacyPolicyContent={privacyPolicy} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </ContentSection>
    ) : null;

    return (
        <>
            {teaserComponent}
            {PrivacyPolicyContentComponent}
        </>
    );
};

const PolicyPrivacyWithHandlers = withLoadingAndNoConnectionHandler(PrivacyPolicyView);

export const PrivacyPolicy: React.FC = () => {
    const { cmsContent: privacyPolicyContent, isLoading, loadingError } = useCmsContent(
        fetchPrivacyPolicyPage,
        (state: RootState) => {
            return state.content.privacyPolicyPage;
        },
    );

    if (!privacyPolicyContent) {
        return null;
    }

    return (
        <>
            <PolicyPrivacyWithHandlers
                hasError={!!loadingError}
                isLoading={isLoading}
                privacyPolicy={privacyPolicyContent}
                loadingPlaceholder={<StaticPageLoadingPlaceholder />}
            />
        </>
    );
};
