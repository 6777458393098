import { Contract, ContractType } from '@cp-es/common';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { Button, ButtonContainer, Callout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CustomerHappinessIndexTypes = {
    handleButtonClick: () => void;
    contracts?: Contract[];
};

export const CustomerHappinessIndexUi: React.FC<CustomerHappinessIndexTypes> = ({ handleButtonClick, contracts }) => {
    const { t } = useTranslation('customer-happiness-index');
    const { onAction } = useAnalyticsActionTracker('chi');

    const onButtonClick = () => {
        onAction();
        handleButtonClick();
    };

    if (contracts) {
        const isFinancing = contracts.every(item => item.contractType === ContractType.FINANCING);
        const isLeasing = contracts.every(item => item.contractType === ContractType.OPERATING_LEASE);
        const consentTextTranslation = isFinancing
            ? t('consent-text-financing')
            : isLeasing
            ? t('consent-text-leasing')
            : t('consent-text-both');
        return (
            <>
                <Callout title={t('title')} className={'u-mb'}>
                    {t('text')}
                    <ButtonContainer center className={'u-mt'}>
                        <Button onClick={onButtonClick}>{t('button')}</Button>
                    </ButtonContainer>
                </Callout>
                <span
                    className={'u-font-size-fs-2'}
                    dangerouslySetInnerHTML={{
                        __html: t('legal-notice'),
                    }}
                />
                <span
                    className={'u-font-size-fs-2'}
                    dangerouslySetInnerHTML={{
                        __html: consentTextTranslation,
                    }}
                />
            </>
        );
    } else {
        return null;
    }
};
