import { AdditionalProduct } from '@cp-es/common';
import { getContractBasedDataSelector, useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import {
    AccordionItem,
    DataOverview,
    DefinitionListHorizontal,
    Notification,
    NotificationStatus,
} from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store/rootReducer';
import { fetchAdditionalProducts } from './AdditionalProductsSlice';
import { isActiveProduct, ProductStatus } from './product-status';
import { formatCpDate } from '@cp-shared-9/common-utilities';

function compareProducts(product1: AdditionalProduct, product2: AdditionalProduct): number {
    if (product1.status && product2.status && isActiveProduct(product1.status) && !isActiveProduct(product2.status)) {
        return -1;
    }
    if (product1.status && product2.status && !isActiveProduct(product1.status) && isActiveProduct(product2.status)) {
        return 1;
    }
    if (product1.endDate && product2.endDate) {
        return (
            formatCpDate(product2.endDate)
                .toMoment()
                .unix() -
            formatCpDate(product1.endDate)
                .toMoment()
                .unix()
        );
    }
    return 0;
}

const AdditionalProductsView: React.FC<{
    additionalProducts?: AdditionalProduct[];
}> = ({ additionalProducts }) => {
    const { t, f } = useTranslationWithFormatting('contracts');

    if (!additionalProducts || !Array.isArray(additionalProducts)) {
        return null;
    }

    if (additionalProducts.length === 0) {
        return <Notification status={NotificationStatus.info} text={t('additional-products.info.not-found')} />;
    }

    const sortedAdditionalProducts = [...additionalProducts].sort(compareProducts);

    return (
        <>
            {sortedAdditionalProducts.map((product, index) => (
                <DataOverview key={index} title={product.code}>
                    <DefinitionListHorizontal
                        list={[
                            {
                                label: t('additional-products.end-date'),
                                value: f(product.endDate, TranslationFormat.DATE),
                            },
                            {
                                label: t('additional-products.type'),
                                value: product.type,
                            },
                            {
                                label: t('additional-products.status'),
                                value: !!product.status ? <ProductStatus status={product.status} /> : null,
                            },
                            {
                                label: t('additional-products.supplier'),
                                value: product.supplier,
                            },
                        ].filter(item => !!item.value)}
                    />
                </DataOverview>
            ))}
        </>
    );
};

const AdditionalProductsWithHandlers = withLoadingAndNoConnectionHandler(AdditionalProductsView);

export const AdditionalProductsDataSelector = getContractBasedDataSelector(
    (state: RootState) => state.additionalProducts,
);

export type AdditionalProductsProps = {
    contractId: string;
    link?: string;
};

export const AdditionalProducts: React.FC<AdditionalProductsProps> = ({ contractId, link }) => {
    const { t } = useTranslation('contracts');

    const { data: additionalProducts, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchAdditionalProducts,
        AdditionalProductsDataSelector,
        link,
    );

    return (
        <AccordionItem title={t('additional-products.headline')}>
            <div>
                <AdditionalProductsWithHandlers
                    isLoading={isLoading}
                    hasError={!!loadingError}
                    additionalProducts={additionalProducts}
                />
            </div>
        </AccordionItem>
    );
};
