import React from 'react';
import { Layout, Button } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';

type PaymentFeedbackProps = {
    asErrorFeedback?: boolean;
};

export const PaymentFeedbackView: React.FC<PaymentFeedbackProps> = ({ asErrorFeedback = false }) => {
    const { t } = useTranslation('unpaid-details');
    const history = useHistory();
    const feedbackType = asErrorFeedback ? 'error' : 'success';
    const analyticsFeedbackType = asErrorFeedback ? 'paymentError' : 'paymentSuccess';
    useAnalyticsPageViewTracker(analyticsFeedbackType);

    const backToDashboard = (): void => history.push(dashboardPagePath());

    return (
        <Layout>
            <Layout.Item className={'u-mt-large '} default="1/1">
                <Notification
                    headline={t(`payment-feedback.alert.${feedbackType}.title`)}
                    status={NotificationStatus[feedbackType]}
                    text={t(`payment-feedback.alert.${feedbackType}.message`)}
                />
            </Layout.Item>
            <Layout.Item className={'u-text-center'} default="1/1">
                <Button secondary onClick={backToDashboard} testId="backButton">
                    {t('payment-feedback.dashboard-button')}
                </Button>
            </Layout.Item>
        </Layout>
    );
};
