import React, { useState, MouseEvent } from 'react';
import {
    getRegistrationWithInvitationEndpoint,
    personalIdValidator,
    PersonalIdValidatorError,
    PrivacyPolicy,
    RegistrationDataWithInvitation,
    UserRegistrationError,
} from '@cp-es/common';
import { preventSubmit, Spinner, ValidatedCheckbox, ValidatedInput } from '@cp-shared-9/frontend-ui';
import { Button, ErrorMessage, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { parseErrorResponse } from '@cp-shared-9/frontend-integration';
import { trimAllValues, WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import { PrivacyPolicyComponent } from '../ui/PrivacyPolicy';

export type RegistrationFormProps = {
    privacyPolicyContent: PrivacyPolicy | undefined;
    showErrorCallback: (code?: WithDefaultCpIntegrationErrors<UserRegistrationError>) => void;
    invitationCode: string;
    successCallback: () => void;
};

export const InvitationCodeRegistrationForm: React.FC<RegistrationFormProps> = ({
    privacyPolicyContent,
    showErrorCallback,
    successCallback,
    invitationCode,
}) => {
    const { t } = useTranslation('registration');
    const [showSpinner, setShowSpinner] = useState(false);
    const [showPrivacyPolicy, togglePrivacyPolicy] = useState(false);
    const identificationError: PersonalIdValidatorError = {
        format: t('error.invitation-identification.format'),
        required: t('error.invitation-identification.required'),
    };
    const validationSchema = Yup.object().shape({
        identification: personalIdValidator(identificationError),
        acceptPrivacyPolicy: Yup.bool().oneOf([true], t('error.accept-privacy-policy-required')),
    });

    return (
        <Formik
            initialValues={{
                identification: '',
                acceptPrivacyPolicy: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values): void => {
                setShowSpinner(true);
                const requestBody: RegistrationDataWithInvitation = {
                    personalId: values.identification,
                    invitationCode: invitationCode,
                };
                CpDataApi.post(getRegistrationWithInvitationEndpoint(), trimAllValues(requestBody))
                    .then(() => successCallback())
                    .catch(error => showErrorCallback(parseErrorResponse<UserRegistrationError>(error).code))
                    .finally(() => setShowSpinner(false));
            }}
            data-testid={'invitationRegistrationForm'}
        >
            {(formik): JSX.Element => (
                <Form onSubmit={e => preventSubmit(e)}>
                    {showSpinner && <Spinner fullPage />}
                    <Fieldset>
                        <Fieldset.Row>
                            <Layout.Item default={'1/1'}>
                                <ValidatedInput label={t('invitation-identification.label')} name={'identification'} />
                            </Layout.Item>
                        </Fieldset.Row>

                        <Fieldset.Row>
                            <Layout.Item default={'1/1'}>
                                <ValidatedCheckbox
                                    name={'acceptPrivacyPolicy'}
                                    testId={'acceptPrivacyPolicy'}
                                    label={
                                        <>
                                            {t('accept-privacy-policy')}{' '}
                                            <Button
                                                onClick={(e: MouseEvent): void => {
                                                    e.preventDefault();
                                                    togglePrivacyPolicy(!showPrivacyPolicy);
                                                }}
                                                link={true}
                                            >
                                                {t('accept-privacy-policy-link')}
                                            </Button>
                                        </>
                                    }
                                />
                            </Layout.Item>
                        </Fieldset.Row>

                        {showPrivacyPolicy && <PrivacyPolicyComponent privacyPolicyContent={privacyPolicyContent} />}

                        <Layout.Item default={'1/1'}>
                            <Button full={true} onClick={formik.submitForm} testId={'submitButton'}>
                                {t('translation:editableSectionNav.confirm')}
                            </Button>
                            {!formik.isValid && !formik.isSubmitting && formik.submitCount > 0 && (
                                <ErrorMessage>{t('error.not-all-steps-done')}</ErrorMessage>
                            )}
                        </Layout.Item>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
