import React, { useState } from 'react';
import { FinanceFinancialDetails, FinancialDetailsEditStatus } from '@cp-es/common';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-9/frontend-ui';
import { ValueOrDefaultError } from 'components/value-or-default-error';
import { NotificationForLastEditStatus } from '../../../iban-section/NotificationForLastEditStatus';
import { SepaMandateDownload } from '../../../sepa-mandate-download/SepaMandateDownload';
import { Modal } from '@vwfs-bronson/bronson-react';
import { DefaultBusinessMarketApiErrorCode, WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';

export const ConsultView: React.FC<{
    startEditing: () => void;
    details: FinanceFinancialDetails;
    lastEditStatus: FinancialDetailsEditStatus;
}> = ({ startEditing, details, lastEditStatus }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [downloadError, setDownloadError] = useState<WithDefaultCpIntegrationErrors<
        DefaultBusinessMarketApiErrorCode
    > | null>(null);
    const financeIbanSectionItemList: DefinitionListItem[] = [
        {
            label: t('ibanSection.consultView.iban.label'),
            value: <ValueOrDefaultError value={details.iban} />,
            tooltip: t('ibanSection.consultView.iban.tooltip'),
        },
    ].filter(item => !!item.value);
    const handleDownloadError = (
        flag: WithDefaultCpIntegrationErrors<DefaultBusinessMarketApiErrorCode> | null,
    ): void => setDownloadError(flag);
    return (
        <DataOverview
            title={t('ibanSection.consultView.title')}
            buttonLabel={details?._links?.changeIban && t('ibanSection.consultView.button.edit')}
            buttonProps={{ onClick: startEditing, testId: 'editButton' }}
        >
            <DefinitionListHorizontal list={financeIbanSectionItemList} />
            {details?._links?.sepaMandate && (
                <SepaMandateDownload
                    downloadLinkLabel={t('ibanSection.consultView.sepa-mandate')}
                    sepaMandateDownloadEndpoint={details._links.sepaMandate}
                    handleDownloadError={handleDownloadError}
                />
            )}
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('ibanSection.editView.sepa-mandate-view.download-failure-modal.accept-button')}
                onConfirm={(): void => handleDownloadError(null)}
                onClose={(): void => handleDownloadError(null)}
                title={t('ibanSection.editView.sepa-mandate-view.download-failure-modal.title')}
                status="error"
                testId="downloadFailureModal"
            >
                {t('ibanSection.editView.sepa-mandate-view.download-failure-modal.text')}
            </Modal>
        </DataOverview>
    );
};
