import React, { useState } from 'react';
import { Button, ButtonContainer, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    DownloadLink,
    preventSubmit,
    Spinner,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    ValidatedCheckbox,
} from '@cp-shared-9/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import { SepaMandateForDownload } from '@cp-es/common';
import { WithDefaultCpIntegrationErrors, DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { parseErrorResponse } from '@cp-shared-9/frontend-integration';

export type SepaMandateFormProps = {
    onSubmit: () => void;
    isSubmitting?: boolean;
    sepaMandateDownloadEndpoint: string;
};

type FormType = {
    sepaMandateConfirmed: boolean;
};

const initialValues: FormType = {
    sepaMandateConfirmed: false,
};

const SepaMandateFormComponent: React.FC<SepaMandateFormProps> = ({
    onSubmit,
    sepaMandateDownloadEndpoint,
    isSubmitting = false,
}) => {
    const { t } = useTranslation('financial-details');
    const { onAction: onDownload } = useAnalyticsActionTracker('onSepaMandateDownload');
    const { onAction: onDownloadError } = useAnalyticsActionTracker('sepaMandateDownloadError');

    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState<WithDefaultCpIntegrationErrors<
        DefaultBusinessMarketApiErrorCode
    > | null>(null);

    const validationSchema = Yup.object().shape({
        sepaMandateConfirmed: Yup.bool().oneOf(
            [true],
            t('ibanSection.editView.sepa-mandate-view.checkbox-sepa-mandate-confirmed.error'),
        ),
    });
    const downloadSepaMandate = (): void => {
        setIsDownloading(true);
        CpDataApi.get(sepaMandateDownloadEndpoint)
            .then(response => {
                const { fileName, fileContent }: SepaMandateForDownload = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                onDownload();
                setDownloadError(null);
                setIsDownloading(false);
            })
            .catch(error => {
                const errorCode = parseErrorResponse<DefaultBusinessMarketApiErrorCode>(error).code;
                const errorMessage = parseErrorResponse<DefaultBusinessMarketApiErrorCode>(error).message;
                onDownloadError({ errorCode, errorMessage, errorCausingUrl: sepaMandateDownloadEndpoint });
                setIsDownloading(false);
                setDownloadError(errorCode);
            });
    };
    return (
        <UiBlockingSpinner isBlocking={isSubmitting}>
            <Layout>
                <Layout.Item default="1/1">
                    <p>
                        <strong>{t('ibanSection.editView.sepa-mandate-view.information-text')}</strong>
                    </p>
                    <p>{t('ibanSection.editView.sepa-mandate-view.business-note')}</p>
                </Layout.Item>
                <Layout.Item default="1/1">
                    {isDownloading ? (
                        <Spinner small />
                    ) : (
                        <DownloadLink
                            label={t('ibanSection.editView.sepa-mandate-view.download-link.label')}
                            onClick={downloadSepaMandate}
                            testId={'sepaDownloadLink'}
                        />
                    )}
                </Layout.Item>
                <Layout.Item default="1/1">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {formik => (
                            <Form onSubmit={e => preventSubmit(e)}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/1">
                                                <ValidatedCheckbox
                                                    name={'sepaMandateConfirmed'}
                                                    label={t(
                                                        'ibanSection.editView.sepa-mandate-view.checkbox-sepa-mandate-confirmed.label',
                                                    )}
                                                    testId="sepaMandateConfirmed"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ButtonContainer nav>
                                            <Button onClick={() => formik.handleSubmit()} testId="submitButton">
                                                {t('translation:editableSectionNav.continue')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
            </Layout>
            <Modal
                shown={!!downloadError}
                buttonConfirmText={t('ibanSection.editView.sepa-mandate-view.download-failure-modal.accept-button')}
                onConfirm={(): void => setDownloadError(null)}
                onClose={(): void => setDownloadError(null)}
                title={t('ibanSection.editView.sepa-mandate-view.download-failure-modal.title')}
                status="error"
                testId="downloadFailureModal"
            >
                {t('ibanSection.editView.sepa-mandate-view.download-failure-modal.text')}
            </Modal>
        </UiBlockingSpinner>
    );
};

export const SepaMandateForm = SepaMandateFormComponent;
