import { CPDate } from '@cp-shared-9/common-utilities';
import { ContractPartyRole } from './contract-party-types';

export enum ContractStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
}

export enum ContractType {
    FINANCING = 'FINANCING',
    FINANCE_LEASE = 'FINANCE_LEASE',
    OPERATING_LEASE = 'OPERATING_LEASE',
}

export interface ContractTypeChecker {
    /**
     * Is the contract an financing contract?
     */
    readonly isFinancingContract: boolean;
    /**
     * Is the contract a finance lease contract?
     */
    readonly isFinanceLeaseContract: boolean;
    /**
     * Is the contract an operating leasing contract?
     */
    readonly isOperatingLeaseContract: boolean;
}

export interface ContractStatusChecker {
    /**
     * Is the contract an active contract?
     */
    readonly isActiveContract: boolean;
    /**
     * Is the contract an expired contract?
     */
    readonly isExpiredContract: boolean;
}

export interface BaseContract {
    /**
     * Start date of the contract
     */
    beginDate?: CPDate;
    /**
     * The type of the contract, which does not necessarily correlates 1:1 to the category (e.g AL and SV are Operating)
     */
    contractType: ContractType;
    /**
     * The contract identification
     * @example "440128795350"
     */
    contractNumber: string;
    /**
     * The car information to be shown in the contract header
     * @example "VW CALIFORNIA 2.0BITDI SE 180 4DR 4MTN DSG"
     */
    carInformation: string;
    /**
     * The contract status (active, expired, ...)
     */
    contractStatus: ContractStatus;
    /**
     * The next instalment amount of the contract
     */
    nextInstalmentAmount?: number;
    /**
     * An optional license plate number
     */
    licensePlateNumber?: string;
    /**
     * Optional amount of cars to support multi-vehicle contracts
     * If not set, 1 will be assumed
     */
    numberOfCars?: number;
    /**
     * A product modality
     */
    productModality?: string;
    /**
     * The total UNPAID amount of the contract
     */
    totalAmount?: number;
    /**
     * The total unpaid amount for the contract
     */
    unpaidAmount?: number;
    /**
     * The number of unpaid installments for the contract
     */
    unpaidInstalments?: number;
    /**
     * The contract end date
     */
    contractEndDate?: CPDate;
    /**
     * The user role
     */
    userRole?: ContractPartyRole;
    /**
     * Balloon amount
     */
    balloonAmount?: number;
}

export interface FinanceContract extends BaseContract {
    /**
     * The next instalment date of the contract
     */
    nextInstalmentDate?: CPDate;
}

export interface FinancingContract extends FinanceContract {
    contractType: ContractType.FINANCING;
}

export interface FinancingContractWithEncryptedContractNumber extends FinancingContract {
    /**
     * Encrypted contract number for use in routing
     */
    _encryptedContractNumber: string;
}

export interface FinanceLeaseContract extends FinanceContract {
    contractType: ContractType.FINANCE_LEASE;
}

export interface FinanceLeaseContractWithEncryptedContractNumber extends FinanceLeaseContract {
    /**
     * Encrypted contract number for use in routing
     */
    _encryptedContractNumber: string;
}

export interface OperatingLeaseContract extends BaseContract {
    contractType: ContractType.OPERATING_LEASE;
    /**
     * The vin code of the car
     */
    vinCode?: string;
}

export interface OperatingLeaseWithEncryptedContractNumber extends OperatingLeaseContract {
    /**
     * Encrypted contract number for use in routing
     */
    _encryptedContractNumber: string;
}

function contractStatusCheckers(contractStatus: ContractStatus): ContractStatusChecker {
    return {
        isActiveContract: contractStatus === ContractStatus.ACTIVE,
        isExpiredContract: contractStatus === ContractStatus.EXPIRED,
    };
}

function contractTypeCheckers(contractType: ContractType): ContractTypeChecker {
    return {
        isFinanceLeaseContract: contractType === ContractType.FINANCE_LEASE,
        isFinancingContract: contractType === ContractType.FINANCING,
        isOperatingLeaseContract: contractType === ContractType.OPERATING_LEASE,
    };
}

export type ContractLinks = {
    financialDetails?: string;
    contractParties?: string;
    vehicleDetails?: string;
    additionalProducts?: string;
    amortizationDetails?: string;
    requestCertificate?: string;
    unpaidDetails?: string;
    earlySettlementHeader?: string;
    totalEarlySettlement?: string;
    partialEarlySettlement?: string;
    amortizationTablePdfDownload?: string;
};

export type ResourceLinks = {
    _links?: ContractLinks;
};

export type EncryptedContractNumber = {
    _encryptedContractNumber: string;
};

function extractLinks(contract: ContractDataWithLinks): ResourceLinks {
    return {
        _links: contract._links,
    };
}

export type ContractData = FinancingContract | FinanceLeaseContract | OperatingLeaseContract;
export type ContractDataWithEncryptedContractNumber =
    | FinancingContractWithEncryptedContractNumber
    | FinanceLeaseContractWithEncryptedContractNumber
    | OperatingLeaseWithEncryptedContractNumber;
export type ContractDataWithLinks = ContractData & ResourceLinks;
export type ContractDataWithLinksAndEncryptedContractNumber = ContractDataWithEncryptedContractNumber & ResourceLinks;
export type Contract = ContractData &
    ContractTypeChecker &
    ContractStatusChecker &
    ResourceLinks &
    EncryptedContractNumber;

export function buildContract(contract: ContractDataWithLinksAndEncryptedContractNumber): Contract {
    const contractTypeCheckersResult = contractTypeCheckers(contract.contractType);
    const contractStatusCheckersResult = contractStatusCheckers(contract.contractStatus);

    const contractBuilt: Contract = {
        ...contract,
        ...contractTypeCheckersResult,
        ...contractStatusCheckersResult,
        ...extractLinks(contract),
        _encryptedContractNumber: contract._encryptedContractNumber,
        isActiveContract: contract.contractStatus === ContractStatus.ACTIVE,
        isExpiredContract: contract.contractStatus === ContractStatus.EXPIRED,
        isFinanceLeaseContract: contract.contractType === ContractType.FINANCE_LEASE,
        isFinancingContract: contract.contractType === ContractType.FINANCING,
        isOperatingLeaseContract: contract.contractType === ContractType.OPERATING_LEASE,
    };

    return contractBuilt;
}

export type FetchContractError = 'CUSTOMER_NOT_FOUND' | 'CONTRACT_NOT_FOUND' | 'NO_CONTRACT_FOUND_FOR_GIVEN_BRAND';

export function enrichContract(contract: ContractDataWithLinks): Contract {
    return {
        ...contract,
        _encryptedContractNumber:
            (contract as Contract & EncryptedContractNumber)._encryptedContractNumber ?? undefined,
        ...contractStatusCheckers(contract.contractStatus),
        ...contractTypeCheckers(contract.contractType),
    };
}

export function enrichContracts(contracts: ContractDataWithLinks[]): Contract[] {
    return contracts.map(enrichContract);
}
