import React from 'react';
import { PaymentDetails } from '@cp-es/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DefinitionListHorizontal } from '@cp-shared-9/frontend-ui';
import {
    DataOverview,
    ProductTable,
    Layout,
    Button,
    ButtonContainer,
    Fieldset,
    SectionHeading,
    DescriptionList,
} from '@vwfs-bronson/bronson-react';

type OnlinePaymentProps = {
    paymentDetails?: PaymentDetails;
    next: () => void;
    back: () => void;
};

export const OnlinePaymentView: React.FC<OnlinePaymentProps> = ({ paymentDetails, back, next }) => {
    const { t, f } = useTranslationWithFormatting('unpaid-details');
    if (!paymentDetails) {
        return null;
    }
    const paymentDetailsValues = [
        {
            label: t('payment-selector.payment-details.unpaid-count'),
            value: paymentDetails.totalNumberOfUnpaid,
        },
        {
            label: t('payment-selector.payment-details.unpaid-selected'),
            value: paymentDetails.selectedNumberOfUnpaid,
        },
        {
            label: t('payment-selector.payment-details.total-arrears'),
            value: f(paymentDetails.totalArrears, TranslationFormat.CURRENCY),
        },
    ];
    return (
        <>
            <SectionHeading level="3" subtitle={t('payment-selector.text')}>
                {t('payment-selector.headline')}
            </SectionHeading>
            <Fieldset>
                <Fieldset.Row>
                    <DataOverview title={t('payment-selector.payment-details.headline')} icon="semantic-checkmark">
                        <Layout equalHeight divider>
                            <Layout.Item default="1/2" s="1/1">
                                <DefinitionListHorizontal list={paymentDetailsValues} />
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/1">
                                <ProductTable testId="cp-selected-amount-to-pay">
                                    <ProductTable.Section title={t('payment-selector.payment-details.selected-amount')}>
                                        <DescriptionList>
                                            <DescriptionList.Detail>
                                                {f(
                                                    paymentDetails && paymentDetails.paymentAmount,
                                                    TranslationFormat.CURRENCY,
                                                )}
                                            </DescriptionList.Detail>
                                        </DescriptionList>
                                        <DescriptionList>
                                            <DescriptionList.Group
                                                termText={t('payment-selector.payment-details.payment-date')}
                                            >
                                                <DescriptionList.Detail>
                                                    {f(paymentDetails.paymentDate, TranslationFormat.DATE)}
                                                </DescriptionList.Detail>
                                            </DescriptionList.Group>
                                        </DescriptionList>
                                    </ProductTable.Section>
                                </ProductTable>
                            </Layout.Item>
                        </Layout>
                    </DataOverview>
                </Fieldset.Row>
                <Fieldset.Row>
                    <ButtonContainer nav>
                        <Button secondary onClick={back} testId="backButton">
                            {t('translation:editableSectionNav.back')}
                        </Button>
                        <Button onClick={next} testId="payNowButton">
                            {t('payment-selector.pay-now')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
};
