import { Button, ButtonContainer, Fieldset, Heading, IconList as IconListShared } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withAutoScroll, IconList } from '@cp-shared-9/frontend-ui';

export type ConfirmationViewProps = {
    invoiceConsentChecked: boolean;
    toPreviousView: () => void;
    onSubmit: () => void;
};

export const ConfirmationView: React.FC<ConfirmationViewProps> = withAutoScroll(
    ({ invoiceConsentChecked, toPreviousView, onSubmit }) => {
        const { t } = useTranslation('my-profile-invoice-consent');

        type IconListProps = {
            label: string;
            icon: string;
        };

        const singUpConfirmItems: IconListProps[] = [
            {
                label: t('confirm-view.sign-up.content.line1'),
                icon: 'semantic-checkmark',
            },
            {
                label: t('confirm-view.sign-up.content.line2'),
                icon: 'semantic-checkmark',
            },
            {
                label: t('confirm-view.sign-up.content.line3'),
                icon: 'semantic-checkmark',
            },
        ];

        const singOffConfirmItems: IconListProps[] = [
            {
                label: t('confirm-view.sign-off.content.line1'),
                icon: 'semantic-crossed-out',
            },
            {
                label: t('confirm-view.sign-off.content.line2'),
                icon: 'semantic-crossed-out',
            },
            {
                label: t('confirm-view.sign-off.content.line3'),
                icon: 'semantic-checkmark',
            },
        ];

        return (
            <>
                <Heading className={'u-mb'} level={'6'}>
                    {t(`confirm-view.${invoiceConsentChecked ? 'sign-up' : 'sign-off'}.content.heading`)}
                </Heading>
                {invoiceConsentChecked && (
                    <>
                        <IconList items={singUpConfirmItems} />
                    </>
                )}
                {!invoiceConsentChecked && (
                    <>
                        <IconListShared>
                            <IconList items={singOffConfirmItems} />
                        </IconListShared>
                    </>
                )}

                <Fieldset>
                    <Fieldset.Row>
                        <ButtonContainer nav>
                            <Button secondary onClick={toPreviousView} testId="toPreviousButton" type="button">
                                {t('translation:editableSectionNav.back')}
                            </Button>
                            <Button onClick={onSubmit} testId="submitButton" type="button">
                                {t('confirm-view.confirm-changes')}
                            </Button>
                        </ButtonContainer>
                    </Fieldset.Row>
                </Fieldset>
            </>
        );
    },
    'ConsentSectionConfirmationView',
);
