import { Contract, FinanceFinancialDetails, Installment } from '@cp-es/common';
import { useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { Layout } from '@vwfs-bronson/bronson-react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { AmortizationTableHeader } from './header/AmortizationTableHeader';
import { AmortizationTableDownload } from './download/AmortizationTableDownload';
import { AmortizationTableInstallmentOverview } from './installment-overview/AmortizationTableInstallmentOverview';
import { fetchAmortizationDetails } from './AmortizationTableSlice';
import { useContract } from '../contracts/useContract';
import { useFinancialDetails } from '../contracts/financial-details/useFinancialDetails';
import { AmortizationDetailsDataSelector } from './AmortizationTableSelector';
import { AmortizationTableLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

export type AmortizationTableUiProps = {
    contract?: Contract;
    amortizationDetails?: Installment[];
    financialDetails?: FinanceFinancialDetails;
};

export type AmortizationTableProps = {
    encryptedContractId: string;
};

export const AmortizationTableUi: React.FC<AmortizationTableUiProps> = ({
    contract,
    amortizationDetails,
    financialDetails,
}) => {
    useAnalyticsPageViewTracker('amortizationTable', !!(contract && amortizationDetails && financialDetails));
    // Check for contract being available, otherwise it might be that fetching hasn't even started
    if (!contract) {
        return null;
    }

    if (!amortizationDetails || !financialDetails) {
        return <NoConnectionNotification />;
    }

    return (
        <Layout>
            <Layout.Item default="1/1">
                <AmortizationTableHeader
                    contract={contract}
                    amortizationDetails={amortizationDetails}
                    financialDetails={financialDetails}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                <AmortizationTableDownload
                    link={contract?._links?.amortizationTablePdfDownload}
                    contractNumber={contract.contractNumber}
                    encryptedContractId={contract._encryptedContractNumber}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                <AmortizationTableInstallmentOverview
                    amortizationDetails={amortizationDetails}
                    forFinanceLeaseContract={contract.isFinanceLeaseContract}
                />
            </Layout.Item>
        </Layout>
    );
};

const AmortizationTableUiWithHandlers = withLoadingAndNoConnectionHandler(AmortizationTableUi);

export const AmortizationTable: React.FC<AmortizationTableProps> = ({ encryptedContractId }) => {
    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractId, { encryptedContractId: true });

    const {
        data: financialDetails,
        isLoading: isLoadingFinancialDetails,
        loadingError: loadingErrorFinancialDetails,
    } = useFinancialDetails(contract?.contractNumber || '');

    const {
        data: amortizationDetails,
        isLoading: isLoadingAmortizationDetails,
        loadingError: loadingErrorAmortizationDetails,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchAmortizationDetails,
        AmortizationDetailsDataSelector,
        contract?._links?.amortizationDetails,
    );

    const isLoading = isLoadingContracts || isLoadingAmortizationDetails || isLoadingFinancialDetails;
    const hasError = !!loadingErrorContract || !!loadingErrorAmortizationDetails || !!loadingErrorFinancialDetails;

    if (contract && !(contract.isFinanceLeaseContract || contract?.isFinancingContract)) {
        // As agreed with the PO, show no connection error, if a "forbidden" contract is accessed
        return <NoConnectionNotification />;
    }

    return (
        <AmortizationTableUiWithHandlers
            isLoading={isLoading}
            hasError={hasError}
            contract={contract}
            amortizationDetails={amortizationDetails}
            financialDetails={financialDetails}
            loadingPlaceholder={<AmortizationTableLoadingPlaceholder tileElements={3} />}
        />
    );
};
