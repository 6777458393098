import { FinanceContract, OperatingLeaseContract } from '@cp-es/common';
import { ContractHeader as ContractHeaderShared } from '@cp-shared-9/frontend-ui';
import { LicensePlate } from 'components/license-plate';
import React from 'react';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { getProductCategoryTranslationLabel } from './ProductCategoryTranslationLabel';

export type ContractHeaderProps = {
    contract: FinanceContract | OperatingLeaseContract;
    contractSummary?: React.ReactNode;
};

export const ContractHeader: React.FC<ContractHeaderProps> = props => {
    const { t } = useTranslationWithFormatting('contracts');
    const { contract: baseContract, contractSummary } = props;
    const { contractType, carInformation, contractNumber, licensePlateNumber } = baseContract;
    const licensePlate = <LicensePlate licensePlateNumber={licensePlateNumber} />;

    return (
        <>
            <ContractHeaderShared
                carInformation={carInformation}
                contractCategory={t(getProductCategoryTranslationLabel(contractType))}
                contractNumber={contractNumber}
                contractIdentifier={licensePlate}
                contractSummary={contractSummary}
            />
        </>
    );
};
