import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { PageHeader } from 'components/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UnpaidDetails } from 'components/unpaid-details/';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

interface RouteParams {
    contractId: string;
}

export const UnpaidDetailsPage: React.FC = () => {
    const { t } = useTranslation('unpaid-details');
    const { contractId: encryptedContractId } = useParams<RouteParams>();

    useAnalyticsPageViewTracker('unpaidDetails', !!encryptedContractId);

    if (!encryptedContractId) return <NoConnectionNotification />;

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <UnpaidDetails encryptedContractId={encryptedContractId} />
        </ContentSection>
    );
};
