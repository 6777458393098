import { Contract } from '@cp-es/common';
import { formatCpDate } from '@cp-shared-9/common-utilities';
import { sortBy } from 'lodash';

export const getMostRecentSeatContract = (contracts: Contract[] = []): Contract => {
    const seatContracts = contracts.filter(contract => {
        if (
            contract.carInformation.toLowerCase().includes('seat') &&
            formatCpDate(formatCpDate().toCpDate()).diff(contract.beginDate, 'year') < 7 &&
            contract.isActiveContract
        )
            return contract;

        return false;
    });

    const sortedSeatContracts = sortBy(seatContracts, seatContract => seatContract.beginDate);

    return sortedSeatContracts[0];
};
