import { useMemo } from 'react';
import {
    ProductRoutingData,
    FetchContractError,
    getProductRoutingEndpoint,
    ProductRoutingResponse,
    PersonalDetails,
    Contract,
    TotalEarlySettlementOfflinePayment,
} from '@cp-es/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-9/frontend-integration';
import { ProductRoutingDataSelector } from './productRoutingSelector';
import { fetchProductRouting } from './productRoutingSlice';
import { fetchVehicleDetails, VehicleDetailsDataSelector } from 'components/contracts/vehicle-details';
import { fetchFinancialDetails } from 'components/contracts/financial-details';
import { FinancialDetailsDataSelector } from 'components/contracts/financial-details/FinancialDetailsDataSelector';

export function useProductRouting(
    mostRecentSeatContract?: Contract,
    personalDetails?: PersonalDetails,
    offlinePayment?: TotalEarlySettlementOfflinePayment,
): AbstractDataState<ProductRoutingResponse, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const vehicleDetailsState = useGetContractBasedApiData(
        mostRecentSeatContract?.contractNumber || '',
        fetchVehicleDetails,
        VehicleDetailsDataSelector,
        mostRecentSeatContract?._links?.vehicleDetails,
    );

    const financialDetailsState = useGetContractBasedApiData(
        mostRecentSeatContract?.contractNumber || '',
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        mostRecentSeatContract?._links?.financialDetails,
    );

    const vehicle = vehicleDetailsState.data?.find(car => car.vehicleDescription?.includes('Seat'));

    const productRoutingData: ProductRoutingData = {
        fullName: personalDetails?.identification.name,
        NIF: personalDetails?.identification.id,
        contactData: {
            telephoneNumber: personalDetails?.contact.primaryPhoneNumber,
            email: personalDetails?.contact.email,
            mobilePhoneNumber: personalDetails?.contact.secondaryPhoneNumber,
        },
        address: {
            street: personalDetails?.address.name,
            houseNumber: personalDetails?.address.number || '',
            floor: personalDetails?.address?.floor,
            door: personalDetails?.address?.door,
            zipCode: personalDetails?.address.zip,
            city: personalDetails?.address.locality,
        },
        paymentData: {
            iban: financialDetailsState?.data?.iban,
            bankName: offlinePayment?.bankName,
        },
        vehicleData: {
            vin: vehicle?.vinCode,
            licensePlate: vehicle?.licensePlate,
            vehicleModel: {
                name: vehicle?.vehicleDescription?.split(' ')[1],
            },
        },
    };

    const requestConfig = {
        data: productRoutingData,
    };

    const productRoutingState = useGetContractBasedApiData(
        mostRecentSeatContract?.contractNumber || '',
        fetchProductRouting,
        ProductRoutingDataSelector,
        getProductRoutingEndpoint(),
        false,
        requestConfig,
    );

    return useMemo(
        () => ({
            isLoading:
                vehicleDetailsState.isLoading || financialDetailsState.isLoading || productRoutingState.isLoading,
            hasReceivedResponse: vehicleDetailsState.loadingError
                ? vehicleDetailsState.hasReceivedResponse
                : financialDetailsState.loadingError
                ? financialDetailsState.hasReceivedResponse
                : financialDetailsState.hasReceivedResponse && productRoutingState.hasReceivedResponse,
            failedLoadingAttempts:
                hasFetchedSuccessfully(vehicleDetailsState) && hasFetchedSuccessfully(financialDetailsState)
                    ? productRoutingState.failedLoadingAttempts
                    : financialDetailsState.failedLoadingAttempts || vehicleDetailsState.failedLoadingAttempts,
            data: productRoutingState.data,
            loadingError: hasFetchedSuccessfully(vehicleDetailsState)
                ? hasFetchedSuccessfully(financialDetailsState)
                    ? productRoutingState.loadingError
                    : financialDetailsState.loadingError
                : vehicleDetailsState.loadingError,
        }),
        [financialDetailsState, vehicleDetailsState, productRoutingState],
    );
}
