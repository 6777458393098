import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Contact, PersonalDetailsEditStatus } from '@cp-es/common';
import { Notification, NotificationStatus } from '@cp-shared-9/frontend-ui';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';
import { ContactList } from './ContactList';

export type ConsultViewProps = {
    contact: Contact;
    startEditing: () => void;
    lastEditStatus: PersonalDetailsEditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ contact, startEditing, lastEditStatus }) => {
    const { t } = useTranslation('my-profile-contact-details');

    const isChangeAllowed = !!contact._links?.changeContactDetails;

    return (
        <DataOverview
            title={t('consultView.title')}
            buttonLabel={isChangeAllowed ? t('translation:editableSectionNav.start') : undefined}
            buttonProps={isChangeAllowed ? { onClick: startEditing, testId: 'editButton' } : undefined}
        >
            <Notification className="u-mb" status={NotificationStatus.info} text={t('consultView.info')} />
            <ContactList contact={contact} />
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
        </DataOverview>
    );
};
