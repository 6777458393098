import {
    AbstractDataState,
    useGetContractBasedApiData,
    hasFetchedSuccessfully,
} from '@cp-shared-9/frontend-integration';
import { FetchContractError, FinancialDetails } from '@cp-es/common';
import { useContract } from '../useContract';
import { fetchFinancialDetails } from './FinancialDetailsSlice';
import { FinancialDetailsDataSelector } from './FinancialDetailsDataSelector';
import { useMemo } from 'react';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';

export function useFinancialDetails(
    contractId: string,
): AbstractDataState<FinancialDetails, DefaultBusinessMarketApiErrorCode | FetchContractError> {
    const contractState = useContract(contractId);
    const financialDetailsState = useGetContractBasedApiData(
        contractId,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        contractState.data?._links?.financialDetails,
    );

    return useMemo(
        () => ({
            isLoading: contractState.isLoading || financialDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && financialDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? financialDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: financialDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? financialDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, financialDetailsState],
    );
}
