import React from 'react';

import { MyProfileOverview } from 'components/my-profile/MyProfile';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { PageHeader } from '../components/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { useAnalyticsPageViewTracker } from '@cp-shared-9/frontend-ui';

export const MyProfilePage: React.FC = () => {
    const { t } = useTranslation('my-profile');

    useAnalyticsPageViewTracker('profile');

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <MyProfileOverview />
        </ContentSection>
    );
};
