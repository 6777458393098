import {
    Contract,
    EarlySettlementError,
    EarlySettlementHeader,
    PartialEarlySettlement as PartialEarlySettlementType,
} from '@cp-es/common';
import { CPDate, formatCpDate, WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';
import React, { useState } from 'react';
import { TotalEarlySettlementErrorNotification } from '../error-notification';
import { NoConnectionNotification } from '../../notifications/no-connection/NoConnectionNotification';
import { Button, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContract } from '../../contracts/useContract';
import { useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { fetchEarlySettlementHeader } from '../EarlySettlementHeaderSlice';
import { EarlySettlementHeaderDataSelector } from '../EarlySettlementHeaderDataSelector';
import { withLoadingHandler } from '../../integration-wrapper';
import { dashboardPagePath, earlySettlementPagePath } from '../../navigation/paths';
import { StepperType } from './stepper/StepperType';
import { StepperUi } from './stepper';
import { PartialEarlySettlementViewType } from './PartialEarlySettlementViewType';
import { QuoteDetails } from './quote-details';
import { Calculation } from './calculation/Calculation';
import { OfflinePayment } from './offline-payment';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

type PartialEarlySettlementComponentProps = {
    contract?: Contract;
    earlySettlementHeader?: EarlySettlementHeader;
    backToJointEntryPage: () => void;
    lastFetchError?: WithDefaultCpIntegrationErrors<EarlySettlementError>;
};

const PartialEarlySettlementComponent: React.FC<PartialEarlySettlementComponentProps> = ({
    contract,
    earlySettlementHeader,
    lastFetchError,
    backToJointEntryPage,
}) => {
    const [currentView, setCurrentView] = useState<PartialEarlySettlementViewType>('PES_CALCULATIONS');
    const [requestDate, setRequestDate] = useState<CPDate>(
        formatCpDate()
            .add(1)
            .toCpDate(),
    );
    const [selectedOption, setSelectedOption] = useState<PartialEarlySettlementType | undefined>(undefined);
    const { t } = useTranslation('partial-early-settlement');
    const { onAction: onProceedToPayment } = useAnalyticsActionTracker('onEarlySettlementProceedToPayment');
    if (lastFetchError) {
        return <TotalEarlySettlementErrorNotification fetchContractError={lastFetchError} />;
    }

    if (!contract || !contract.totalAmount || !contract.contractEndDate || !earlySettlementHeader) {
        return null;
    }

    if (!(contract.isFinanceLeaseContract || contract.isFinancingContract)) {
        return <NoConnectionNotification />;
    }

    const stepperValue: StepperType[] = [
        {
            title: t('stepper.step-1'),
            viewType: 'PES_CALCULATIONS',
        },
        { title: t('stepper.step-2'), viewType: 'PES_QUOTE_DETAILS' },
        { title: t('stepper.step-3'), viewType: 'PES_OFFLINE_PAYMENT' },
    ];

    return (
        <>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <StepperUi stepper={stepperValue} currentViewType={currentView} />
                </Layout.Item>

                {currentView === 'PES_CALCULATIONS' && (
                    <Calculation
                        contract={contract}
                        earlySettlementHeader={earlySettlementHeader}
                        setRequestDate={setRequestDate}
                        setSelectedOption={setSelectedOption}
                        backToJointEntryPoint={backToJointEntryPage}
                        continueToQuoteDetails={(): void => setCurrentView('PES_QUOTE_DETAILS')}
                    />
                )}

                {currentView === 'PES_QUOTE_DETAILS' && (
                    <QuoteDetails
                        back={(): void => setCurrentView('PES_CALCULATIONS')}
                        proceedToPayment={(): void => {
                            onProceedToPayment();
                            setCurrentView('PES_OFFLINE_PAYMENT');
                        }}
                        contractDetails={contract}
                        partialEarlySettlementDetails={selectedOption}
                        paymentDate={requestDate}
                    />
                )}
                {currentView === 'PES_OFFLINE_PAYMENT' && (
                    <OfflinePayment
                        back={(): void => setCurrentView('PES_QUOTE_DETAILS')}
                        paymentDate={requestDate}
                        contract={contract}
                        earlySettlementHeader={earlySettlementHeader}
                        totalAmount={selectedOption?.totalAmount}
                        codeTransfer={selectedOption?.codeTransfer}
                        partialEarlySettlementOptionType={selectedOption?.optionType}
                        offlinePaymentSendingEmailLink={selectedOption?._links?.offlinePaymentEmailSending}
                    />
                )}
            </Layout>
        </>
    );
};

const PartialEarlySettlementWithHandlers = withLoadingHandler(PartialEarlySettlementComponent);

export const PartialEarlySettlement: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractId, { encryptedContractId: true });

    const {
        data: earlySettlementHeader,
        isLoading: isLoadingEarlySettlementHeader,
        loadingError: loadingErrorEarlySettlementHeader,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchEarlySettlementHeader,
        EarlySettlementHeaderDataSelector,
        contract?._links?.earlySettlementHeader,
    );

    const isLoading = isLoadingContract || isLoadingEarlySettlementHeader;

    const hasError = loadingErrorContract || loadingErrorEarlySettlementHeader;

    const backToJointEntryPage = (): void => history.push(earlySettlementPagePath(encryptedContractId));
    const backToDashboard = (): void => history.push(dashboardPagePath());

    return (
        <Layout>
            <Layout.Item className={'u-mt-large'} default="1/1">
                <PartialEarlySettlementWithHandlers
                    isLoading={isLoading}
                    contract={contract}
                    earlySettlementHeader={earlySettlementHeader}
                    backToJointEntryPage={backToJointEntryPage}
                />
            </Layout.Item>
            {hasError && (
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button secondary onClick={backToDashboard} testId="backToDashboardButton">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                </Layout.Item>
            )}
        </Layout>
    );
};
