import React, { useState } from 'react';
import { DownloadLink, Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';
import { CpDataApi } from '../../../../cp-xhr';
import { SepaMandateForDownload } from '@cp-es/common';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { parseErrorResponse } from '@cp-shared-9/frontend-integration';
import { DefaultBusinessMarketApiErrorCode, WithDefaultCpIntegrationErrors } from '@cp-shared-9/common-utilities';

export type SepaMandateDownloadProps = {
    handleDownloadError(errorCode: WithDefaultCpIntegrationErrors<DefaultBusinessMarketApiErrorCode> | null): void;
    sepaMandateDownloadEndpoint: string;
    downloadLinkLabel: string;
};

export const SepaMandateDownload: React.FC<SepaMandateDownloadProps> = ({
    handleDownloadError,
    sepaMandateDownloadEndpoint,
    downloadLinkLabel,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { onAction: onDownload } = useAnalyticsActionTracker('onSepaMandateDownload');
    const { onAction: onDownloadError } = useAnalyticsActionTracker('sepaMandateDownloadError');

    const downloadSepaMandate = (): void => {
        setIsDownloading(true);
        CpDataApi.get(sepaMandateDownloadEndpoint)
            .then(response => {
                const { fileName, fileContent }: SepaMandateForDownload = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                onDownload();
                setIsDownloading(false);
                handleDownloadError(null);
            })
            .catch(error => {
                const errorCode = parseErrorResponse<DefaultBusinessMarketApiErrorCode>(error).code;
                const errorMessage = parseErrorResponse<DefaultBusinessMarketApiErrorCode>(error).message;
                onDownloadError({ errorCode, errorMessage, errorCausingUrl: sepaMandateDownloadEndpoint });
                setIsDownloading(false);
                handleDownloadError(errorCode);
            });
    };
    return isDownloading ? (
        <Spinner small />
    ) : (
        <DownloadLink label={downloadLinkLabel} onClick={downloadSepaMandate} testId={'sepaMandateDownloadLink'} />
    );
};
