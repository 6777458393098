import React, { useCallback } from 'react';
import { HeroImage } from '@cp-shared-9/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { useLogout } from '../useLogout';

export interface LogoutUiProps {
    logoutType: 'auto' | 'manual';
}

export const LogoutUi: React.FC<LogoutUiProps> = ({ logoutType }) => {
    const login = useLogin();

    const onClick = useCallback(() => {
        login();
    }, [login]);

    const { cmsContent: logoutContent } = useLogout();

    return (
        <HeroImage
            title={logoutContent?.[logoutType].headline}
            subTitle={logoutContent?.[logoutType].subHeadline}
            imageUrl={logoutContent?.imageUrl}
            buttonText={logoutContent?.buttonText}
            clickHandler={onClick}
        />
    );
};
