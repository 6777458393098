import {
    DefinitionListHorizontal,
    DefinitionListItem,
    IconList,
    IconListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-9/frontend-ui';
import { Button, ButtonContainer, Heading } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type SubrogationInfoProps = {
    documentsList: IconListItem[];
    type: string;
    isLegalPerson?: boolean;
    isPrivatePerson?: boolean;
};

export const SubrogationInfo: React.FC<SubrogationInfoProps> = ({
    documentsList,
    type,
    isLegalPerson = false,
    isPrivatePerson = false,
}) => {
    const { t } = useTranslation('request-subrogation');
    const history = useHistory();

    const itemList: DefinitionListItem[] = [
        {
            label: t('subrogation-info.type.label'),
            value: type,
        },
        {
            label: t('subrogation-info.email.label'),
            value: t('subrogation-info.email.value'),
        },
    ];

    let pdfDocument = '/pdf-documents/Legal_person_GDPR.pdf';
    if (!isLegalPerson && isPrivatePerson) {
        pdfDocument = '/pdf-documents/Natural_person_GDPR.pdf';
    }

    const handleOpenPdfDocument = (): void => {
        window.open(pdfDocument);
    };

    return (
        <>
            <DefinitionListHorizontal list={itemList} />
            <Heading level={'3'}>{t('subrogation-info.title')}</Heading>
            <Notification
                status={NotificationStatus.warning}
                text={t('subrogation-info.notification')}
                className={'u-mb'}
            />
            <IconList items={documentsList} />
            {isLegalPerson && (
                <ul>
                    <ul>
                        <li>{t('documents.legal-person.doc13')}</li>
                        <li>{t('documents.legal-person.doc14')}</li>
                        <li>{t('documents.legal-person.doc15')}</li>
                        <li>{t('documents.legal-person.doc16')}</li>
                        <li>{t('documents.legal-person.doc17')}</li>
                    </ul>
                </ul>
            )}
            {(isLegalPerson || isPrivatePerson) && (
                <div className="o-component-wrapper">
                    <ButtonContainer right>
                        <Button
                            element="a"
                            link
                            onClick={handleOpenPdfDocument}
                            testId="pdfDocumentButton"
                            icon="semantic-download"
                        >
                            {t('buttons.download-gdpr-button')}
                        </Button>
                    </ButtonContainer>
                </div>
            )}
            <ButtonContainer center>
                <Button secondary onClick={() => history.push(dashboardPagePath())} testId="backButton">
                    {t('subrogation-info.back-button')}
                </Button>
            </ButtonContainer>
        </>
    );
};
