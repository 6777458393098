import React from 'react';
import { NotificationStatus, Notification } from '@cp-shared-9/frontend-ui';
import { useTranslation } from 'react-i18next';
import DigitalRenewalContentTransformComponent from 'components/digital-renewal/DigitalRenewalContentTransformComponent';
import { useSelector } from 'hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { clearNotification } from 'components/notifications/digital-renewal/DigitalRenewalNotificationSlice';
import { ContractType, DigitalRenewalOptions } from '@cp-es/common';
import { checkTheMonthsLeft } from 'utils/digitalRenewalEligibleContracts';

export const DigitalRenewalSuccessNotification = () => {
    const { t } = useTranslation('digital-renewal');
    const dispatch = useDispatch();
    const { success, contract, selectedOption } = useSelector(state => state.notifications);

    if (!success || !contract || !selectedOption) {
        return null;
    }

    const monthsLeft = (months: number) => checkTheMonthsLeft(contract, months);

    const formatSelectedOption = (option: DigitalRenewalOptions | string): string => {
        return t('options.' + option);
    };

    let headlineParts: string[] = [];
    let contentKey = '';

    if (contract.contractType === ContractType.OPERATING_LEASE) {
        if (monthsLeft(1)) {
            headlineParts = [
                t('leasing.notification.success-notification-1-month.headlinePart1'),
                t('leasing.notification.success-notification-1-month.headlinePart2'),
            ];
            contentKey = 'leasing.notification.success-notification-1-month.content';
        } else if (monthsLeft(2)) {
            headlineParts = [
                t('leasing.notification.success-notification-2-month.headlinePart1'),
                t('leasing.notification.success-notification-2-month.headlinePart2'),
            ];
            contentKey = 'leasing.notification.success-notification-2-month.content';
        } else if (monthsLeft(5)) {
            headlineParts = [
                t('leasing.notification.success-notification-5-month.headlinePart1'),
                t('leasing.notification.success-notification-5-month.headlinePart2'),
            ];
            contentKey = 'leasing.notification.success-notification-5-month.content';
        }
    } else {
        const isC5Modality = contract.productModality === 'c5';
        if (monthsLeft(8) && isC5Modality) {
            headlineParts = [
                t('finance.notification.success-notification-8-month-cpc.headlinePart1'),
                t('finance.notification.success-notification-8-month-cpc.headlinePart2'),
            ];
            contentKey = 'finance.notification.success-notification-8-month-cpc.content';
        } else {
            headlineParts = [
                t('finance.notification.success-notification-8-month-next.headlinePart1'),
                t('finance.notification.success-notification-8-month-next.headlinePart2'),
            ];
            contentKey = 'finance.notification.success-notification-8-month-next.content';
        }
    }

    const selectedOptionFormatted = formatSelectedOption(selectedOption);
    const modelName = contract.carInformation;
    const contractNumber = contract.contractNumber;

    const content = t(contentKey, {
        modelName,
        selectedOption: selectedOptionFormatted,
    });

    const heading = `${headlineParts[0]} ${contractNumber} ${headlineParts[1]}`;

    const handleClose = (): void => {
        dispatch(clearNotification());
    };

    return (
        <Notification
            status={NotificationStatus.success}
            headline={heading}
            testId={'success-notification'}
            className={'c-notification--context u-mb'}
            showCloseButton={true}
            onCloseClick={handleClose}
        >
            <DigitalRenewalContentTransformComponent content={content} />
        </Notification>
    );
};
