import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetails as VehicleDetailsType } from '@cp-es/common';
import { getContractBasedDataSelector, useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { AccordionItem } from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';
import { fetchVehicleDetails } from './VehicleDetailsSlice';
import { SingleVehicleDetails } from './single-vehicle-details';

type VehicleDetailsProps = {
    contractId: string;
    link: string;
};

const VehicleDetailsComponent: React.FC<{ vehicles?: VehicleDetailsType[] }> = ({ vehicles }) => {
    if (!vehicles || vehicles.length < 1) {
        return null;
    }

    return (
        <>
            {vehicles.map(vehicle => (
                <SingleVehicleDetails key={vehicle.vinCode} details={vehicle} numberOfCars={vehicles.length} />
            ))}
        </>
    );
};

const VehicleDetailsWithWrappers = withLoadingAndNoConnectionHandler(VehicleDetailsComponent);

export const VehicleDetailsDataSelector = getContractBasedDataSelector((state: RootState) => state.vehicleDetails);

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({ contractId, link }) => {
    const { t } = useTranslation('contracts');

    const { data: vehicles, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchVehicleDetails,
        VehicleDetailsDataSelector,
        link,
    );

    return (
        <AccordionItem title={t('vehicleDetails.headline')}>
            <div>
                <VehicleDetailsWithWrappers isLoading={isLoading} hasError={!!loadingError} vehicles={vehicles} />
            </div>
        </AccordionItem>
    );
};
