import React, { useState } from 'react';
import { UnpaidDetailsContractHeader } from './unpaid-details-contract-header';
import { UnpaidDetailsOnlinePayment } from './unpaid-details-online-payment';
import { UnpaidDetailsAmountSelector } from './unpaid-details-amount-selector';
import { Contract, UnpaidDetailsWithEndpoints } from '@cp-es/common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContract } from 'components/contracts/useContract';
import { useGetContractBasedApiData } from '@cp-shared-9/frontend-integration';
import { fetchUnpaidDetails } from './UnpaidDetailsSlice';
import { UnpaidDetailsDataSelector } from './UnpaidDetailsDataSelector';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';

export type UnpaidDetailsViews = 'UNPAID_DETAILS_AMOUNT_SELECTOR' | 'UNPAID_DETAILS_ONLINE_PAYMENT';

type UnpaidDetailsProps = {
    encryptedContractId: string;
};

type UnpaidDetailsComponentProps = {
    unpaidDetailsWithEndpoints?: UnpaidDetailsWithEndpoints;
    contract?: Contract;
};

const UnpaidDetailsComponent: React.FC<UnpaidDetailsComponentProps> = ({ unpaidDetailsWithEndpoints, contract }) => {
    const [currentView, setCurrentView] = useState<UnpaidDetailsViews>('UNPAID_DETAILS_AMOUNT_SELECTOR');
    const [lastIndexToBePayed, setLastIndexToBePayed] = useState<number>(0);

    const { unpaidDetails, endpoints } = unpaidDetailsWithEndpoints || {};

    if (!contract || !unpaidDetails) {
        return null;
    }

    if (!(contract.isFinanceLeaseContract || contract.isFinancingContract)) {
        return <NoConnectionNotification />;
    }

    const { carInformation, licensePlateNumber, contractType } = contract;

    const unpaidAmount = unpaidDetails.reduce((prev, curr) => prev + curr.totalAmount, 0);

    if (unpaidAmount === 0.0) return null;

    const unpaidInstallments = unpaidDetails.length;

    const toPreviousView = (): void => setCurrentView('UNPAID_DETAILS_AMOUNT_SELECTOR');

    const continueToOnlinePayment = (installmentCount: number): void => {
        setLastIndexToBePayed(installmentCount - 1);
        setCurrentView('UNPAID_DETAILS_ONLINE_PAYMENT');
    };

    return (
        <>
            <UnpaidDetailsContractHeader
                carInformation={carInformation}
                contractNumber={contract.contractNumber}
                licensePlateNumber={licensePlateNumber}
                contractType={contractType}
                unpaidInstallments={unpaidInstallments}
                unpaidAmount={unpaidAmount}
            />
            <div className="u-mt">
                {currentView === 'UNPAID_DETAILS_AMOUNT_SELECTOR' && (
                    <UnpaidDetailsAmountSelector
                        unpaidDetails={unpaidDetails}
                        continueToOnlinePayment={continueToOnlinePayment}
                    />
                )}
                {currentView === 'UNPAID_DETAILS_ONLINE_PAYMENT' && (
                    <UnpaidDetailsOnlinePayment
                        lastIndexToBePayed={lastIndexToBePayed}
                        toPreviousView={toPreviousView}
                        onlinePaymentEndpoint={endpoints?.cecaPaymentInformationEndpoint}
                    />
                )}
            </div>
        </>
    );
};

const UnpaidDetailsWithHandlers = withLoadingAndNoConnectionHandler(UnpaidDetailsComponent);

export const UnpaidDetails: React.FC<UnpaidDetailsProps> = ({ encryptedContractId }) => {
    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractId, { encryptedContractId: true });

    const {
        data: unpaidDetailsWithEndpoints,
        isLoading: isLoadingUnpaidDetails,
        loadingError: loadingErrorUnpaidDetails,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchUnpaidDetails,
        UnpaidDetailsDataSelector,
        contract?._links?.unpaidDetails,
    );

    const isLoading = isLoadingContract || isLoadingUnpaidDetails;
    const hasError = loadingErrorContract || loadingErrorUnpaidDetails;
    return (
        <UnpaidDetailsWithHandlers
            unpaidDetailsWithEndpoints={unpaidDetailsWithEndpoints}
            isLoading={isLoading}
            hasError={!!hasError}
            contract={contract}
        />
    );
};
