import { formatCpDate } from '@cp-shared-9/common-utilities';
import { DATE_FORMAT } from '@cp-es/common';

export type FormValues = {
    futureQuoteDate?: string;
};

export const getInitialValues = (): FormValues => ({
    futureQuoteDate: formatCpDate()
        .add(1, 'days')
        .format(DATE_FORMAT),
});
