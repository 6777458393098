import React from 'react';
import { useTranslation } from 'react-i18next';

import { PostboxDocument } from '@cp-es/common';
import { Combobox } from '@vwfs-bronson/bronson-react';
import { getListOfAvailableDocumentTypes, PostboxDocumentType } from '../../helper/DocumentType';
import { ComboboxOptions, ComboboxState } from '../../../../types/bronson-react';

export type DocumentTypeFilterProps = {
    postboxDocuments?: PostboxDocument[];
    onChange: (value: PostboxDocumentType) => void;
};

export const DocumentTypeFilter: React.FC<DocumentTypeFilterProps> = ({ postboxDocuments, onChange }) => {
    const { t } = useTranslation('postbox');

    if (!postboxDocuments) {
        return null;
    }

    const selectValueChange = (selected: ComboboxOptions): void => {
        onChange(selected[0].key as PostboxDocumentType);
    };

    const availableOptionsWithTranslations = getListOfAvailableDocumentTypes(postboxDocuments).map(postboxDocument => ({
        ...postboxDocument,
        translation: t(`filters.document-type.select-items.${postboxDocument.translation}`),
    }));

    const availableOptionElements = availableOptionsWithTranslations.map(postboxDocument => {
        return (
            <Combobox.Item
                key={postboxDocument.type}
                testId={`DocumentTypeFilterItem-${postboxDocument.type}`}
                optionValue={postboxDocument.translation}
                optionKey={postboxDocument.type}
            >
                <div>{postboxDocument.translation}</div>
            </Combobox.Item>
        );
    });

    const defaultItem: ComboboxState = {
        value: [
            {
                key: availableOptionsWithTranslations[0].type,
                value: availableOptionsWithTranslations[0].translation,
            },
        ],
    };

    return (
        <>
            <label>{t('filters.document-type.label')}</label>
            <Combobox testId={'DocumentTypeFilter'} onChange={selectValueChange} defaultState={defaultItem}>
                {availableOptionElements}
            </Combobox>
        </>
    );
};
