import React from 'react';
import { Address, PersonalDetailsEditStatus } from '@cp-es/common';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeAddressDetailsPath } from '../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { ConsultView } from './consult-view/ConsultView';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

export type AddressSectionProps = {
    address: Address;
};

export const AddressSection: React.FC<AddressSectionProps> = ({ address }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const trackingSection = 'Address';
    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const setLastEditStatus = (newEditStatus: PersonalDetailsEditStatus, updatedAddress?: Address) => {
        updateMyProfile({
            ...myProfileData,
            address: updatedAddress ?? address,
            lastEditStatus: { ...myProfileData?.lastEditStatus, address: newEditStatus },
        });
    };

    const startEditing = (): void => {
        onEdit();
        setLastEditStatus('NOT_PERFORMED');
        history.push(changeAddressDetailsPath());
    };

    const cancelEditing = (): void => {
        onCancel(trackingSection);
        setLastEditStatus('NOT_PERFORMED');
        history.push(path);
    };

    const finishEditing = (newEditStatus: PersonalDetailsEditStatus, updatedAddress?: Address): void => {
        setLastEditStatus(newEditStatus, updatedAddress);
        history.push(path);
    };
    const isChangeAllowed = !!address._links?.changeLink;

    return (
        <Switch>
            {isChangeAllowed && (
                <Route exact path={changeAddressDetailsPath()}>
                    <EditView
                        address={address}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        lastEditStatus={myProfileData?.lastEditStatus?.address ?? 'NOT_PERFORMED'}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    address={address}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.address ?? 'NOT_PERFORMED'}
                />
            </Route>
        </Switch>
    );
};
