import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { FaqDto } from '@cp-es/common';
import { useCmsContent } from '@cp-shared-9/frontend-integration';
import {
    Faq as FaqShared,
    FaqLoadingPlaceholder,
    HeroImage,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-9/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';
import { fetchFaq } from './FaqSlice';

const FaqView: React.FC<{ faq?: FaqDto }> = ({ faq }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');
    const { teaser } = faq || {};
    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    const { header, descriptionText, items } = faq?.content || { header: '', items: [] };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    const contentComponent = (
        <FaqShared onChange={handleOnChange} header={header} descriptionText={descriptionText} items={items} />
    );

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};

const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqView);

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useCmsContent(fetchFaq, (state: RootState) => {
        return state.content.faq;
    });
    useAnalyticsPageViewTracker('faq', !!faq);

    return (
        <FaqWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            faq={faq}
            loadingPlaceholder={<FaqLoadingPlaceholder />}
        />
    );
};
