import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React, { useState } from 'react';
import { CustomerHappinessIndexUi } from './ui/CustomerHappinessIndexUi';
import { useContracts } from '../contracts/useContracts';
import { CpDataApi } from '../../cp-xhr';
import { CustomerHappinessIndexResponse, getCustomerHappinessIndexEndpoint } from '@cp-es/common';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

const CustomerHappinessIndexWithLoadingHandler = withLoadingAndNoConnectionHandler(CustomerHappinessIndexUi);

export const CustomerHappinessIndex: React.FC = () => {
    const { onAction } = useAnalyticsActionTracker('chi');
    const { data: contracts, isLoading, loadingError } = useContracts();

    const [isSurveyLoading, setIsSurveyLoading] = useState(false);
    const [isSurveyError, setIsSurveyError] = useState(false);

    const handleButtonClick = () => {
        onAction();
        setIsSurveyLoading(true);

        CpDataApi.get<CustomerHappinessIndexResponse>(getCustomerHappinessIndexEndpoint())
            .then(response => {
                setIsSurveyLoading(false);
                window.open(response.data?.redirectUrl, '_blank');
            })
            .catch(() => {
                setIsSurveyLoading(false);
                setIsSurveyError(true);
            });
    };

    return (
        <>
            {isSurveyLoading && <Spinner fullPage />}
            <CustomerHappinessIndexWithLoadingHandler
                contracts={contracts}
                isLoading={isLoading}
                hasError={!!loadingError || isSurveyError}
                handleButtonClick={handleButtonClick}
            />
        </>
    );
};
