import { ProductRoutingResponse } from '@cp-es/common';
import { AxiosRequestConfig } from 'axios';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-9/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-9/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    ProductRoutingResponse,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'productRouting',
    fetchCallback(link: string, config?: AxiosRequestConfig) {
        return CpDataApi.post<ProductRoutingResponse>(link, config?.data).then(response => response.data);
    },
});

export default reducer;
export const fetchProductRouting = fetchData;
