import { transformOnlyDefinedValues, useGetSimpleApiDataWithTransformer } from '@cp-shared-9/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { enrichContracts } from '@cp-es/common';

/**
 * Fetches all the contracts
 */
export function useContracts() {
    return useGetSimpleApiDataWithTransformer(
        fetchContracts,
        ContractsDataSelector,
        transformOnlyDefinedValues(enrichContracts),
    );
}
