import React from 'react';
import { FinancialDetails, FinancialDetailsEditStatus } from '@cp-es/common';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeIbanPath } from 'components/navigation/paths';
import { EditView } from './edit-view/EditView';
import { useUpdateFinancialDetailsPerContract, useUpdateFinancialDetailsState } from '../useUpdateFinancialDetails';
import { useAnalyticsActionTracker } from '@cp-shared-9/frontend-ui';

export type ConsultViewProps = {
    startEditing: () => void;
    details: FinancialDetails;
    lastEditStatus: FinancialDetailsEditStatus;
};

export const IbanSection: React.FC<{
    contractId: string;
    encryptedContractId: string;
    details: FinancialDetails;
    consultView: React.FC<ConsultViewProps>;
    forOperatingLease: boolean;
}> = ({ contractId, details, consultView: ConsultView, forOperatingLease, encryptedContractId }) => {
    const { onAction: onEditStart } = useAnalyticsActionTracker('financialDetailsEditIbanSection');
    const { onAction: onEditSuccess } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSuccess');
    const { onAction: onEditFail } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSubmitFailed');
    const history = useHistory();
    const { path } = useRouteMatch();
    const isChangeEnabled = details._links?.validateIban && details._links?.changeIban;
    const { updateFinancialDetails, financialDetailsData } = useUpdateFinancialDetailsPerContract(contractId);
    const { updateFinancialDetailsState, financialDetailsState } = useUpdateFinancialDetailsState();

    const setLastEditStatus = (newEditStatus: FinancialDetailsEditStatus, newIban?: string): void => {
        const serializedData: FinancialDetails = {
            ...financialDetailsData,
            iban: newIban ?? financialDetailsData.iban,
            lastEditStatus: { ...financialDetailsData?.lastEditStatus, iban: newEditStatus },
        };
        updateFinancialDetails(serializedData);
    };

    const updateIbanInMultipleContracts = (
        newIban: string,
        contractIds: string[],
        newEditStatus: FinancialDetailsEditStatus,
    ): void => {
        const updatedState = financialDetailsState;

        contractIds.forEach(id => {
            updatedState[id] = {
                ...updatedState[id],
                data: {
                    ...(updatedState[id].data as FinancialDetails),
                    iban: newIban,
                    lastEditStatus: {
                        iban: id === contractId ? newEditStatus : 'NOT_PERFORMED',
                    },
                },
            };
        });

        updateFinancialDetailsState(updatedState);
    };

    const startEditing = (): void => {
        onEditStart();
        setLastEditStatus('NOT_PERFORMED');
        if (isChangeEnabled) {
            history.push(changeIbanPath(encryptedContractId));
        }
    };
    const cancelEditing = (): void => {
        setLastEditStatus('NOT_PERFORMED');
        history.push(path);
    };
    const finishEditing = (
        newEditStatus: FinancialDetailsEditStatus,
        newIban?: string,
        contractWithSameCostCenterIds?: string[],
    ): void => {
        if (newEditStatus === 'SUCCESS') {
            onEditSuccess();
        }
        if (forOperatingLease && newIban && contractWithSameCostCenterIds?.length) {
            updateIbanInMultipleContracts(newIban, contractWithSameCostCenterIds, newEditStatus);
        } else setLastEditStatus(newEditStatus, newIban);
        history.push(path);
    };

    return (
        <>
            <Switch>
                {isChangeEnabled && (
                    <Route exact path={changeIbanPath(encryptedContractId)}>
                        <EditView
                            contractId={contractId}
                            forOperatingLease={forOperatingLease}
                            details={details}
                            cancelEditing={cancelEditing}
                            finishEditing={finishEditing}
                            onEditFail={onEditFail}
                        />
                    </Route>
                )}
                <Route path={path}>
                    <ConsultView
                        details={details}
                        startEditing={startEditing}
                        lastEditStatus={financialDetailsData?.lastEditStatus?.iban ?? 'NOT_PERFORMED'}
                    />
                </Route>
            </Switch>
        </>
    );
};
