import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UnpaidDetails } from '@cp-es/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { Button, ButtonContainer, Fieldset, SectionHeading, Table, Checkbox } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../../navigation/paths';

interface UnpaidDetailsWithRowMetadata extends UnpaidDetails {
    isSelected: boolean;
    isDisabled: boolean;
    checkboxId: number;
}

export type UnpaidDetailsAmountSelectorProps = {
    unpaidDetails: UnpaidDetails[];
    continueToOnlinePayment(installmentCount: number): void;
};

export const UnpaidDetailsAmountSelector: React.FC<UnpaidDetailsAmountSelectorProps> = ({
    unpaidDetails,
    continueToOnlinePayment,
}) => {
    const { t, f } = useTranslationWithFormatting('unpaid-details');
    const history = useHistory();
    const [unpaidDetailsWithRowMetadata, setUnpaidDetailsWithRowMetadata] = useState<UnpaidDetailsWithRowMetadata[]>(
        [],
    );
    const [totalOfSelectedAmounts, setTotalOfSelectedAmounts] = useState(0);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        if (unpaidDetails && unpaidDetails.length > 0) {
            const unpaidDetailsEnriched = unpaidDetails.map((unpaidDetailItem, index) => {
                return { ...unpaidDetailItem, isSelected: true, isDisabled: index === 0, checkboxId: index };
            });
            setUnpaidDetailsWithRowMetadata(unpaidDetailsEnriched);
            setTotalOfSelectedAmounts(unpaidDetails.reduce((prev, curr) => prev + curr.totalAmount, 0));
            setAllChecked(true);
        }
    }, [unpaidDetails]);

    if (!unpaidDetailsWithRowMetadata || unpaidDetailsWithRowMetadata.length === 0) {
        return null;
    }

    /**
     * Calculates the total arrears to be paid based on the selected checkbox
     *
     * The initial totalOfSelectedAmounts amount is set in useEffect() as the first installment
     * is always checked.
     * On selection the totalAmount is added to totalOfSelectedAmounts
     * and on deselection it is subtracted.
     *
     * @param totalAmount
     * @param isCheckboxSelected
     * @returns void
     */
    const calculateTotalArrears = (totalAmount: number, isCheckboxSelected: boolean): void => {
        let totalValue = totalOfSelectedAmounts;
        if (isCheckboxSelected) {
            totalValue += totalAmount;
        } else {
            totalValue -= totalAmount;
        }
        setTotalOfSelectedAmounts(totalValue);
    };

    const handleChangeWhenOneSelected = (isChecked: boolean, checkboxId: number): void => {
        const totalAmounts: number[] = [];
        setUnpaidDetailsWithRowMetadata(
            unpaidDetailsWithRowMetadata.map(data => {
                if (data.checkboxId > 0) {
                    if (isChecked && !data.isSelected && data.checkboxId <= checkboxId) {
                        data.isSelected = isChecked;
                        totalAmounts.push(data.totalAmount);
                    }
                    if (!isChecked && data.isSelected && data.checkboxId >= checkboxId) {
                        data.isSelected = isChecked;
                        totalAmounts.push(data.totalAmount);
                    }
                }
                return data;
            }),
        );
        calculateTotalArrears(
            totalAmounts.reduce((prev, curr) => prev + curr, 0),
            isChecked,
        );
        setAllChecked(unpaidDetailsWithRowMetadata.every(item => item.isSelected));
    };

    const calculateTotalArrearsWhenAllSelected = (allSelected: boolean): void => {
        const unpaidDetailsWithRowMetadataNoFirstEntry = unpaidDetailsWithRowMetadata.filter(
            item => item.checkboxId > 0,
        );
        const countTrue = unpaidDetailsWithRowMetadataNoFirstEntry.filter(item => item.isSelected).length;
        const countFalse = unpaidDetailsWithRowMetadataNoFirstEntry.length - countTrue;

        const allChecked = countTrue === unpaidDetailsWithRowMetadataNoFirstEntry.length;
        const noneChecked = countFalse === unpaidDetailsWithRowMetadataNoFirstEntry.length;

        let totalAmounts: number[] = [];
        if (allChecked || noneChecked) {
            totalAmounts = unpaidDetailsWithRowMetadataNoFirstEntry.map(data => data.totalAmount);
        } else if (!noneChecked && !allChecked) {
            totalAmounts = unpaidDetailsWithRowMetadataNoFirstEntry
                .filter(item => !item.isSelected)
                .map(data => data.totalAmount);
        }
        calculateTotalArrears(
            totalAmounts.reduce((prevVal, curVal) => prevVal + curVal, 0),
            allSelected,
        );
    };

    const handleChangeWhenAllSelected = (areAllChecked: boolean): void => {
        calculateTotalArrearsWhenAllSelected(areAllChecked);
        setUnpaidDetailsWithRowMetadata(
            unpaidDetailsWithRowMetadata.map(data => {
                if (data.checkboxId > 0) {
                    data.isSelected = areAllChecked;
                }
                return data;
            }),
        );
        setAllChecked(areAllChecked);
    };

    const handleOnClickBack = (): void => {
        history.push(dashboardPagePath());
    };

    const handleOnClickContinue = (): void => {
        continueToOnlinePayment(unpaidDetailsWithRowMetadata.filter(value => value.isSelected).length);
    };

    const totalArrears = unpaidDetailsWithRowMetadata.reduce((prev, curr) => prev + curr.totalAmount, 0);

    return (
        <>
            <SectionHeading level="3" subtitle={t('table.information')}>
                {t('table.headline')}
            </SectionHeading>
            <Table>
                <Table.Caption>{t('table.headline')}</Table.Caption>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th />
                        <Table.Th>{t('table.column.due-date')}</Table.Th>
                        <Table.Th>{t('table.column.installment')}</Table.Th>
                        <Table.Th>{t('table.column.nominal-amount')}</Table.Th>
                        <Table.Th>{t('table.column.rejected-processing-fee')}</Table.Th>
                        <Table.Th>{t('table.column.interest-on-arrears')}</Table.Th>
                        <Table.Th>{t('table.column.total-amount')}</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {unpaidDetailsWithRowMetadata.map((data, index) => (
                        <Table.Tr key={index}>
                            <Table.Td
                                onClick={(): void => {
                                    handleChangeWhenOneSelected(!data.isSelected, data.checkboxId);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <Checkbox
                                    testId={`cp-unpaid-details-checkbox-${data.checkboxId}`}
                                    name={`cp-unpaid-details-checkbox-${data.checkboxId}`}
                                    checked={data.isSelected}
                                    disabled={data.isDisabled}
                                    onClick={(event): void => {
                                        event.stopPropagation();
                                    }}
                                    readOnly
                                />
                            </Table.Td>
                            <Table.Td>{f(data.nextDueDate, TranslationFormat.DATE)}</Table.Td>
                            <Table.Td>{data.instalmentId}</Table.Td>
                            <Table.Td>{f(data.nominalAmount, TranslationFormat.CURRENCY)}</Table.Td>
                            <Table.Td>{f(data.returnAmount, TranslationFormat.CURRENCY)}</Table.Td>
                            <Table.Td>{f(data.arrearsAmount, TranslationFormat.CURRENCY)}</Table.Td>
                            <Table.Td>{f(data.totalAmount, TranslationFormat.CURRENCY)}</Table.Td>
                        </Table.Tr>
                    ))}
                </Table.Tbody>
                <Table.Tfoot>
                    <Table.Tr>
                        <Table.Td
                            style={{ cursor: 'pointer' }}
                            onClick={(): void => handleChangeWhenAllSelected(!allChecked)}
                        >
                            <Checkbox
                                testId={'cp-unpaid-details-checkbox-allChecked'}
                                name={'cp-unpaid-details-checkbox-allChecked'}
                                checked={allChecked}
                                onClick={(event): void => {
                                    event.stopPropagation();
                                }}
                                readOnly
                            />
                        </Table.Td>
                        <Table.Th colSpan={5}>{t('table.total-unpaid-amount')}</Table.Th>
                        <Table.Th>{f(totalArrears, TranslationFormat.CURRENCY)}</Table.Th>
                    </Table.Tr>
                    <Table.Tr>
                        <Table.Th className="u-bg-brand u-text-white" colSpan={6}>
                            {t('table.summary')}
                        </Table.Th>
                        <Table.Th className="u-bg-brand u-text-white">
                            {f(totalOfSelectedAmounts, TranslationFormat.CURRENCY)}
                        </Table.Th>
                    </Table.Tr>
                </Table.Tfoot>
            </Table>
            <Fieldset>
                <Fieldset.Row>
                    <ButtonContainer nav>
                        <Button secondary testId="backButton" onClick={handleOnClickBack}>
                            {t('translation:editableSectionNav.back')}
                        </Button>
                        <Button testId="continueButton" onClick={handleOnClickContinue}>
                            {t('translation:editableSectionNav.continue')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
};
