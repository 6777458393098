import { isValidDni } from './dni';
import { isValidNie } from './nie';
import { isValidForeignerNif } from './foreigner-nif';

export const isValidNif = (nif?: string | null): boolean =>
    isValidDni(nif) || isValidNie(nif) || isValidForeignerNif(nif);

export { isValidDni, isValidNie, isValidForeignerNif };
// eslint-disable-next-lin
export * from './cif';
